import ArrowDropDownRoundedIcon from '@mui/icons-material/ArrowDropDownRounded';
import ArrowDropUpRoundedIcon from '@mui/icons-material/ArrowDropUpRounded';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import CloseIcon from '@mui/icons-material/Close';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import FilterNoneIcon from '@mui/icons-material/FilterNone';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import styled from 'styled-components';
import { colors } from '../index';

export const ArrowDropDownIconStyled = styled(ArrowDropDownRoundedIcon)`
    && {
        color: ${colors.grey300};
    }
`;

export const ArrowDropUpIconStyled = styled(ArrowDropUpRoundedIcon)`
    && {
        color: ${colors.grey300};
    }
`;

export const EditIconStyled = styled(EditOutlinedIcon)`
&& {
    padding: 0 8px;
    color: ${colors.grey500};
    transition: color 400ms ease-in-out;
    cursor: pointer;
    &:hover {
        color: ${colors.black000};
        transition: color 400ms ease-in-out;
    }
}
`;

export const EditIconDropdownStyled = styled(EditOutlinedIcon)`
&& {
    padding: 0 8px;
    color: ${colors.grey400};
    transition: color 400ms ease-in-out;
    cursor: pointer;
    &:hover {
        color: ${colors.black000};
        transition: color 400ms ease-in-out;
    }
}
`;

export const CloseIconStyled = styled(DeleteOutlineOutlinedIcon)`
    && {
        color: ${colors.grey400};
        padding: 0 8px;
        transition: color 400ms ease-in-out;
        cursor: pointer;
        &:hover {
            color: ${colors.black000};
            transition: color 400ms ease-in-out;
        }
    }
`;

export const FilterNoneIconStyled = styled(FilterNoneIcon)`
&& {
    color: ${colors.grey400};
    padding: 0 8px;
    transition: color 400ms ease-in-out;
    cursor: pointer;
    &:hover {
        color: ${colors.black000};
        transition: color 400ms ease-in-out;
    }
}
`;

export const ChatBubbleOutlineIconStyled = styled(ChatBubbleOutlineIcon)`
    && {
        color: ${colors.grey300};
        cursor: pointer;
        &:hover {
            color: ${colors.grey200};
        }
    }
`;

export const SettingsOutlinedIconStyled = styled(SettingsOutlinedIcon)`
    && {
        background-color: ${colors.grey000};
        padding: 14px;
        cursor: pointer;
        margin-top: 8px;
        color: ${colors.grey300};
        border-radius: 8px;
        :hover {
            background-color: ${colors.grey700};
        }
    }
`;

export const ArrowForwardIconStyled = styled(ArrowForwardIcon)`
    && {
        color: ${colors.default};
        &:hover {
            color: ${colors.grey100};
        }
    }

`;

export const CloseArrowIconStyled = styled(CloseIcon)`
    && {
        width: 24px;
        color: ${colors.grey500};
        padding: 0 8px;
        transition: color 400ms ease-in-out;
        cursor: pointer;
        &:hover {
            color: ${colors.black000};
            transition: color 400ms ease-in-out;
        }
    }
`;
