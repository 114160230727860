import myHeaders from './myHeaders';

const requestOptionsRole = {
    method: 'GET',
    headers: myHeaders,
};

const getVieweDashboardRoleId = fetch(`${process.env.REACT_APP_FRONTEGG_BASE_URL}/identity/resources/roles/v1`, requestOptionsRole)
    .then((response) => response.json())
    .then((response) => {
        if (response) {
            return response.find((role: {key: string}) => role.key === 'viewer_dashboard').id;
        }

        return response;
    })
    .catch((err) => console.error(err));

export default getVieweDashboardRoleId;
