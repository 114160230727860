import React, { FC } from 'react';
import { createStaticRanges, DateRangePicker } from 'react-date-range';
import { colors } from '../../../../theme/colors';
import './css/styles.css';
import './css/default.css';
import { staticRanges } from './default/defaultRanges';

const languageConfiguration = require('react-date-range/dist/locale');

interface DateRangeSelectProps {
    dateRange?: any;
    handleChangeDateRangeScope: (dateRange : any) => void;
    maxDate?: Date;
    minDate?: Date;
}

const DateRangeSelect: FC<DateRangeSelectProps> = ({ dateRange, handleChangeDateRangeScope, maxDate, minDate }) => (
    <DateRangePicker
        direction="vertical"
        inputRanges={[]}
        locale={languageConfiguration.fr}
        maxDate={maxDate}
        minDate={minDate}
        months={1}
        onChange={(item) => handleChangeDateRangeScope({ ...dateRange, ...item })}
        rangeColors={[colors.dateRangeDefault]}
        ranges={[dateRange.selection]}
        renderStaticRangeLabel={() => (null)}
        scroll={{ enabled: false }}
        showDateDisplay={false}
        staticRanges={createStaticRanges(staticRanges)}
    />
);

export default DateRangeSelect;
