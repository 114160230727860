import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import fr from './translation/locales/fr.json';

export const resources = {
    fr,
};
export const defaultNS = 'common';

i18n.use(initReactI18next)
    .init({
        lng: 'fr',
        ns: ['fr'],
        resources,
        defaultNS,
        fallbackLng: 'fr',
        interpolation: {
            escapeValue: false,
        },
    });
