import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import styled from 'styled-components';
import { colors } from '../../theme';
import { fontFamilyPrimary, fontSize, fontWeight } from '../../theme/font';

export const TabsSettingsStyled = styled(Tabs)`
    border-bottom: 1px solid ${colors.grey500};

    && .MuiTabs-indicator {
        background-color: ${colors.default};
    }
`;

export const SettingsStyled = styled.div`
    position: relative;
    min-height: 80vh;
`;
export const TabSettingsStyled = styled(Tab)`
    &&.MuiButtonBase-root {
        text-transform: none;
        font-size: ${fontSize.m};
        letter-spacing: none;
        font-family: ${fontFamilyPrimary};
        color: ${colors.grey300};
        font-weight: ${fontWeight.semiRegular};
        padding: 10px 24px;
    }
    &&.Mui-selected {
        font-weight: ${fontWeight.bold};
        color: ${colors.default};
    }
`;
