import { Fade } from '@mui/material';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Title from '../../Title/title';
import ActionsButton from '../ConfirmationModal/ActionButton';
import BoxModalStyled from '../styled';
import { CloseIconModalStyled, ModalStyled } from './styled';

interface DeleteContentModalInterface {
    handleClose: () => void;
    openModal: boolean
    handleClickConfirm: () => void
    title: string
}

const DeleteContentModal: FC<DeleteContentModalInterface> = ({ handleClose, openModal, handleClickConfirm, title }) => {
    const { t } = useTranslation('common');

    return (
        <ModalStyled
            aria-describedby="modal-modal-description"
            aria-labelledby="modal-modal-title"
            onClose={handleClose}
            open={openModal}
        >
            <Fade in={openModal}>
                <BoxModalStyled>
                    <Title content={title} modalTitle />
                    <CloseIconModalStyled onClick={handleClose} />
                    <ActionsButton
                        handleClickCancel={handleClose}
                        handleClickConfirm={handleClickConfirm}
                        labelCancel={t('cancel')}
                        labelConfirm={t('confirm')}
                    />
                </BoxModalStyled>
            </Fade>
        </ModalStyled>
    );
};

export default DeleteContentModal;
