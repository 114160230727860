import React, { FC } from 'react';
import { ActionsButtonProps } from './ConfirmationModalInterface';
import { ActionButtonContainer, ActionButtonStyled } from './styled';

const ActionsButton: FC<ActionsButtonProps> = ({
    handleClickCancel,
    handleClickConfirm,
    labelCancel,
    labelConfirm,
}) => (
    <ActionButtonContainer>
        <ActionButtonStyled
            lighten={1}
            onClick={handleClickCancel}
            variant="text"
            disableRipple
        >
            {labelCancel}
        </ActionButtonStyled>
        <ActionButtonStyled
            onClick={handleClickConfirm}
            variant="text"
            default
            disableRipple
        >
            {labelConfirm}
        </ActionButtonStyled>
    </ActionButtonContainer>
);

export default ActionsButton;
