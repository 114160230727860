import Fade from '@mui/material/Fade';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useSWRConfig } from 'swr';
import createDashboardFetcher from '../../../api/dashboard/createDashboard';
import { colors } from '../../../theme';
import { fontSize, fontWeight, TypographyStyledGlobal } from '../../../theme/font';
import { CreateDashboardProps } from '../../Dashboard/dashboardInterfaces';
import Title from '../../Title/title';
import BoxModalStyled from '../styled';
import ActionsButton from './actionButton';
import { AddDashboardModalProps } from './addDashboardModalInterface';
import CustomTitleInput from './customTitleInput';
import { CloseIconModalStyled, ModalStyled } from './styled';

const AddDashboardModal: FC<AddDashboardModalProps> = ({ openModal, setOpenModal, surveyId }) => {
    const { t } = useTranslation('dashboard');
    const { mutate } = useSWRConfig();
    const navigate = useNavigate();
    const [createDashboard, setCreateDashboard] = useState<CreateDashboardProps>({ name: '', surveyId, slug: '' });

    const handleClose = () => {
        setOpenModal(false);
    };

    const handleClickConfirm = async () => {
        const response = await createDashboardFetcher(createDashboard, t('sucessAddDashboard'), t('errorAddDashboard'));
        setOpenModal(false);
        mutate(`${process.env.REACT_APP_API_URL}/survey/${surveyId}/dashboard`);

        if (response) {
            navigate(`/survey/${surveyId}/dashboard/${response.id}`);
        }
    };

    return (
        <ModalStyled
            aria-describedby="modal-modal-description"
            aria-labelledby="modal-modal-title"
            onClose={handleClose}
            open={openModal}
        >
            <Fade in={openModal}>
                <BoxModalStyled>
                    <Title content={t('addDashboard.title')} />
                    <TypographyStyledGlobal
                        color={colors.grey200}
                        margin='8px 0'
                        size={fontSize.m}
                        weight={fontWeight.regular}
                    >
                        {t('addDashboard.description')}
                    </TypographyStyledGlobal>
                    <CustomTitleInput setCreateDashboard={setCreateDashboard} />
                    <CloseIconModalStyled onClick={handleClose} />
                    <ActionsButton
                        handleClickConfirm={handleClickConfirm}
                        labelConfirm={t('addDashboard.confirm')}
                    />
                </BoxModalStyled>
            </Fade>

        </ModalStyled>
    );
};

export default AddDashboardModal;
