import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { TableCellBody, TableRowStyled } from '../styled';
import DeleteUserCell from './deleteUserCell';

interface TitleProps {
    id: string,
    email: string,
    lastLogin: Date,
    mailingListName: string
    handleDeleteUser: (userId: string, email: string) => void
}

const TableRow: FC<TitleProps> = ({ id, email, lastLogin, mailingListName, handleDeleteUser }) => {
    const { t } = useTranslation('settings');

    return (
        <TableRowStyled>
            <TableCellBody>{email}</TableCellBody>
            <TableCellBody>{lastLogin ? new Date(lastLogin).toLocaleDateString('fr-FR') : '-'}</TableCellBody>
            <TableCellBody>{t('mailingList.tableCell.observer')}</TableCellBody>
            <TableCellBody>
                <DeleteUserCell
                    email={email}
                    handleDeleteUser={handleDeleteUser}
                    mailingListName={mailingListName}
                    userId={id}
                />
            </TableCellBody>
        </TableRowStyled>
    );
};

export default TableRow;
