import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useSWRConfig } from 'swr';
import duplicateDashboardFetcher from '../../../api/dashboard/duplicateDashboard';
import { DashboardParams } from '../../Dashboard/dashboardInterfaces';
import DeleteContentModal from '../DeleteModal/deleteModal';
import { DeleteDashboardProps } from './ConfirmationModalInterface';

const DuplicateDashboardModal: FC<DeleteDashboardProps> = ({ openModal, setOpenModal, title }) => {
    const { t } = useTranslation(['common', 'dashboard']);
    const { surveyId, dashboardId } = useParams<DashboardParams>();
    const navigate = useNavigate();
    const { mutate } = useSWRConfig();

    const handleClose = () => {
        setOpenModal(false);
    };

    const handleClickConfirm = async () => {
        const newDashboard = await duplicateDashboardFetcher(
            t('dashboard:duplicateDashboardModal.success', { dashboardTitle: title }),
            t('dashboard:duplicateDashboardModal.error'),
            surveyId || '',
            dashboardId || '',
        );
        mutate(`${process.env.REACT_APP_API_URL}/survey/${surveyId}/dashboard`);
        if (newDashboard.id) {
            navigate(`/survey/${surveyId}/dashboard/${newDashboard.id}`);
        } else {
            navigate('/');
        }
        setOpenModal(false);
    };

    return (
        <DeleteContentModal
            handleClickConfirm={handleClickConfirm}
            handleClose={handleClose}
            openModal={openModal}
            title={t('dashboard:duplicateDashboardModal.title', { dashboardTitle: title }) as string}
        />
    );
};

export default DuplicateDashboardModal;
