import myHeaders from './myHeaders';

const getApiChatbotResponse = async (surveyId?: string, dashboardId?: string, indicatorId?: number, filter?: string, chatId?: string, chat?: string) => {
    const requestOptions = {
        method: 'GET',
        headers: myHeaders,
    };
    const requestFilter = filter ? `${filter}&chatId=${chatId}&chat=${chat}` : `?chatId=${chatId}&chat=${chat}`;

    return fetch(`${process.env.REACT_APP_API_URL}/survey/${surveyId}/dashboard/${dashboardId}/compute/${indicatorId}/CHATBOT${requestFilter}`, requestOptions)
        .then((response) => response.json())
        .catch((err) => console.error(err));
};

export default getApiChatbotResponse;
