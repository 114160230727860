import React, { FC } from 'react';
import { v4 as uuidv4 } from 'uuid';
import AlertType from '../../../utils/enum/AlertType';
import { CodeError, getMessageContent } from '../../Message/constant';
import Message from '../../Message/Message';
import { VerbatimProps } from '../dashboardInterfaces';
import { EmojiVerbatimStyled, QuoteVerbatimStyled, TextVerbatimStyled, VerbatimContainerStyled } from '../styled';
import { ReactComponent as VerbatimAngry } from '../../../assets/verbatim/angry.svg';
import { ReactComponent as VerbatimHappy } from '../../../assets/verbatim/happy.svg';
import { ReactComponent as VerbatimQuote } from '../../../assets/verbatim/quote.svg';

const VerbatimIndicator: FC<VerbatimProps> = ({ positive, negative }) => {
    const positiveReviewContent = Object.values(CodeError).includes(positive as unknown as CodeError) ? <Message content={getMessageContent(positive)} messageType={AlertType.WARNING} /> : positive;
    const positiveReview = { key: uuidv4(), content: positiveReviewContent, isPositiveAnswer: true };
    const negativeReviewContent = Object.values(CodeError).includes(negative as unknown as CodeError) ? <Message content={getMessageContent(negative)} messageType={AlertType.WARNING} /> : negative;
    const negativeReview = { key: uuidv4(), content: negativeReviewContent, isPositiveAnswer: false };
    const verbatims = [positiveReview, negativeReview];

    return (
        <>
            {verbatims.map(({ key, content, isPositiveAnswer }) => (
                <VerbatimContainerStyled key={key}>
                    <EmojiVerbatimStyled>
                        {isPositiveAnswer ? <VerbatimHappy /> : <VerbatimAngry />}
                    </EmojiVerbatimStyled>
                    <QuoteVerbatimStyled>
                        <VerbatimQuote />
                    </QuoteVerbatimStyled>
                    <TextVerbatimStyled>
                        {content}
                    </TextVerbatimStyled>
                </VerbatimContainerStyled>

            ))}
        </>
    );
};

export default VerbatimIndicator;
