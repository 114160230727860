import React, { useState, FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import useSWR from 'swr';
import useFetcher from '../../../../../utils/hooks/useFetcher';
import { DashboardParams } from '../../../../Dashboard/dashboardInterfaces';
import { QUESTION_TYPE } from '../../constants';
import { SelectIndicatorQuestionContentProps, TypeOfQuestionIdProps } from '../../dashboardModalInterface';
import ActionsButton from '../ActionsButton/actionButton';
import CardQuestionModal from '../CardQuestionModal/cardQuestionModal';
import { FormControlStyled } from '../styled';

const SelectIndicatorQuestion: FC<SelectIndicatorQuestionContentProps> = ({
    setCurrentContent,
    setCreateIndicator,
    setSelectedQuestion,
    selectedQuestion,
}) => {
    const { t } = useTranslation();
    const { surveyId, dashboardId } = useParams<DashboardParams>();
    const [typeOfQuestionId, setTypeOfQuestionId] = useState<TypeOfQuestionIdProps>(
        {
            id: undefined,
        },
    );

    const { data, error } = useSWR(`${process.env.REACT_APP_API_URL}/survey/${surveyId}/dashboard/${dashboardId}/questions/`, useFetcher());

    if (error) { console.info('error', error); }

    const handleClick = () => {
        if (selectedQuestion.type === QUESTION_TYPE.OPEN || selectedQuestion.type === QUESTION_TYPE.NUMERIC) {
            setCurrentContent('selectDisplayType');
        } else {
            setCurrentContent('refineIndicatorAnswer');
        }
        setCreateIndicator((prev) => {
            const indicatorCopy = { ...prev };
            indicatorCopy.questionId = typeOfQuestionId.id!;
            indicatorCopy.saveModalities = [];
            indicatorCopy.saveModalitiesGroup = [];
            indicatorCopy.saveTreeItems = [];

            return indicatorCopy;
        });
    };

    return (
        <>
            <FormControlStyled>
                {data?.map((questionElement:any) => (
                    <CardQuestionModal
                        key={questionElement.question_id}
                        question={questionElement}
                        setSelectedQuestion={setSelectedQuestion}
                        setTypeOfQuestionId={setTypeOfQuestionId}
                        typeOfQuestionId={typeOfQuestionId}
                    />

                ))}
            </FormControlStyled>
            <ActionsButton
                handleClickBack={() => setCurrentContent('selectIndicatorType')}
                handleClickNext={handleClick}
                isDisabled={typeOfQuestionId.id === undefined}
                labelBack={t('back')}
                labelNext={t('next')}
            />
        </>
    );
};

export default SelectIndicatorQuestion;
