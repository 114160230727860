import { useAuth, useLoginWithRedirect } from '@frontegg/react';
import React, { FC } from 'react';
import { Routes, Route } from 'react-router-dom';
import Dashboard from './components/Dashboard/dashboard';
import DefaultLayout from './components/DefaultLayout/defaultLayout';
import HotAlertDetails from './components/HotAlerts/DetailsPage/hotAlertDetails';
import HotAlertsSummary from './components/HotAlerts/hotAlertsSummary';
import Settings from './components/Settings/settings';
import Surveys from './components/Surveys/surveys';
import { ToastContainerStyled } from './styled';

const App: FC = () => {
    const { isAuthenticated, user } = useAuth();
    const loginWithRedirect = useLoginWithRedirect();

    if (!isAuthenticated) {
        loginWithRedirect();
    } else {
        localStorage.setItem('token', user.accessToken);
    }

    return (
        <DefaultLayout>
            <main>
                <Routes>
                    <Route element={<Surveys />} path="/" />
                    <Route element={<Settings />} path="/settings" />
                    <Route element={<Dashboard />} path="/survey/:surveyId/dashboard/:dashboardId" />
                    <Route element={<HotAlertsSummary />} path="/survey/:surveyId/hot-alerts" />
                    <Route element={<HotAlertDetails />} path="/survey/:surveyId/hot-alerts/:hotAlertId" />
                </Routes>
            </main>
            <ToastContainerStyled autoClose={4000} />
        </DefaultLayout>
    );
};

export default App;
