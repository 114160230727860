import { Typography } from '@mui/material';
import styled from 'styled-components';

export const fontSize = {
    s: '12px',
    sm: '14px',
    m: '16px',
    um: '18px',
    ml: '21px',
    l: '24px',
    xl: '32px',
    xxl: '40px',
};

export const fontWeight = {
    light: 300,
    semiRegular: 400,
    regular: 500,
    semiBold: 600,
    bold: 700,
};

export const fontFamilyPrimary = 'Barlow';

interface TypographyStyledGlobalProps {
    weight?: string
    margin?: string
    color?: string
    fontStyle?: string
    size?: string
    textDecoration?: string
    paddingLeft?: string
    paddingTop?: string
    textAlign?:string
}

/* eslint-disable max-len */
export const TypographyStyledGlobal = styled(Typography)(({ weight, margin, color, fontStyle, size, textDecoration, paddingLeft, paddingTop, textAlign }: TypographyStyledGlobalProps) => `
    && {
        font-family: ${fontFamilyPrimary};
        font-weight: ${weight};
        margin: ${margin};
        color: ${color};
        font-style: ${fontStyle};
        font-size: ${size};
        text-decoration: ${textDecoration};
        padding-left: ${paddingLeft};
        padding-left: ${paddingTop};
        text-align: ${textAlign};
    }
`);

export const TypographyDivStyledGlobal = styled.div(({ weight, margin, color, fontStyle, size, textDecoration, paddingLeft, paddingTop, textAlign }: TypographyStyledGlobalProps) => `
    && {
        font-family: ${fontFamilyPrimary};
        font-weight: ${weight};
        margin: ${margin};
        color: ${color};
        font-style: ${fontStyle};
        font-size: ${size};
        text-decoration: ${textDecoration};
        padding-left: ${paddingLeft};
        padding-left: ${paddingTop};
        text-align: ${textAlign};
    }
`);

export const gutterSize = {
    s: 8,
    m: 16,
    l: 24,
    xl: 32,
    xxl: 48,
};
