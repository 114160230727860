/* eslint-disable react/destructuring-assignment */
import { Dispatch, SetStateAction } from 'react';
import { colors } from '../../theme';
import { HotAlertsInterface, StatusHotAlertsInterface } from './hotAlertsInterface';

export const hotAlertsColors = {
    GREY: {
        color: colors.grey600,
        fontColor: colors.grey200,
    },
};

export const ColumnLabels = {
    SATISFACTION: 'satisfaction',
    ANSWER: 'answerVerbatim',
    STATUS: 'status',
    DATE: 'answerDate',
};

export const columns = [
    { id: 'satisfaction', label: 'Satisfaction' },
    { id: 'answerVerbatim', label: 'Réponse' },
    { id: 'lastname', label: 'Nom' },
    { id: 'firstname', label: 'Prénom' },
    { id: 'answerDate', label: 'Date' },
];

export interface CreateRowInterface {
    columnName: keyof HotAlertsInterface,
    row: HotAlertsInterface,
    status: StatusHotAlertsInterface,
    statusList: Array<StatusHotAlertsInterface>,
    setStatus: Dispatch<SetStateAction<StatusHotAlertsInterface>>,
    surveyId: string,
}
