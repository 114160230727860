import styled from 'styled-components';
import { colors, fontFamilyPrimary, fontSize, fontWeight } from '../../theme';
import { ButtonStyled, EditIconStyled, CloseIconStyled, MenuItemsStyled, MenuStyled } from '../../theme/style';

export const DropdownButtonStyled = styled(ButtonStyled)(({ menuopen }: {menuopen: boolean}) => `
  && {
        font-weight: ${fontWeight.semiBold};
        padding: 12px;
        background-color: ${menuopen ? colors.lighten : colors.grey000};
        border: 1px solid;
        border-color: ${menuopen ? colors.default : 'transparent'};
        color: ${menuopen ? colors.black000 : colors.grey300};
        font-size: ${fontSize.sm};
        &:active {
            border: 1px solid ${colors.default};
        }
        &:hover {
            background: ${colors.grey600};
        }
    }
`);

export const MenuDropdownStyled = styled(MenuStyled)`
  && {
    top: 8px;
    .MuiMenu-list {
        padding: 8px;
        overflow-y: auto;
        overflow-x: hidden;
        max-height: 50vh;
    }
  }
`;

export const MenuItemStyled = styled(MenuItemsStyled)`
    && {
        display: flex;
        flex-direction: column;
        margin: 0;
        max-height: 40vh;
        overflow-y: auto;
        overflow-x: hidden;
        padding: 0;
    &&:hover {
        background-color: transparent;
    }
}
`;
export const DropdownMenuItemsStyled = styled.div(({ currentdashboard }: {currentdashboard?: boolean}) => `
&& {

    &:hover {
        background-color: ${colors.grey700};
    }

    &:active{
        background-color: ${colors.grey000};
    }
    &:active *{
        font-weight: ${fontWeight.semiBold};
    }
}

&& {
    box-shadow: none;
    padding: 16px;
    border-radius: 4px;
    font-family: ${fontFamilyPrimary};
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    color: ${currentdashboard ? colors.black000 : colors.grey300};
    align-items: center;
    font-size: ${fontSize.sm};
    width: 100%;
    box-sizing: border-box;
    background-color: ${currentdashboard ? colors.grey800 : colors.grey000};
    &:hover {
        background-color: ${colors.grey700};
    }

    &:active{
        background-color: ${colors.grey000};
    }
    &:active *{
        font-weight: ${fontWeight.semiBold};
    }
  }
`);

export const EditButtonStyled = styled(EditIconStyled)`
  && {
    padding: 0px;
    width: 20px;
  }
`;

export const DeleteIconStyled = styled(CloseIconStyled)`
  && {
    color: ${colors.grey500}
  }
`;
