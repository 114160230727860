import { FormControl } from '@mui/material';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TypographyStyledGlobal, fontWeight, colors } from '../../../theme';
import { ArrowDropDownIconStyled } from '../../../theme/style';
import { StatusHotAlertsInterface } from '../hotAlertsInterface';
import { MenuItemStyled } from '../styled';
import { SelectStatusHotAlertDetailsStyled, StatusHotAlertStyled } from './styled';

interface StatusHotAlertProps {
    status: StatusHotAlertsInterface
    statusList: StatusHotAlertsInterface[]
    username: string
    onChange: (status: StatusHotAlertsInterface) => void
}

const StatusHotAlert: FC<StatusHotAlertProps> = ({ status, statusList, username, onChange }) => {
    const { t } = useTranslation('hotAlerts');
    const [statusOfHotAlert, setStatusOfHotAlert] = useState(status);

    useEffect(() => {
        setStatusOfHotAlert(status);
    }, [status]);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setStatusOfHotAlert(statusList.find((s) => s.label === event.target.value) as StatusHotAlertsInterface);
        const newStatus = statusList.find((s) => s.label === event.target.value) as StatusHotAlertsInterface;
        onChange(newStatus);
    };

    return (
        <StatusHotAlertStyled>
            <TypographyStyledGlobal
                color={colors.grey200}
                margin='0 0 16px 0'
                size='21px'
                weight={fontWeight.semiBold}
            >
                {t('details.statusOf', { name: username })}
            </TypographyStyledGlobal>

            <FormControl sx={{ width: '100%' }}>
                <SelectStatusHotAlertDetailsStyled
                    IconComponent={ArrowDropDownIconStyled}
                    MenuProps={{
                        PaperProps: {
                            sx: {
                                marginTop: 0.5,
                                '& .MuiList-root.MuiList-padding.MuiMenu-list': {
                                    padding: 0,
                                },
                            },
                        },
                    }}
                    background={statusOfHotAlert?.color?.color}
                    fontcolor={statusOfHotAlert?.color?.fontColor}
                    onChange={handleChange}
                    value={statusOfHotAlert?.label}
                    displayEmpty
                >
                    {statusList?.map((statusLabel) => (
                        <MenuItemStyled key={statusLabel.label} value={statusLabel.label}>
                            {statusLabel.label}
                        </MenuItemStyled>
                    ))}

                </SelectStatusHotAlertDetailsStyled>
            </FormControl>
        </StatusHotAlertStyled>
    );
};

export default StatusHotAlert;
