import React, { FC } from 'react';
import AlertType from '../../utils/enum/AlertType';
import useGetMessageIcon from '../../utils/hooks/useGetAlertIcon';
import { getMessageContent } from './constant';
import { MessageStyled } from './styled';

interface MessageProps {
    content: string | undefined;
    messageType: AlertType;
}

const Message: FC<MessageProps> = ({ content, messageType }) => (
    <MessageStyled
        icon={useGetMessageIcon(messageType)}
        severity={messageType}
        type={messageType}
        variant='filled'
    >
        {getMessageContent(content)}
    </MessageStyled>
);

export default Message;
