import { Checkbox, CircularProgress } from '@mui/material';
import React, { Dispatch, FC, SetStateAction, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import useSWR from 'swr';
import { colors, fontSize, fontWeight, TypographyStyledGlobal } from '../../../../../theme';
import useFetcher from '../../../../../utils/hooks/useFetcher';
import useCustomPagination from '../../../../../utils/hooks/usePagination';
import { DashboardParams } from '../../../../Dashboard/dashboardInterfaces';
import { CreateIndicatorContext } from '../../constants';
import { CreateIndicatorFetchProps, SelectedQuestionProps, VerbatimFromApi } from '../../dashboardModalInterface';
import ActionsButton from '../ActionsButton/actionButton';
import { CardQuestionStyled, StyledInfiniteScroll } from '../styled';

interface AddVerbatimIndicatorProps {
    isPositiveQuestion: boolean
    setCurrentContent: Dispatch<SetStateAction<string>>
    setCreateIndicator: Dispatch<SetStateAction<CreateIndicatorFetchProps>>
    setOpenModal: Dispatch<SetStateAction<boolean>>
    selectedQuestion: SelectedQuestionProps,
}

const AddVerbatimIndicator: FC<AddVerbatimIndicatorProps> = ({ isPositiveQuestion, setCreateIndicator, setCurrentContent, setOpenModal, selectedQuestion }) => {
    const { t } = useTranslation(['common', 'dashboard']);
    const { surveyId, dashboardId } = useParams<DashboardParams>();
    const [selectedAnswer, setSelectedAnswer] = useState<{id: number; content: string}[]>([]);
    const [verbatim, setVerbatim] = useState<Array<VerbatimFromApi>>([]);
    const pagination = useCustomPagination();
    const { setSendCreateIndicator } = useContext(CreateIndicatorContext);

    const handleCreation = () => {
        setCreateIndicator((prev) => ({ ...prev, kpiType: 'OPEN', negativeVerbatimToShow: selectedAnswer.map((verbatimToShow:VerbatimFromApi) => verbatimToShow.id) }));
        setOpenModal(false);
        setCurrentContent('sendIndicator');
        setSendCreateIndicator(true);
    };

    const handleClosePositive = () => {
        setCreateIndicator((prev) => ({ ...prev, positiveVerbatimToShow: selectedAnswer.map((verbatimToShow:VerbatimFromApi) => verbatimToShow.id) }));
        setCurrentContent('addNegativeVerbatimIndicator');
        setSelectedAnswer([]);
    };

    const handleChange = (answer: {
        id: number,
        content: string,
    }) => {
        if (selectedAnswer.some((e) => e.id === answer.id)) {
            setSelectedAnswer(selectedAnswer.filter((elt) => elt.id !== answer.id));
        } else {
            setSelectedAnswer((prev) => [...prev, answer]);
        }
    };

    const isAnswerChecked = (
        selectedAnswerChecked: VerbatimFromApi[],
        questionId: number,
    ) => selectedAnswerChecked.some((e) => e.id === questionId);

    const { data: fetchedVerbatim, error } = useSWR(
        `${process.env.REACT_APP_API_URL}/survey/${surveyId}/dashboard/${dashboardId}/questions/${selectedQuestion.question_id}/verbatim/${pagination.currentPage}`,
        useFetcher(),
    );

    useEffect(() => {
        if (fetchedVerbatim) {
            setVerbatim((prev) => [...prev, ...fetchedVerbatim.data]);
            pagination.setTotalItem(fetchedVerbatim.count);
        }
    }, [fetchedVerbatim]);

    const fetchMoreData = () => {
        pagination.setCurrentPage((prev) => prev + 1);
    };

    return (
        <>
            <TypographyStyledGlobal
                color={colors.grey300}
                margin='0 0 8px 0'
                size={fontSize.sm}
                weight={fontWeight.regular}
            >
                {t('dashboard:addIndicatorModal.addVerbatimIndicator.subtitle', { question: selectedQuestion.label })}
            </TypographyStyledGlobal>
            {error && (
                <TypographyStyledGlobal
                    color={colors.grey300}
                    margin='0 0 8px 0'
                    size={fontSize.sm}
                    weight={fontWeight.semiRegular}
                >
                    {t('dashboard:addIndicatorModal.addVerbatimIndicator.noVerbatim')}
                </TypographyStyledGlobal>
            )}
            {(verbatim && !error) && (
                <StyledInfiniteScroll
                    dataLength={verbatim.length}
                    hasMore={pagination.hasMore}
                    height='60vh'
                    loader={<CircularProgress color="secondary" />}
                    next={fetchMoreData}
                >
                    {verbatim.map((answer: VerbatimFromApi) => (
                        <CardQuestionStyled
                            key={answer.id}
                            control={<Checkbox />}
                            label={(
                                <TypographyStyledGlobal
                                    margin='0px 0px 4px 0px'
                                    sx={{ color: colors.grey100, fontSize: '14px' }}
                                    weight='500'
                                >
                                    {answer.content}
                                </TypographyStyledGlobal>

                            )}
                            onChange={() => handleChange(answer)}
                            questionchecked={isAnswerChecked(selectedAnswer, answer.id) ? 1 : 0}
                        />

                    ))}

                </StyledInfiniteScroll>
            )}

            <ActionsButton
                handleClickBack={() => (isPositiveQuestion ? setCurrentContent('selectDisplayType') : setCurrentContent('addPositiveVerbatimIndicator'))}
                handleClickNext={() => (isPositiveQuestion ? handleClosePositive() : handleCreation())}
                isDisabled={selectedAnswer.length <= 0}
                labelBack={t('common:back')}
                labelNext={isPositiveQuestion ? t('common:next') : t('dashboard:addIndicatorModal.addVerbatimIndicator.addIndicator')}
            />
        </>
    );
};

export default AddVerbatimIndicator;
