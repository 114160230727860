import React, { Dispatch, FC, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { useSWRConfig } from 'swr';
import createMailingList from '../../../api/mailingList/createMailingList';
import AddMailingListModal from '../../Modals/AddMailingListModal/addMailingListModal';
import { ActionButtonOnBoardingStyled } from '../../OnBoarding/styled';
import { MailingListInterface } from './mailingListInterface';

interface AddMailingListActionProps {
    openCreateMailingListModale: boolean;
    setOpenCreateMailingListModale: Dispatch<SetStateAction<boolean>>;
    setCurrentElement:Dispatch<SetStateAction<MailingListInterface>>;
}

const AddMailingListAction: FC<AddMailingListActionProps> = ({ setOpenCreateMailingListModale, openCreateMailingListModale, setCurrentElement }) => {
    const { t } = useTranslation('settings');
    const { mutate } = useSWRConfig();

    const mutateMailingList = () => {
        mutate(`${process.env.REACT_APP_API_URL}/mailing_list/`);
    };
    const creationMailingList = async (value: string) => {
        const newMailingList = await createMailingList(value, t('mailingList.modal.successMessage', { mailingListTitle: value }), t('mailingList.modal.errorMessage', { mailingListTitle: value }), mutateMailingList);
        if (newMailingList) {
            setCurrentElement(newMailingList);
        }
    };

    return (
        <>
            <ActionButtonOnBoardingStyled onClick={() => setOpenCreateMailingListModale(true)} default disableRipple>
                {t('mailingList.addMailingList')}
            </ActionButtonOnBoardingStyled>

            {openCreateMailingListModale && (
                <AddMailingListModal
                    handleClose={() => setOpenCreateMailingListModale(false)}
                    labelButton={t('mailingList.createMailingListButton')}
                    labelInput={t('mailingList.modal.addNewListInputTitle')}
                    openModal={openCreateMailingListModale}
                    placeholder={t('mailingList.modal.placeholder')}
                    sendValue={creationMailingList}
                    title={t('mailingList.createMailingList')}
                />
            )}
        </>
    );
};

export default AddMailingListAction;
