import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { ActionButtonOnBoardingStyled, ActionsButtonContainer } from './styled';

const ActionsButtons: FC = () => {
    const { t } = useTranslation('navigation');
    const navigate = useNavigate();

    return (
        <ActionsButtonContainer>
            <Link to='/survey'>
                <ActionButtonOnBoardingStyled onClick={() => navigate('/survey')} disableRipple>
                    {t('goToHome')}
                </ActionButtonOnBoardingStyled>
            </Link>
            <Link to='settings'>
                <ActionButtonOnBoardingStyled onClick={() => navigate('/settings')} default disableRipple>
                    <SettingsOutlinedIcon sx={{ marginRight: '10px' }} />
                    {t('configureSpace')}
                </ActionButtonOnBoardingStyled>
            </Link>
        </ActionsButtonContainer>
    );
};

export default ActionsButtons;
