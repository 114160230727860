import { Accordion, AccordionDetails, AccordionSummary, Card } from '@mui/material';
import styled from 'styled-components';
import { colors, fontFamilyPrimary, fontSize } from '../../../theme';
import { ReactComponent as TemporalFilterImage } from '../../../assets/filters/temporalFilter.svg';

export const TemporalScopeStyled = styled(Card)`
&& {
    background-color: ${colors.grey000};
    flex-direction: row;
    box-shadow: 0px 10px 60px rgb(33, 22, 69, 0.04);
    border-radius: 8px;
    border: none;
    display: flex;
    left: 50%;
    padding: 12px;
    break-after: avoid;
    break-inside: avoid;
    align: center;
}`;

export const TemporalScopeElementStyled = styled(Card)`
    && {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        border: none;
        align-items: flex-start;
        position: relative;
        gap: 40px;
        box-shadow: none;
    }
`;

export const AccordionStyled = styled(Accordion)(({ height }: {height: string}) => `
    && {
        font-family: ${fontFamilyPrimary};
        background-color: #ffffff;
        font-size: ${fontSize.sm};
        color: ${colors.grey100};
        width: auto;
        min-width: 250px;
        vetical-align: middle;
        border: none;
        box-shadow: none;
        margin-bottom: 15px;
        margin-right: 10px;
        border-radius: 8px;
        height: ${height};
        ::before {
            display: none;
        };
        &&.Mui-expanded {
            margin: 0px 10px 15px 0px;
            height: max-content;
        };
        box-shadow: 0px 10px 60px rgba(33,22,69,0.04);
    }
    }
`);

export const AccordionDetailsStyled = styled(AccordionDetails)`
    && {
        background-color: #FFFFFF;
        width: max-content;
        &&.Mui-selected {
            opacity: 1;
            max-height: 54px;
        };
    }
`;

export const AccordionSummaryStyled = styled(AccordionSummary)`
    && {
        background-color: ${colors.defaultRGBA};
        max-height: 54px;
        border-radius: 8px;
        min-height: 54px;
        &&.Mui-expanded {
            max-height: 54px;
            height:54px;
            margin-right: 10px;
            margin-bottom: 15px;
            min-height: 54px;
            margin: 0;
        };
    }
`;

export const TemporaFilterImageStyled = styled(TemporalFilterImage)`
    && {
        padding-right: 15px;
        width: 25px;
        height: auto;
        min-width:25px;
    }
`;

export const SelectedFilterSummaryStyled = styled.div`
    && {
        justify-content: space-between;
        width: auto;
        font-size: 1em;
        font-weight: 500;
        color: ${colors.default};
        border-left: dashed 1px ${colors.default};
        padding-left: 15px;
        padding-top: 0px;
        white-space: pre-line;
        margin-right: 10px;
    }
`;
