import { Box, TableSortLabel } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import { visuallyHidden } from '@mui/utils';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TableVirtuoso } from 'react-virtuoso';
import { colors, fontSize, fontWeight, TypographyStyledGlobal } from '../../../../../theme';
import { TableCellStyled } from '../styled';
import { TableCellBodyStyled, TableCellHeaderStyled, TableRowBodyStyled, TableRowHeaderStyled, TextFieldDivStyled, TextFieldStyled } from './styled';
import './styles.css';

type VerbatimTableProps = {
    verbatims: any
}

function EnhancedTableHead(props: any) {
    const { headCells, order, orderBy, onRequestSort, onRequestFilter, lastFocusedItem, setLastFocusedItem } = props;
    const createSortHandler = (property: any) => (event: any) => {
        onRequestSort(event, property);
    };
    const createFilterHandler = (property: any, filterText: string) => {
        onRequestFilter(property, filterText);
    };
    const [displayedHeadCells, setDisplayedHeadCells] = useState([...headCells]);
    const setFilterValue = (cellId: string, filterText: string) => {
        const newDisplayedHeadCells = [...headCells];
        const index = displayedHeadCells.findIndex((cell) => cell.id === cellId);
        newDisplayedHeadCells[index].filter = filterText;
        setLastFocusedItem(cellId);
        setDisplayedHeadCells(newDisplayedHeadCells);
    };

    return (
        <TableRowHeaderStyled>
            {displayedHeadCells.map((headCell: any) => (
                <TableCellHeaderStyled
                    key={headCell.id}
                    align={headCell.numeric ? 'right' : 'left'}
                    sortDirection={orderBy === headCell.id ? order : false}
                    style={{ width: headCell.id === 'openData' ? undefined : 175 }}
                >
                    <TableCellStyled>
                        <TableSortLabel
                            key={`sort-${headCell.id}`}
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                            title={headCell.label}
                        >
                            {headCell.shortlabel}
                            {orderBy === headCell.id ? (
                                <Box component='span' sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCellStyled>
                    <TextFieldDivStyled>
                        <TextFieldStyled
                            key={`filled-${headCell.id}`}
                            autoFocus={headCell.id === lastFocusedItem}
                            id={`filled-search-${headCell.id}`}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                setFilterValue(headCell.id, event.target.value);
                                createFilterHandler(headCell.id, event.target.value);
                            }}
                            placeholder='Contient ...'
                            size="small"
                            type="search"
                            value={headCells.filter((av: any) => av.id === headCell.id)[0].filter}
                            hiddenLabel
                        />
                    </TextFieldDivStyled>
                </TableCellHeaderStyled>
            ))}
        </TableRowHeaderStyled>
    );
}

function compare(a: any, b: any, property: any) {
    const aValue = a[property] ? a[property].normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase() : '';
    const bValue = b[property] ? b[property].normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase() : '';
    if (aValue < bValue) {
        return -1;
    }
    if (aValue > bValue) {
        return 1;
    }

    return 0;
}

function getAdditionalValue(additionalVars: any, additionalVarId: number) {
    const result = additionalVars.length > 0 ? additionalVars.filter((av: any) => av.id === additionalVarId)[0] : '';

    return result.value;
}

const VirtualizedVerbatimTable: FC<VerbatimTableProps> = ({ verbatims }) => {
    const [additionalVarsCells, setAdditionalVarsCells] = useState<Array<any>>([]);
    const [headCells, setHeadCells] = useState<Array<any>>([]);
    const [rows, setRows] = useState<Array<any>>([]);
    const [data, setData] = useState<Array<any>>([]);
    const [lastFocusedItem, setLastFocusedItem] = useState('');
    useEffect(() => {
        const additionalVarsCellsTmp = verbatims.columns.map((column: any, index: number) => ({ id: `var${index}`, shortlabel: column.shortlabel.length > 30 ? `${column.shortlabel.substring(0, 30)}...` : column.shortlabel, label: column.label, numeric: false, code: column.code, isOpen: false, filter: '' }));
        const headCellsTmp = [...additionalVarsCellsTmp, { id: 'openData', label: 'Verbatim', shortlabel: 'Verbatim', numeric: false, code: 0, isOpen: false, filter: '' }];
        setAdditionalVarsCells(additionalVarsCellsTmp);
        setHeadCells(headCellsTmp);
        const rowsTmp = verbatims.datas.map((verbatim: any) => ({ id: verbatim.interviewId, var0: additionalVarsCellsTmp.length > 0 ? getAdditionalValue(verbatim.additionalVars, additionalVarsCellsTmp[0].code) : '', var1: additionalVarsCellsTmp.length > 1 ? getAdditionalValue(verbatim.additionalVars, additionalVarsCellsTmp[1].code) : '', var2: additionalVarsCellsTmp.length > 2 ? getAdditionalValue(verbatim.additionalVars, additionalVarsCellsTmp[2].code) : '', openData: verbatim.openData }));
        setRows(rowsTmp);
        setData(rowsTmp);
    }, []);
    /* const rows = verbatims.datas.map((verbatim: any) => ({ id: verbatim.interviewId, var0: additionalVarsCells.length > 0 ? getAdditionalValue(verbatim.additionalVars, additionalVarsCells[0].code) : '', var1: additionalVarsCells.length > 1 ? getAdditionalValue(verbatim.additionalVars, additionalVarsCells[1].code) : '', var2: additionalVarsCells.length > 2 ? getAdditionalValue(verbatim.additionalVars, additionalVarsCells[2].code) : '', openData: verbatim.openData })); */
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('');
    const { t } = useTranslation('dashboard');

    const handleRequestSort = (event: any, property: any) => {
        const isAsc = orderBy === property && order === 'asc';

        if (isAsc) {
            data.sort((a: any, b: any) => -compare(a, b, property)); // A-Z
        } else {
            data.sort((a: any, b: any) => compare(a, b, property)); // Z-A
        }

        setData(data);
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleFilter = () => {
        const datasToFilter = headCells.map((h : any) => rows.filter((d: any) => !(h.filter) || String(d[h.id]).toLowerCase().includes(h.filter.toLowerCase())));
        const idsToFilter = datasToFilter.map((da: any) => da.map((i : any) => i.id));
        let finalIdsToFilter: any[] = idsToFilter[0];
        idsToFilter.map((i : any) => {
            finalIdsToFilter = finalIdsToFilter.filter((x : number) => i.includes(x));

            return true;
        });
        const filteredData = rows.filter((d: any) => finalIdsToFilter.includes(d.id));
        setData(filteredData);
    };

    return (
        <>
            <TableVirtuoso
                components={{
                    Scroller: TableContainer,
                    Table: (props) => (
                        <Table
                            stickyHeader
                            {...props}
                            style={{ borderCollapse: 'separate' }}
                        />
                    ),
                    TableHead: React.forwardRef((props, ref) => (
                        <TableHead {...props} ref={ref} />
                    )),
                    TableRow: (props) => <TableRowBodyStyled hover {...props} />,
                    TableBody: React.forwardRef((props, ref) => (
                        <TableBody {...props} ref={ref} />
                    )),
                }}
                data={data}
                fixedHeaderContent={() => (
                    <EnhancedTableHead
                        headCells={headCells}
                        lastFocusedItem={lastFocusedItem}
                        onRequestFilter={handleFilter}
                        onRequestSort={handleRequestSort}
                        order={order}
                        orderBy={orderBy}
                        setLastFocusedItem={setLastFocusedItem}
                    />
                )}
                itemContent={(index, row) => (
                    <>
                        {additionalVarsCells.length > 0 && <TableCellBodyStyled isOpenData={false}>{row.var0}</TableCellBodyStyled>}
                        {additionalVarsCells.length > 1 && <TableCellBodyStyled isOpenData={false}>{row.var1}</TableCellBodyStyled>}
                        {additionalVarsCells.length > 2 && <TableCellBodyStyled isOpenData={false}>{row.var2}</TableCellBodyStyled>}
                        <TableCellBodyStyled isOpenData>{row.openData}</TableCellBodyStyled>
                    </>
                )}
                style={{ height: '80%', willChange: 'transform' }}
            />
            <TypographyStyledGlobal color={colors.grey400} fontStyle='italic' paddingTop='15px' size={fontSize.sm} textAlign='right' weight={fontWeight.semiRegular}>
                {t('addIndicatorModal.selectDisplayType.indicatorChart.verbatimCount', { count: data?.length })}
            </TypographyStyledGlobal>
        </>
    );
};

export default VirtualizedVerbatimTable;
