import { TableCell, TableContainer, TableRow } from '@mui/material';
import styled from 'styled-components';
import { colors, fontWeight, fontFamilyPrimary, fontSize } from '../../../theme';

export const MailingListActionsHeaderStyled = styled.div`
    display: flex;
    gap: 16px;
    margin-top: 34px;
`;
export const EmptyMailingListStyled = styled.div`
    margin: 10px 0px;
`;
export const HeaderMailingListStyled = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: baseline;

`;

export const AddMailingListButtonStyled = styled.div`
    && {
        position: absolute;
        bottom: -5px;
        right: 0;
        display: flex;
        gap: 8px;
    }
`;

export const TableRowStyled = styled(TableRow)`
    &&.MuiTableRow-root:nth-child(odd)   {
        background: transparent;
    }
`;
export const TableCellBody = styled(TableCell)`
    && {
        font-family: ${fontFamilyPrimary};
        color: ${colors.grey200};
        font-weight: ${fontWeight.semiRegular};
        font-size: ${fontSize.m};
        padding: 9px 0;
        border-bottom: none;
        &&.MuiTableCell-root {
            line-height: 9px;
        }
    }
`;
export const TableCellHead = styled(TableCell)`
    && {
        color: ${colors.grey100};
        font-weight: ${fontWeight.semiBold};
        font-family: ${fontFamilyPrimary};
        font-size: ${fontSize.m};
        border-bottom: none;
        padding: 9px 0;

    }
`;
export const TableCellSpecialBody = styled.p(({ disabled }: {disabled: boolean}) => `
    && {
        background-color: ${disabled ? colors.grey600 : colors.alertSuccess};
        display: inline;
        padding: 4px;
        border-radius: 4px;
        font-size: ${fontSize.sm};
        font-weight: ${fontWeight.regular};
        color: ${disabled ? colors.grey200 : colors.grey000};
    }
`);

export const TableContainerStyled = styled(TableContainer)`
    margin-top: 28px;
`;

export const EmptyMailingListTitle = styled.div`
    margin-top: 34px;
`;
export const MailingListTitleStyled = styled.h2`
    margin: 0;
    color: ${colors.grey100};
    font-weight: ${fontWeight.semiBold}
`;
