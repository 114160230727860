import { AuthorizedContent } from '@frontegg/react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React, { FC, SyntheticEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import RolesType from '../../../utils/enum/RolesType';
import { FilterResults } from '../../Modals/FilterModal/filterModalInterface';
import { DashboardFromApi, UpdateDashboardProps } from '../dashboardInterfaces';
import SelectedFilterSummary from '../TemporalScope/selectedFilterSummary';
import AddFilterButton from './AddFilterButton/AddFilterButton';
import FilterSelect from './FilterSelect/filterSelect';
import ButtonResetFilters from './ResetFilters/buttonResetFilters';
import { AccordionStyled, AccordionSummaryStyled, AccordionDetailsStyled, FilterBarStyled, FilterElementStyled, FilterImageStyled } from './styled';

interface FilterBarProps {
    updateDashboard: (filters: FilterResults) => void;
    setUpdatedDasboard: React.Dispatch<React.SetStateAction<UpdateDashboardProps| undefined>>;
    alreadySelectedFilter: UpdateDashboardProps['dashboardFilterConfig'] | undefined;
    filters: DashboardFromApi['dashboardFilterConfig'];
    handleSelectClauseFilter: ([filterId, modalityId]: [string, Array<string> | null]) => void;
    handleSelectClauseFilterLabel: ([filterLabel, modalitiesLabel]: [string, string | null]) => void;
    handleResetClauseFilter: (resetFilters: number) => void;
    resetFilters: number;
    removeDashboardFilter: (filterId: string) => void;
    selectedClauseFilterLabel?: any;
}

const FilterBar: FC<FilterBarProps> = ({ updateDashboard, setUpdatedDasboard, alreadySelectedFilter, filters, handleSelectClauseFilter, handleSelectClauseFilterLabel, handleResetClauseFilter, removeDashboardFilter, resetFilters, selectedClauseFilterLabel }) => {
    const { t } = useTranslation('dashboard');
    const [lockSelectedFilter, setLockSelectedFilter] = useState<string[]>([]);
    const [expanded, setExpanded] = useState<string | false>(false);
    const handleChange = (panel: string) => (event: SyntheticEvent, isExpanded: boolean) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        <AccordionStyled expanded={expanded === 'filter'} height={expanded ? '54px' : '54px'} onChange={handleChange('filter')}>
            <AccordionSummaryStyled
                aria-controls="filter-content"
                expandIcon={<ExpandMoreIcon />}
                id="filter-header"
            >
                <FilterImageStyled />
                {(expanded || selectedClauseFilterLabel.length === 0) && (
                    <SelectedFilterSummary
                        varTitle={t('filter.superTitle')}
                    />
                )}
                { !expanded && selectedClauseFilterLabel.length > 0
            && selectedClauseFilterLabel.map((filterLabel: any) => (
                <SelectedFilterSummary
                    key={filterLabel.filterLabel}
                    selectedValuesLabel={filterLabel.modalitiesLabel}
                    varTitle={filterLabel.filterLabel}
                />
            ))}
            </AccordionSummaryStyled>
            <AccordionDetailsStyled>
                <FilterBarStyled>
                    { filters.length > 0
                && (
                    <FilterElementStyled>
                        {filters.map((filter) => (
                            <FilterSelect
                                key={Object.keys(filter.filter)[0]}
                                filter={filter}
                                handleSelectClauseFilter={handleSelectClauseFilter}
                                handleSelectClauseFilterLabel={handleSelectClauseFilterLabel}
                                lockSelectedFilter={lockSelectedFilter}
                                lockedFilters={filter.lockedModalities ? filter.lockedModalities.toString().split(',') : []}
                                removeDashboardFilter={removeDashboardFilter}
                                resetFilters={resetFilters}
                                setLockSelectedFilter={setLockSelectedFilter}
                            />
                        ))}
                    </FilterElementStyled>
                )}
                    <AuthorizedContent requiredRoles={[RolesType.ADMIN, RolesType.ADMIN_MOAI]}>
                        <AddFilterButton
                            alreadySelectedFilter={alreadySelectedFilter}
                            setUpdatedDasboard={setUpdatedDasboard}
                            updateDashboard={updateDashboard}
                        />
                    </AuthorizedContent>
                </FilterBarStyled>
                {(alreadySelectedFilter && alreadySelectedFilter.length > 0) && (
                    <ButtonResetFilters
                        handleResetClauseFilter={handleResetClauseFilter}
                    />
                )}
            </AccordionDetailsStyled>
        </AccordionStyled>
    );
};

export default FilterBar;
