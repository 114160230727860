import styled from 'styled-components';
import { colors, fontSize, fontWeight } from '../../../theme';
import { ButtonStyled } from '../../../theme/style';
import { ActionButtonStyledProps } from '../DashboardModal/DashboardModalContent/styled';

export const ActionButtonContainer = styled.div`
    display: flex;
    gap: 16px;
    margin-top: 32px;
`;

export const ActionButtonStyled = styled(ButtonStyled)((props : ActionButtonStyledProps) => `
    && {
        padding: 12px 16px;
        background-color: ${props.default ? colors.default : colors.grey000};
        color: ${props.default ? colors.grey000 : colors.default};
        font-weight: ${fontWeight.semiBold};
        font-size: ${fontSize.sm};
        max-height: 48px;
        &:hover {
            background-color: ${props.default ? colors.defaultHover : colors.grey700};
        }
        &:disabled {
            background-color: ${colors.grey600};
            color: ${colors.grey300};
        }
    }
`);
