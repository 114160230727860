import ArrowDropDownRoundedIcon from '@mui/icons-material/ArrowDropDownRounded';
import ArrowDropUpRoundedIcon from '@mui/icons-material/ArrowDropUpRounded';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AddMailingListModal from '../Modals/AddMailingListModal/addMailingListModal';
import DeleteContentModal from '../Modals/DeleteModal/deleteModal';
import { MailingListInterface } from '../Settings/MailingList/mailingListInterface';
import sortedItems from '../Settings/MailingList/utilities';
import DropdownProps from './dropDownInterface';
import DropdownMenuItems from './DropdownMenuItems';
import { DropdownButtonStyled, MenuDropdownStyled, MenuItemStyled } from './styled';

const Dropdown: FC<DropdownProps> = ({ currentElement, items, setCurrentElement, editActions, editValue, editActionsLabel, deleteMailingList }) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [openDashboardDropdown, setOpenDashboardDropdown] = useState<boolean>(false);
    const [openEditActionsButton, setOpenEditActionsButton] = useState<boolean>(false);
    const [openDeleteActionsButton, setOpenDeleteActionsButton] = useState<boolean>(false);
    const { t } = useTranslation('settings');

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setOpenDashboardDropdown(!openDashboardDropdown);
        setAnchorEl(event.currentTarget);
    };

    const handleChangeElement = (menuItem:MailingListInterface) => {
        setCurrentElement(menuItem);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleEditMailingList = (value:string) => {
        if (editActions) {
            editActions(value);
        }
    };

    const handleDeleteMailingList = () => {
        deleteMailingList();
        setOpenDeleteActionsButton(false);
    };

    return (
        <>
            <DropdownButtonStyled
                endIcon={openDashboardDropdown ? <ArrowDropUpRoundedIcon /> : <ArrowDropDownRoundedIcon />}
                menuopen={openDashboardDropdown ? 1 : 0}
                onClick={handleClick}
                disableRipple
            >
                {currentElement.name}
                <MenuDropdownStyled
                    anchorEl={anchorEl}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    onClose={handleClose}
                    open={openDashboardDropdown}
                >
                    <MenuItemStyled>
                        {items.length > 0 && items.sort(sortedItems).map((item: MailingListInterface) => (
                            <DropdownMenuItems
                                key={item.id}
                                content={item.name}
                                currentElement={currentElement.id === item.id}
                                editActions={editActions}
                                id={item.id}
                                onClick={() => handleChangeElement(item)}
                                setOpenDeleteActionsButton={setOpenDeleteActionsButton}
                                setOpenEditActionsButton={setOpenEditActionsButton}
                            />
                        ))}
                    </MenuItemStyled>
                </MenuDropdownStyled>
            </DropdownButtonStyled>

            {openEditActionsButton && (
                <AddMailingListModal
                    handleClose={() => setOpenEditActionsButton(false)}
                    labelButton={editActionsLabel?.labelButton || ''}
                    labelInput={editActionsLabel?.inputTitle || ''}
                    openModal={openEditActionsButton}
                    placeholder=""
                    sendValue={handleEditMailingList}
                    title={editActionsLabel?.title || ''}
                    value={editValue}
                />
            )}
            {openDeleteActionsButton && (
                <DeleteContentModal
                    handleClickConfirm={handleDeleteMailingList}
                    handleClose={() => setOpenDeleteActionsButton(false)}
                    openModal={openDeleteActionsButton}
                    title={t('mailingList.modal.deleteMailingListTitle', { mailingListName: currentElement.name }) as string}
                />
            )}
        </>
    );
};

export default Dropdown;
