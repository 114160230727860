import { UniqueIdentifier } from '@dnd-kit/core';
import ArrowDropDownRoundedIcon from '@mui/icons-material/ArrowDropDownRounded';
import { FormGroup, FormControlLabel, Tooltip } from '@mui/material';
import React, { FC, Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { colors, fontSize, fontWeight, TypographyStyledGlobal } from '../../../../../../theme';
import { SwitchStyled, CloseIconStyled } from '../../../../../../theme/style';
import { ActionButtons } from '../../../../../Dashboard/DashboardDropdown/styled';
import { KPI_STATE } from '../../../constants';
import { ModalitiesGroupProps } from '../../../dashboardModalInterface';
import { CardAnswerModalities, InputGroupStyled } from '../../styled';

interface AddGroupInputProps {
    idGroup: string;
    setModalitiesGroup: Dispatch<SetStateAction<ModalitiesGroupProps[]>>;
    modalityGroups?: ModalitiesGroupProps[];
    onCollapse(id: UniqueIdentifier): void;
    handleRemove(id: UniqueIdentifier): void;
}
interface UpdateProps {
    label?: string;
    status?: boolean;
}

const AddGroupInput: FC<AddGroupInputProps> = ({
    idGroup,
    setModalitiesGroup,
    modalityGroups,
    onCollapse,
    handleRemove,
}) => {
    const { t } = useTranslation('dashboard');
    const [modalityGroup, setModalityGroup] = useState<ModalitiesGroupProps>({ idGroup, label: '', isCollapse: false, modalities: [], status: KPI_STATE.DISPLAYED });
    const [statusGroup, setStatusGroup] = useState<KPI_STATE[]>([KPI_STATE.DISPLAYED]);

    useEffect(() => {
        const updatedGroup = modalityGroups?.find((group) => group.idGroup === idGroup);
        if (updatedGroup) {
            setModalityGroup(updatedGroup);
        }
    }, [modalityGroups]);

    const updateGroup = (value: UpdateProps, groupStatus: KPI_STATE = KPI_STATE.DISPLAYED) => {
        setModalitiesGroup((prev: ModalitiesGroupProps[]) => {
            const { status, label } = value;
            const indexModalitiesGroup = prev.findIndex((object: ModalitiesGroupProps) => object.idGroup === idGroup);
            const newData = { idGroup, label: label ?? '', isCollapse: false, status: KPI_STATE.DISPLAYED };

            if (prev.length === 0) {
                return [newData];
            }
            if (indexModalitiesGroup !== -1) {
                const groupCopy = { ...prev.find((object: ModalitiesGroupProps) => object.idGroup === idGroup) };
                if (status !== undefined) {
                    if ((groupStatus === KPI_STATE.HIDDEN && groupCopy.status === KPI_STATE.EXCLUDED) || (groupStatus === KPI_STATE.EXCLUDED && groupCopy.status === KPI_STATE.HIDDEN)) {
                        groupCopy.status = status ? KPI_STATE.EXCLUDED : KPI_STATE.DISPLAYED;
                    } else {
                        groupCopy.status = status ? groupStatus : KPI_STATE.DISPLAYED;
                    }
                }
                if (label !== undefined) {
                    groupCopy.label = label;
                }
                const updateModalitiesGroup = [...prev];

                updateModalitiesGroup[indexModalitiesGroup] = groupCopy as ModalitiesGroupProps;

                return updateModalitiesGroup;
            }

            return [...prev, newData];
        });
    };

    const handleChangeStatus = (e: any, status: KPI_STATE) => {
        const target = e?.target as HTMLInputElement;
        const groupStatus = status;
        setStatusGroup((prev) => {
            let newValues = [];
            if (target.checked) {
                newValues = [...prev, groupStatus];
            } else {
                newValues = prev.filter((value) => value !== groupStatus);
            }

            return newValues;
        });
        updateGroup({ status: target.checked }, groupStatus);
    };

    const handleChange = (e: any) => {
        const target = e?.target as HTMLInputElement;
        updateGroup({ label: target.value });
    };
    const removeGroup = () => {
        setModalitiesGroup((prev) => {
            const groups = [];
            for (const group of prev) {
                if (group.idGroup === idGroup) {
                    continue;
                }
                groups.push(group);
            }

            return groups;
        });
    };

    const handleArrowDropDown = () => {
        onCollapse(idGroup);
        setModalityGroup((prev:ModalitiesGroupProps) => {
            const newData = { ...prev };
            newData.isCollapse = !prev.isCollapse;

            return newData;
        });
    };

    const handleKeyDownEnter = (e:any) => {
        if (e?.key === 'Enter') {
            e.target.blur();
        }
    };

    return (
        <CardAnswerModalities>
            <ArrowDropDownRoundedIcon
                onClick={handleArrowDropDown}
                sx={modalityGroup.isCollapse ? { transform: 'rotate(-90deg)' } : undefined}
            />
            <FormGroup sx={{
                display: 'flex',
                flexDirection: 'row',
                width: '100%',
                justifyContent: 'space-between',
                alignItems: 'center',
                flexWrap: 'nowrap',
            }}
            >
                <InputGroupStyled
                    onChange={handleChange}
                    onKeyDown={handleKeyDownEnter}
                    placeholder={t('addIndicatorModal.refineIndicatorAnswer.group.rename')}
                    value={modalityGroup?.label ?? ''}
                />
                <FormControlLabel
                    control={<SwitchStyled checked={statusGroup.includes(KPI_STATE.HIDDEN) || modalityGroup.status === KPI_STATE.HIDDEN} onChange={(e: Event) => handleChangeStatus(e, KPI_STATE.HIDDEN)} />}
                    label={(
                        <TypographyStyledGlobal
                            color={colors.grey300}
                            size={fontSize.sm}
                            weight={fontWeight.regular}
                        >
                            {t('addIndicatorModal.refineIndicatorAnswer.hide')}
                        </TypographyStyledGlobal>
                    )}
                />
                <FormControlLabel
                    control={<SwitchStyled checked={statusGroup.includes(KPI_STATE.EXCLUDED) || modalityGroup.status === KPI_STATE.EXCLUDED} onChange={(e: Event) => handleChangeStatus(e, KPI_STATE.EXCLUDED)} />}
                    label={(
                        <TypographyStyledGlobal
                            color={colors.grey300}
                            size={fontSize.sm}
                            weight={fontWeight.regular}
                        >
                            {t('addIndicatorModal.refineIndicatorAnswer.exclude')}
                        </TypographyStyledGlobal>
                    )}
                />
                {modalityGroup?.label.length !== 0 && (
                    <ActionButtons>
                        <Tooltip title={t('addIndicatorModal.refineIndicatorAnswer.group.delete')} arrow>
                            <CloseIconStyled
                                onClick={() => {
                                    handleRemove(idGroup);
                                    removeGroup();
                                }}
                                sx={{ width: '21px' }}
                            />
                        </Tooltip>
                    </ActionButtons>
                )}
            </FormGroup>
        </CardAnswerModalities>
    );
};

export default AddGroupInput;
