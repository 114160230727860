import { displayToast } from '../../components/Toaster/displayToast';

const updateCustomSurveyTitle = (
    customSurveyTitle: string,
    url: string,
    successMessage: string,
    errorMessage: string,
) => {
    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    myHeaders.append('Accept', 'application/json');
    myHeaders.append('Authorization', `Bearer ${localStorage.getItem('token')}`);

    const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: JSON.stringify({ customTitle: customSurveyTitle }),
    };

    fetch(url, requestOptions)
        .then((response) => {
            if (!response.ok) {
                displayToast(errorMessage, 'error');
            } else {
                displayToast(successMessage, 'success');
            }
        }).catch(() => {
            displayToast(errorMessage, 'error');
        });
};

export default updateCustomSurveyTitle;
