import { FormControl, RadioGroup } from '@mui/material';
import { t } from 'i18next';
import React, { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { RadioButtonStyled, SelectStyled, ArrowDropDownIconStyled } from '../../../../../../theme/style';
import { useCalculationMode, CALCULATION_MODE, QUESTION_TYPE, CHART_INDICATOR_NAME } from '../../../constants';
import { CreateIndicatorFetchProps, KpiGroupConfig, KpiModalityConfig, SelectedQuestionProps, UseCalculationModeProps } from '../../../dashboardModalInterface';
import { FormLabelStyled, FormControlLabelStyled, PlaceholderInputStyled, MenuItemSelectStyled } from '../../styled';

interface CalculationModeProps {
    createIndicator: CreateIndicatorFetchProps;
    setCalculationMode: Dispatch<SetStateAction<CALCULATION_MODE>>,
    setCreateIndicator: Dispatch<SetStateAction<CreateIndicatorFetchProps>>,
    calculationMode: CALCULATION_MODE
    selectedQuestion: SelectedQuestionProps
    isEdit?: boolean
}

const CalculationMode: FC<CalculationModeProps> = ({ setCalculationMode, setCreateIndicator, calculationMode, selectedQuestion, createIndicator, isEdit }) => {
    const filterModality = createIndicator.kpiModalityConfig.filter((modality) => modality.state === 'DISPLAYED');
    const filterGroup = createIndicator.kpiGroupConfig.filter((group) => group.state === 'DISPLAYED');
    const selectedModalityLabel = isEdit && filterModality.filter((f: any) => f.modalityCode === createIndicator.displayModality.donutKeyModality[0])[0]?.label;
    const selectedLabel = isEdit && filterGroup.filter((g: any) => JSON.stringify(g.modalityCodes) === JSON.stringify(createIndicator.displayModality.donutKeyModality))[0]?.groupLabel;
    const [menuItemValue, setMenuItemValue] = useState<string>(selectedModalityLabel || selectedLabel || (filterModality[0]?.label ?? filterGroup[0]?.groupLabel));

    useEffect(() => {
        if (selectedQuestion.type !== QUESTION_TYPE.NUMERIC) {
            const result = filterModality?.length > 0 ? ((isEdit && createIndicator.displayModality?.donutKeyModality) ?? [createIndicator.kpiModalityConfig[0]?.modalityCode]) : createIndicator.kpiGroupConfig[0]?.modalityCodes;
            setCreateIndicator((prev) => ({ ...prev, displayModality: { ...prev.displayModality, donutKeyModality: createIndicator.displayType === CHART_INDICATOR_NAME.DONUT ? result : createIndicator.kpiModalityConfig[0]?.modalityCode ?? createIndicator.kpiGroupConfig[0].modalityCodes } }));
        }
    }, []);
    const handleChangeComputeMethod = (type: CALCULATION_MODE) => {
        setCalculationMode(type);
        if (calculationMode === CALCULATION_MODE.AVERAGE) {
            const donutKeyModalityIds = filterModality.map(({ modalityCode }) => modalityCode);
            setCreateIndicator((prev) => ({ ...prev, displayModality: { ...prev.displayModality, donutKeyModality: donutKeyModalityIds } }));
        } else {
            const currentModality = createIndicator.kpiModalityConfig.filter((modality) => modality.label === menuItemValue);
            setCreateIndicator((prev) => ({ ...prev, computeMethod: type, displayModality: { ...prev.displayModality, donutKeyModality: [currentModality[0].modalityCode] } }));
        }
    };
    const handleChangeSelectPercentage = (value: string) => {
        if (calculationMode === CALCULATION_MODE.PERCENTAGE) {
            if (createIndicator.displayType === CHART_INDICATOR_NAME.LINE_CHART || createIndicator.displayType === CHART_INDICATOR_NAME.KEY_FIGURE) {
                const newKpiModalityConfig: CreateIndicatorFetchProps['kpiModalityConfig'] = createIndicator.kpiModalityConfig?.map((item) => ({ ...item, state: item.state }));
                setCreateIndicator((prev) => ({ ...prev, kpiModalityConfig: newKpiModalityConfig }));
            }
            const donutKeyModalityId = createIndicator.kpiModalityConfig?.find((item) => item?.label === value)?.modalityCode;
            const donutKeyModalityGroupIds = createIndicator.kpiGroupConfig?.find((item) => item?.groupLabel === value)?.modalityCodes;
            if ((createIndicator.displayType === CHART_INDICATOR_NAME.DONUT || createIndicator.displayType === CHART_INDICATOR_NAME.KEY_FIGURE || createIndicator.displayType === CHART_INDICATOR_NAME.LINE_CHART)) {
                setCreateIndicator((prev) => ({ ...prev, displayModality: { ...prev.displayModality, donutKeyModality: createIndicator.displayType === CHART_INDICATOR_NAME.DONUT ? [donutKeyModalityId] : donutKeyModalityId } }));
            }
            if (donutKeyModalityGroupIds) {
                setCreateIndicator((prev) => ({ ...prev, displayModality: { ...prev.displayModality, donutKeyModality: donutKeyModalityGroupIds } }));
            }
        }
        setMenuItemValue(value);
    };

    const defaultValueChecked = () => {
        if (!isEdit) {
            if (selectedQuestion.type === QUESTION_TYPE.CLOSED_DATA) {
                return CALCULATION_MODE.PERCENTAGE;
            }

            return CALCULATION_MODE.AVERAGE;
        }

        return calculationMode;
    };

    const SelectCalculationMode = () => (
        <SelectStyled
            IconComponent={() => <ArrowDropDownIconStyled />}
            onChange={
                (e: React.ChangeEvent<HTMLInputElement>) => handleChangeSelectPercentage(e.target.value)
            }
            renderValue={(selected: string) => {
                if (selected.length === 0) {
                    return <PlaceholderInputStyled> {t('dashboard:addIndicatorModal.commonAddIndicator.selectInputPlaceholder')}</PlaceholderInputStyled>;
                }

                return selected;
            }}
            value={menuItemValue}
            displayEmpty
        >
            {createIndicator.kpiGroupConfig.map((group: KpiGroupConfig) => {
                if (group.state === 'DISPLAYED') {
                    return (
                        <MenuItemSelectStyled
                            key={group.id}
                            value={group.groupLabel}
                        >{group.groupLabel}
                        </MenuItemSelectStyled>
                    );
                }

                return undefined;
            })}
            {createIndicator.kpiModalityConfig.map((modality: KpiModalityConfig) => {
                if (modality.state === 'DISPLAYED') {
                    return (
                        <MenuItemSelectStyled
                            key={modality.modalityCode}
                            value={modality.label}
                        >{modality.label}
                        </MenuItemSelectStyled>
                    );
                }

                return undefined;
            })}
        </SelectStyled>
    );

    return (
        <FormControl>
            <FormLabelStyled>
                {t('dashboard:addIndicatorModal.commonAddIndicator.selectCalculationMode')}
            </FormLabelStyled>
            <RadioGroup
                aria-labelledby="radio-buttons-group-label"
                defaultValue={defaultValueChecked}
                name="radio-buttons-group"
            >
                {useCalculationMode().map((calculationModeItem: UseCalculationModeProps) => {
                    if ((calculationModeItem.mode === CALCULATION_MODE.AVERAGE || calculationModeItem.mode === CALCULATION_MODE.NPS) && createIndicator.kpiGroupConfig.length !== 0) {
                        return undefined;
                    }
                    if (calculationModeItem.mode === CALCULATION_MODE.NPS && createIndicator.kpiModalityConfig.length < 10) {
                        return undefined;
                    }
                    if (calculationModeItem.questionType.includes(selectedQuestion.type)) {
                        return (
                            <div key={calculationModeItem.id}>
                                <FormControlLabelStyled
                                    control={<RadioButtonStyled />}
                                    ischecked={calculationMode === calculationModeItem.mode ? 1 : 0}
                                    label={calculationModeItem.label}
                                    onClick={() => handleChangeComputeMethod(calculationModeItem.mode)}
                                    value={calculationModeItem.mode}
                                />
                                {(calculationModeItem.mode === CALCULATION_MODE.PERCENTAGE && selectedQuestion.type !== QUESTION_TYPE.NUMERIC) ? (
                                    <SelectCalculationMode />
                                ) : ''}
                            </div>
                        );
                    }

                    return '';
                })}
            </RadioGroup>
        </FormControl>
    );
};

export default CalculationMode;
