import { ToastContainer } from 'react-toastify';
import styled from 'styled-components';
import { colors, fontFamilyPrimary, fontSize } from './theme';
import 'react-toastify/dist/ReactToastify.css';

export const CoreStyled = styled.div`
    @page { size: A4 landscape;  margin: 0cm;}
    color: ${colors.grey300};
    background-color: ${colors.grey800};
    font-family: ${fontFamilyPrimary};
    font-size: ${fontSize.m};
`;

export const ToastContainerStyled = styled(ToastContainer)`
    width: auto;
    .Toastify__toast {
        padding: 8px;
        margin: 0;
        min-height: auto;
        border-radius: 8px;
    }
    .Toastify__toast-body {
        padding: 0;
        margin: 0;
    }
    .Toastify__progress-bar {
        padding: 0;
        margin: 0;
    }
    .Toastify__progress-bar {
       visibility: hidden;
       height: 0;
        width: 0;
    }
    .Toastify__close-button {
        align-self: center;
        margin-left: 15px;
        opacity: initial;
        & > svg {
            color: ${colors.grey000}
        }
    }
`;
