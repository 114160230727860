import * as Sentry from '@sentry/browser';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import App from './App';
import AuthProvider from './components/Login/loginThemeOptions';
import reportWebVitals from './reportWebVitals';
import { CoreStyled } from './styled';
import version from './version';
import './i18n';

const root = document.getElementById('root');

if (!root) {
    throw new Error('Missing root element');
}

const { environmentName, sentryDsn, ...props } = root.dataset;

Sentry.init({
    dsn: sentryDsn,
    environment: environmentName,
    release: version ? `moai-korto-frontend@${version}` : undefined,
});

ReactDOM.render(
    <React.StrictMode>
        <BrowserRouter>
            <CoreStyled>
                <AuthProvider>
                    <App {...props} />
                </AuthProvider>
            </CoreStyled>
        </BrowserRouter>
    </React.StrictMode>,
    root,
);

reportWebVitals();
