import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import InfoIcon from '@mui/icons-material/Info';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import React from 'react';
import { colors } from '../../theme';
import AlertType from '../enum/AlertType';

const useGetMessageIcon = (alert: AlertType) => {
    switch (alert) {
        case AlertType.INFO:
            return <InfoIcon sx={{ color: colors.grey000 }} />;
        case AlertType.WARNING:
            return <WarningRoundedIcon sx={{ color: colors.grey000 }} />;
        case AlertType.SUCCESS:
            return <CheckCircleIcon sx={{ color: colors.grey000 }} />;
        case AlertType.ERROR:
            return <ErrorIcon sx={{ color: colors.grey000 }} />;
        default:
            return undefined;
    }
};

export default useGetMessageIcon;
