import { CreateIndicatorFetchProps } from '../../components/Modals/DashboardModal/dashboardModalInterface';
import { displayToast } from '../../components/Toaster/displayToast';

const recreateIndicatorFetcher = (
    indicator: CreateIndicatorFetchProps,
    dashboardId: string,
    surveyId: string,
    successMessage: string,
    errorMessage: string,
) => {
    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    myHeaders.append('Accept', 'application/json');
    myHeaders.append('Authorization', `Bearer ${localStorage.getItem('token')}`);

    const requestOptions = {
        method: 'PUT',
        headers: myHeaders,
        body: JSON.stringify(indicator),
    };
    fetch(`${process.env.REACT_APP_API_URL}/survey/${surveyId}/dashboard/${dashboardId}/indicator/${indicator.dashboardId}/update`, requestOptions)
        .then((response) => {
            if (!response.ok) {
                displayToast(errorMessage, 'error');
            } else {
                displayToast(successMessage, 'success');
            }
        }).catch(() => {
            displayToast(errorMessage, 'error');
        });
};

export default recreateIndicatorFetcher;
