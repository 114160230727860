import { Fade } from '@mui/material';
import React, { ChangeEvent, FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { colors } from '../../../theme';
import { fontSize, fontWeight, TypographyStyledGlobal } from '../../../theme/font';
import Title from '../../Title/title';
import { InputStyled } from '../AddDashboardModal/styled';
import ActionsButton from '../ConfirmationModal/ActionButton';
import BoxModalStyled, { ErrorInputMessageStyled } from '../styled';
import { CloseIconModalStyled, ModalStyled } from './styled';

interface RenameContentModalInterface {
    handleClose: () => void;
    openModal: boolean
    title: string
    description: string
    placeholder: string
    sendValue: (value: string) => void
    value?:string
}

const RenameContentModal: FC<RenameContentModalInterface> = ({ handleClose, openModal, title, placeholder, sendValue, value, description }) => {
    const { t } = useTranslation(['common', 'dashboard']);
    const [renameDashboardValue, setRenameDashboardValue] = useState<string>(value || '');
    const [errorMessage, setErrorMessage] = useState<string>('');

    const handleChangeRenameDashboard = (e: ChangeEvent<HTMLInputElement>) => {
        setRenameDashboardValue(e.target.value);
    };
    const handleRenameDashboard = () => {
        if (renameDashboardValue) {
            setErrorMessage('');
            sendValue(renameDashboardValue);
            handleClose();
        } else {
            setErrorMessage(t('dashboard:renameDashboardModal.errorInputMessage'));
        }
    };

    return (
        <ModalStyled
            aria-describedby="modal-modal-description"
            aria-labelledby="modal-modal-title"
            onClose={handleClose}
            open={openModal}
        >
            <Fade in={openModal}>
                <BoxModalStyled>
                    <Title content={title} modalTitle />
                    <TypographyStyledGlobal
                        color={colors.grey200}
                        margin='8px 0'
                        size={fontSize.m}
                        weight={fontWeight.regular}
                    >
                        {description}
                    </TypographyStyledGlobal>
                    <CloseIconModalStyled onClick={handleClose} />
                    <InputStyled
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChangeRenameDashboard(e)}
                        placeholder={placeholder}
                        value={renameDashboardValue}
                    />
                    {errorMessage && (
                        <ErrorInputMessageStyled>{errorMessage}</ErrorInputMessageStyled>
                    )}
                    <ActionsButton
                        handleClickCancel={handleClose}
                        handleClickConfirm={handleRenameDashboard}
                        labelCancel={t('common:cancel')}
                        labelConfirm={t('common:confirm')}
                    />
                </BoxModalStyled>
            </Fade>
        </ModalStyled>
    );
};

export default RenameContentModal;
