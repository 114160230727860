import { useTranslation } from 'react-i18next';

export enum CodeError {
    NO_DATA = 'NO_DATA',
    NOT_ENOUGH_DATA = 'NOT_ENOUGH_DATA',
    OLDER_DATA = 'OLDER_DATA',
}

export const getMessageContent = (content: string | undefined) => {
    const { t } = useTranslation('dashboard');

    switch (content) {
        case CodeError.NO_DATA:
            return t('error.noData');
        case CodeError.NOT_ENOUGH_DATA:
            return t('error.notEnoughData');
        case CodeError.OLDER_DATA:
            return t('error.olderVerbatimData');
        default:
            return content;
    }
};
