import GradientColor from './GradientColor';

class Gradient {
    colorGradients: [] | GradientColor[];

    maxNum: number;

    colors: string[];

    intervals: [] | {lower: number, upper: number}[];

    setMidpoint: Function;

    getColors: Function;

    setColorGradient: Function;

    constructor(
        colorGradients = [],
        maxNum = 10,
        colors = ['', ''],
        intervals = [{ lower: 0, upper: 1 }],
    ) {
        this.colorGradients = colorGradients;
        this.maxNum = maxNum;
        this.colors = colors;
        this.intervals = intervals;

        const setColorGradient = (gradientColors: string[]) => {
            if (gradientColors.length < 2) {
                throw new Error(
                    `setColorGradient should have more than ${gradientColors.length} color`,
                );
            } else {
                const increment = this.maxNum / (gradientColors.length - 1);
                const firstColorGradient = new GradientColor();
                const lower = 0;
                const upper = 0 + increment;
                firstColorGradient.setColorGradient(
                    gradientColors[0],
                    gradientColors[1],
                );
                firstColorGradient.setMidpoint(lower, upper);
                this.colorGradients = [firstColorGradient];
                this.intervals = [
                    {
                        lower,
                        upper,
                    },
                ];
                this.colors = gradientColors;
            }
        };

        this.setColorGradient = (...gradientColors: string[]) => {
            setColorGradient(gradientColors);

            return this;
        };

        this.getColors = () => {
            const gradientColorsArray = [];
            for (let j = 0; j < this.intervals.length; j++) {
                const interval = this.intervals[j];
                const start = interval.lower === 0 ? 1 : Math.ceil(interval.lower);
                const end = interval.upper === this.maxNum
                    ? interval.upper + 1
                    : Math.ceil(interval.upper);
                for (let i = start; i < end; i++) {
                    gradientColorsArray.push(this.colorGradients[j].getColor(i));
                }
            }

            return gradientColorsArray;
        };

        this.setMidpoint = (maxNumber: number) => {
            if (!Number.isNaN(maxNumber) && maxNumber >= 0) {
                this.maxNum = maxNumber;
                setColorGradient(this.colors);
            } else if (maxNumber <= 0) {
                throw new RangeError(`midPoint should be greater than ${maxNumber}`);
            } else {
                throw new RangeError('midPoint should be a number');
            }

            return this;
        };
    }
}

export default Gradient;
