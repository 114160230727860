import i18next from 'i18next';
import { displayToast } from '../../components/Toaster/displayToast';
import getVieweDashboardRoleId from '../getViewerDashboardRoleId';
import validateEmail from '../validators/validateEmail';
import addUserInMailingList from './addUserInMailingList';
import createUserByFrontegg from './createUserByFrontegg';
import getUserIdIfUserAlreadyExist from './getUserIdIfUserAlreadyExist';

const createNewUserInMailingList = async (
    userEmail: string,
    mailingListId: string,
    metadata: string,
    mutate: () => void,
) => {
    const getRoleId = () => getVieweDashboardRoleId;

    const createUser = async () => await createUserByFrontegg(await getRoleId(), userEmail, metadata) || getUserIdIfUserAlreadyExist(userEmail);

    const addNewUserInMailingList = async () => addUserInMailingList(await createUser(), userEmail, mailingListId, mutate);

    try {
        validateEmail(userEmail);
        addNewUserInMailingList();
        displayToast(i18next.t('settings:mailingList.modal.successMessageNewUser', { userEmail }), 'success');
    } catch (error: unknown) {
        displayToast((error as Error).message || i18next.t('settings:mailingList.modal.errorMessageNewUser', { user: userEmail }), 'error');
    }
};

export default createNewUserInMailingList;
