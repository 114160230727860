import React from 'react';
import { toast } from 'react-toastify';
import { colors } from '../../theme';
import AlertToaster from './alertToaster';

export type ToastType = 'success' | 'error';

export const displayToast = (message: string, type: ToastType = 'success') => {
    const toastColor = type === 'success' ? colors.alertSuccess : colors.alertDanger;

    toast(
        <div>
            <AlertToaster content={message} type={type} />
        </div>,
        {
            position: toast.POSITION.BOTTOM_LEFT,
            style: { backgroundColor: toastColor },
        },
    );
};
