import { useAuth } from '@frontegg/react';

const useFetcher = () => {
    const { user } = useAuth();

    return (url: string) => fetch(url, {
        headers: {
            Authorization: `Bearer ${user.accessToken}`,
        },
    }).then((res) => {
        if (res.status !== 200) {
            throw new Error('An error occurred while fetching the data.');
        }

        return res.json();
    });
};

export default useFetcher;
