import { FormControl, RadioGroup } from '@mui/material';
import React, { Dispatch, FC, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { RadioButtonStyled } from '../../../../../../theme/style';
import { CHART_INDICATOR_NAME } from '../../../constants';
import { CreateIndicatorFetchProps } from '../../../dashboardModalInterface';
import { FormLabelStyled, FormControlLabelStyled } from '../../styled';

interface DecimalRadioButtonsProps {
    createIndicator: CreateIndicatorFetchProps;
    isIntegerView: boolean,
    setIsIntegerView: Dispatch<SetStateAction<boolean>>
    setCreateIndicator: Dispatch<SetStateAction<CreateIndicatorFetchProps>>
}

const DecimalRadioButtons: FC<DecimalRadioButtonsProps> = ({ createIndicator, isIntegerView, setIsIntegerView, setCreateIndicator }) => {
    const { t } = useTranslation('dashboard');
    const handleDisplayModality = (isInteger: boolean) => {
        setIsIntegerView(isInteger);
        setCreateIndicator((prev) => ({ ...prev, displayModality: { ...prev.displayModality, isInteger } }));
    };

    const isHorizontalOrDonuts = createIndicator.displayType === CHART_INDICATOR_NAME.DONUT || createIndicator.displayType === CHART_INDICATOR_NAME.HORIZONTAL_BAR;

    return (
        <FormControl>
            {isHorizontalOrDonuts && (
                <FormLabelStyled>
                    {t('addIndicatorModal.commonAddIndicator.resultViewTitleDonutOrBar')}
                </FormLabelStyled>
            )}
            {!isHorizontalOrDonuts && (
                <FormLabelStyled>
                    {t('addIndicatorModal.commonAddIndicator.resultViewTitle')}

                </FormLabelStyled>
            )}

            <RadioGroup
                aria-labelledby="radio-buttons-group-label"
                defaultValue={isIntegerView ? 'withoutDecimal' : 'withDecimal'}
                name="radio-buttons-group"
                sx={{ display: 'flex', flexDirection: 'row', gap: '24px' }}
            >
                <FormControlLabelStyled
                    key="withoutDecimal"
                    control={<RadioButtonStyled />}
                    ischecked={isIntegerView ? 1 : 0}
                    label={t('addIndicatorModal.commonAddIndicator.integer')}
                    onClick={() => handleDisplayModality(true)}
                    value="withoutDecimal"
                />
                <FormControlLabelStyled
                    key="withDecimal"
                    control={<RadioButtonStyled />}
                    ischecked={!isIntegerView ? 1 : 0}
                    label={t('addIndicatorModal.commonAddIndicator.notInteger')}
                    onClick={() => handleDisplayModality(false)}
                    value="withDecimal"
                />
            </RadioGroup>
        </FormControl>
    );
};

export default DecimalRadioButtons;
