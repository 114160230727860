import { displayToast } from '../../components/Toaster/displayToast';
import myHeaders from '../myHeaders';

const deleteMailingList = (
    mailingListId: string,
    successMessage: string,
    errorMessage: string,
    mutate: () => void,
) => {
    const url = `${process.env.REACT_APP_API_URL}/mailing_list/${mailingListId}`;

    const requestOptions = {
        method: 'DELETE',
        headers: myHeaders,
    };

    fetch(url, requestOptions)
        .then((response) => {
            if (!response.ok) {
                displayToast(errorMessage, 'error');
            } else {
                displayToast(successMessage, 'success');
                mutate();
            }
        }).catch(() => {
            displayToast(errorMessage, 'error');
        });
};

export default deleteMailingList;
