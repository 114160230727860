const sortedItems = (a: {name: string}, b: { name:string}) => {
    if (a.name < b.name) {
        return -1;
    }
    if (a.name > b.name) {
        return 1;
    }

    return 0;
};
export default sortedItems;
