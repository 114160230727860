import styled from 'styled-components';
import { colors, fontSize, fontWeight } from '../../theme';

export const FooterStyled = styled.footer`
  background: ${colors.grey000};
  font-weight: ${fontWeight.semiRegular};
  font-size: ${fontSize.sm};
  color: ${colors.grey400};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  letter-spacing: 1px;
  height: 50px;
  box-sizing: border-box;
  padding: 15px 40px;
  @media print{
    display: none;
  }
`;

export const LinkFooter = styled.div`
    display: flex;
    gap: 80px;
    font-weight: ${fontWeight.regular};
    letter-spacing: normal;
    align-items: center;
    a {
        color: ${colors.grey300};
        font-size: ${fontSize.m};
        &:hover {
            color: ${colors.grey100};
        }
    }
`;

export const LogoContainer = styled.div`
    display: flex;
    align-items: center;
`;
