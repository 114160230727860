import React, { FC } from 'react';
import useTypingEffect from './useTypingEffect';

interface TextTypingEffectWithTextsFadeOutProps {
    chatId?: number,
    text: string,
    hasToUseTypingEffect: boolean,
    handleIsRenderedChat: (chatIdRendered: number) => void,
}

const TIME_PER_LETTER = 25;

const TextTypingEffectWithTextsFadeOut: FC<TextTypingEffectWithTextsFadeOutProps> = ({ chatId, handleIsRenderedChat, text, hasToUseTypingEffect = true }) => {
    const textToShow = useTypingEffect(text, TIME_PER_LETTER, false, hasToUseTypingEffect, handleIsRenderedChat, chatId);

    return (
        <span
            key='textIndex'
        >
            {textToShow}{' '}
        </span>
    );
};

export default TextTypingEffectWithTextsFadeOut;
