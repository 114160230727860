import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { Tooltip } from '@mui/material';
import React, { Dispatch, FC, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { colors, fontFamilyPrimary, fontSize } from '../../theme';
import { ActionsInputStyled, IconsEditContainer } from './styled';

interface ActionsInputProps {
    setDisabledInput: Dispatch<SetStateAction<boolean>>
    setCustomSurveyTitle: Dispatch<SetStateAction<string>>
    oldTitle: string
    modificationAction: () => void
}

const ActionsInput: FC<ActionsInputProps> = ({ setCustomSurveyTitle, setDisabledInput, oldTitle, modificationAction }) => {
    const { t } = useTranslation('common');

    const validateTitle = () => {
        modificationAction();
        setDisabledInput(true);
    };

    const handleCancelTitle = () => {
        setCustomSurveyTitle(oldTitle);
        setDisabledInput(true);
    };

    return (
        <ActionsInputStyled>
            <Tooltip
                componentsProps={{
                    tooltip: {
                        sx: {
                            bgcolor: colors.grey100,
                            borderRadius: '4px',
                            fontFamily: fontFamilyPrimary,
                            fontSize: fontSize.s,
                            padding: '4px 8px',
                            color: colors.grey000,
                            '& .MuiTooltip-arrow': {
                                color: colors.grey100,
                            },
                        },
                    },
                }}
                title={t('validate')}
                arrow
            >
                <IconsEditContainer onClick={validateTitle}>
                    <CheckOutlinedIcon />
                </IconsEditContainer>
            </Tooltip>
            <Tooltip
                componentsProps={{
                    tooltip: {
                        sx: {
                            bgcolor: colors.grey100,
                            borderRadius: '4px',
                            fontFamily: fontFamilyPrimary,
                            fontSize: fontSize.s,
                            padding: '4px 8px',
                            color: colors.grey000,
                            '& .MuiTooltip-arrow': {
                                color: colors.grey100,
                            },
                        },
                    },
                }}
                title={t('delete')}
                arrow
            >
                <IconsEditContainer onClick={handleCancelTitle}>
                    <CloseOutlinedIcon />
                </IconsEditContainer>
            </Tooltip>
        </ActionsInputStyled>
    );
};

export default ActionsInput;
