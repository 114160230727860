import styled from 'styled-components';
import { colors, fontWeight } from '../../../theme';
import { ButtonStyled, MenuItemsStyled, MenuStyled } from '../../../theme/style';

interface DashboardDropdownButtonStyledProps {
    menuopen?: boolean
}

/* eslint-disable max-len */
export const DashboardDropdownButtonStyled = styled(ButtonStyled)(({ menuopen }: DashboardDropdownButtonStyledProps) => `
  && {
        font-weight: ${fontWeight.semiBold};
        padding: 12px;
        background-color: ${menuopen ? colors.lighten : colors.grey000};
        border: 1px solid;
        border-color: ${menuopen ? colors.default : 'transparent'};
        color: ${menuopen ? colors.black000 : colors.grey300};

        &:active {
            border: 1px solid ${colors.default};
        }
        &:hover {
            background: ${colors.grey600};
        }
    }
`);

interface DashboardDropdownMenuItemsStyledProps {
    currentdashboard?: boolean
}

export const DashboardDropdownMenuItemsStyled = styled(MenuItemsStyled)(({ currentdashboard }: DashboardDropdownMenuItemsStyledProps) => `
  && {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    color: ${currentdashboard ? colors.black000 : colors.grey300};
    align-items: center;
    background-color: ${currentdashboard ? colors.grey800 : colors.grey000}
  }
`);

export const AddDashboardButton = styled(ButtonStyled)`
  && {
    color: ${colors.black000};
    width: 100%;
    padding: 16px;
    border-radius: 0;
    box-shadow: none;
    background-color: transparent;
  }
`;

export const MenuDashboardDropdownStyled = styled(MenuStyled)`
  && {
    top: 8px;
    .MuiMenu-list {
        padding: 8px;
    }
  }
`;

export const ActionButtons = styled.div`
  display: flex;
`;
