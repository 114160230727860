import React, { FC } from 'react';
import { useLocation } from 'react-router-dom';
import isElementVisible from '../../utils/isElementVisible';
import Footer from '../Footer/footer';
import Header from '../Header/header';
import { DefaultLayoutStyled, MainStyled } from './styled';

type LayoutProps = {
    children: React.ReactNode;
};

const DefaultLayout: FC<LayoutProps> = ({ children }) => {
    const location = useLocation();

    return (
        <DefaultLayoutStyled>
            {isElementVisible(location.pathname, 'sign-up') && (
                <Header />
            )}
            <MainStyled>
                {children}
            </MainStyled>
            <Footer />
        </DefaultLayoutStyled>
    );
};

export default DefaultLayout;
