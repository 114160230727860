import { Checkbox } from '@mui/material';
import React, { ChangeEvent, FC } from 'react';
import { colors } from '../../theme';
import FormControlLabelStyled from './styled';

interface CheckboxInterface {
    id: string;
    label: string;
    handleChange: (e:ChangeEvent<HTMLInputElement>) => void
    selectedMailingList: string[]
}

const CheckboxWithLabel: FC<CheckboxInterface> = ({ label, id, handleChange, selectedMailingList }) => (
    <FormControlLabelStyled
        control={(
            <Checkbox
                checked={selectedMailingList.includes(id.toString())}
                id={id}
                onChange={handleChange}
                sx={{
                    color: colors.grey500,
                    '&.Mui-checked': {
                        color: colors.alertSuccess,
                    },
                }}
                disableRipple
            />

        )}
        label={label}
    />
);

export default CheckboxWithLabel;
