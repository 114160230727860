import { displayToast } from '../components/Toaster/displayToast';

const deleteFetcher = (
    successMessage: string,
    errorMessage: string,
    url: string,
) => {
    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    myHeaders.append('Accept', 'application/json');
    myHeaders.append('Authorization', `Bearer ${localStorage.getItem('token')}`);

    const requestOptions = {
        method: 'DELETE',
        headers: myHeaders,
    };

    fetch(url, requestOptions)
        .then((response) => {
            if (!response.ok) {
                displayToast(errorMessage, 'error');
            } else {
                displayToast(successMessage, 'success');
            }
        }).catch(() => {
            displayToast(errorMessage, 'error');
        });
};

export default deleteFetcher;
