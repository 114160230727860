import { FormControlLabel } from '@mui/material';
import styled from 'styled-components';
import { colors, fontFamilyPrimary, fontSize, fontWeight } from '../../theme';

const FormControlLabelStyled = styled(FormControlLabel)`
    && {
            margin: 8px 0;
            && .MuiButtonBase-root {
                padding-top: 0;
                padding-bottom: 0;
                padding-left: 0;
            }
            && span.MuiTypography-root {
            font-family: ${fontFamilyPrimary};
            font-weight: ${fontWeight.regular};
            font-size: ${fontSize.sm};
            color: ${colors.grey300};
        }
    }
`;

export default FormControlLabelStyled;
