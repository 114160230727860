import { useEffect, useState } from 'react';

const useTypingEffect = (text: string, duration: number, isTypeByLetter = false, hasToUseTypingEffect = true, handleIsRenderedChat: (chatId: number) => void, chatId?: number) => {
    const [currentPosition, setCurrentPosition] = useState(0);
    const items = isTypeByLetter ? text.split('') : text.split(' ');
    useEffect(() => {
        setCurrentPosition(0);
    }, [text]);

    useEffect(() => {
        if ((currentPosition >= items.length) || !hasToUseTypingEffect) {
            if (chatId) {
                handleIsRenderedChat(chatId);
            }

            return undefined;
        }
        const intervalId = setInterval(() => {
            setCurrentPosition((prevPosition) => prevPosition + 1);
        }, duration);

        return (() => (clearInterval(intervalId)));
    }, [currentPosition, items, duration]);

    return items.slice(0, hasToUseTypingEffect ? currentPosition : items.length).join(isTypeByLetter ? '' : ' ');
};

export default useTypingEffect;
