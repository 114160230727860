import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { IconButton, MenuItem } from '@mui/material';
import styled from 'styled-components';
import { fontFamilyPrimary, fontSize, fontWeight } from '../../../../theme';
import { colors } from '../../../../theme/colors';
import { StyledSelect } from '../../styled';

export const CloseIconFilterStyled = styled(DeleteOutlineOutlinedIcon)`
    && {
        cursor: pointer;
        transition: color 400ms ease-in-out;
        color: ${colors.grey300};
        padding: 0;
        width: 20px;
        &:hover {
            color: ${colors.grey200};
            background: transparent;
            transition: color 400ms ease-in-out;
        }
    }
`;

export const LockIconFilterStyled = styled(IconButton)`
&& {
    cursor: pointer;
    transition: color 400ms ease-in-out;
    color: ${colors.grey300};
    padding: 0;
    margin-right: 12px;
    &:hover {
        color: ${colors.grey200};
        background: transparent;
        transition: color 400ms ease-in-out;
    }
}
`;

export const SelectFilterStyled = styled(StyledSelect)`
    && {
        background-color: ${colors.grey800};
        border-radius: 8px;
        border-bottom: none;
        font-family: ${fontFamilyPrimary};
        font-size: ${fontSize.sm};
        font-weight: ${fontWeight.regular};
        margin-top: 8px;
        .MuiSelect-select {
            padding: 12px 16px;
            &:focus {
                background-color: transparent;
            }

        }
        ::after {
            border: none;
        }
        svg {
            padding-right: 16px;
            &.MuiSelect-iconOpen {
                padding-left: 16px;
            }
        }
    }
`;

export const ButtonActionsStyled = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const MenuItemStyled = styled(MenuItem)`
    && {
        font-family: ${fontFamilyPrimary};
        font-size: ${fontSize.sm};
        display: flex;
        justify-content: space-between;
        color: ${colors.grey100};

        &&.Mui-selected {
            opacity: 0.7;
        }
    }
`;
export const PdfPrintValueStyled = styled.div`
    display: none;
    @media print {
        display: inline;
        break-inside: avoid;
        break-after: avoid;
    }
`;
