import { GradientColorProps } from '../dashboardInterfaces';

class GradientColor implements GradientColorProps {
    startColor: string;

    endColor: string;

    minNum: number;

    maxNum: number;

    setColorGradient: Function;

    setMidpoint: Function;

    getColor: Function;

    constructor(startColor: string = '', endColor: string = '', minNum: number = 0, maxNum: number = 10) {
        this.startColor = startColor;
        this.endColor = endColor;
        this.minNum = minNum;
        this.maxNum = maxNum;

        const generateHex = (number: number, start: string, end: string) => {
            let newNumber = number;
            if (newNumber <= this.minNum) {
                newNumber = this.minNum;
            } else if (newNumber > this.maxNum) {
                newNumber = this.maxNum;
            }

            const midPoint = this.maxNum - this.minNum;
            const startBase = parseInt(start, 16);
            const endBase = parseInt(end, 16);
            const average = (endBase - startBase) / midPoint;
            const finalBase = Math.round(average * (newNumber - this.minNum) + startBase);
            const balancedFinalBase = finalBase < 16 ? `0${finalBase.toString(16)}` : finalBase.toString(16);

            return balancedFinalBase;
        };

        const getHexColor = (color: string) => color.substring(color.length - 6, color.length);

        this.setColorGradient = (colorStart: string, colorEnd: string) => {
            this.startColor = getHexColor(colorStart);
            this.endColor = getHexColor(colorEnd);
        };

        this.setMidpoint = (minNumber: number, maxNumber: number) => {
            this.minNum = minNumber;
            this.maxNum = maxNumber;
        };

        this.getColor = (numberValue: number) => {
            if (numberValue === 1) {
                return `#${this.startColor}`;
            }
            if (numberValue) {
                const hexValue = `#${
                    generateHex(
                        numberValue,
                        this.startColor.substring(0, 2),
                        this.endColor.substring(0, 2),
                    )
                }${generateHex(
                    numberValue,
                    this.startColor.substring(2, 4),
                    this.endColor.substring(2, 4),
                )
                }${generateHex(
                    numberValue,
                    this.startColor.substring(4, 6),
                    this.endColor.substring(4, 6),
                )}`;

                return hexValue;
            }

            return undefined;
        };
    }
}

export default GradientColor;
