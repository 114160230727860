import { AuthorizedContent, useAuthUser } from '@frontegg/react';
import CloseIcon from '@mui/icons-material/Close';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import { Box } from '@mui/material';
import React, { Dispatch, FC, SetStateAction } from 'react';
import RolesType from '../../../../utils/enum/RolesType';
import { CloseIconFilterStyled, LockIconFilterStyled } from './styled';

export interface ActionsFilterButtonsProps {
    handleDeleteFilter: Function,
    handleLockFilter: Function,
    setOpenLockIcon: Dispatch<SetStateAction<boolean>>
    openLockIcon: boolean
}

const ActionsFilterButtons: FC<ActionsFilterButtonsProps> = ({ handleDeleteFilter, handleLockFilter, setOpenLockIcon, openLockIcon }) => {
    const user = useAuthUser();

    const changeLockStatus = () => {
        if (user.roles.find((role: {key: string}) => [RolesType.ADMIN as string, RolesType.ADMIN_MOAI as string].includes(role.key))) {
            handleLockFilter();
            setOpenLockIcon(!openLockIcon);
        }
    };

    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <LockIconFilterStyled onClick={changeLockStatus} size='small' disableRipple>
                {openLockIcon && (
                    <LockOpenIcon sx={{ width: '18px' }} />
                )}
                {!openLockIcon && (
                    <LockIcon sx={{ width: '18px' }} />
                )}
            </LockIconFilterStyled>
            <AuthorizedContent requiredRoles={[RolesType.ADMIN, RolesType.ADMIN_MOAI]}>
                <CloseIconFilterStyled onClick={handleDeleteFilter} size='small'>
                    <CloseIcon sx={{ width: '18px' }} />
                </CloseIconFilterStyled>
            </AuthorizedContent>
        </Box>

    );
};

export default ActionsFilterButtons;
