import { Avatar } from '@mui/material';
import styled from 'styled-components';
import { colors, fontFamilyPrimary, fontSize, fontWeight } from '../../../theme';
import { ButtonStyled, MenuStyled } from '../../../theme/style';

interface ButtonDropdownStyledProps {
    menuopen?: boolean
}

export const ButtonDropdownStyled = styled(ButtonStyled)(({ menuopen }: ButtonDropdownStyledProps) => `
  && {
    height: 40px;
    margin: 11px 0 11px 72px;
    padding: 4px;
    background-color: ${menuopen ? colors.grey000 : colors.grey700};
    border: ${menuopen ? '1px solid' : '1px solid'};
    border-color: ${menuopen ? colors.default : 'transparent'};

    &:active {
        border: 1px solid ${colors.default};
    }
    &:hover {
        background: ${colors.grey800};
        border: 1px solid ${colors.grey500}
    }
  }
`);

export const MenuDropdownStyled = styled(MenuStyled)`
  && {
      left: -16px;
      margin-top: 45px;
      .MuiMenu-list {
        padding: 8px;
        box-shadow: 0px 10px 60px rgba(33, 22, 69, 0.16);
        border-radius: 8px;
        background-color: ${colors.grey000};
        text-align: right;
    }
    .MuiMenu-paper {
        border-radius: 8px;
    }
  }
`;

export const AvatarStyled = styled(Avatar)`
    && {
        width: 32px;
        height: 32px;
        font-size: ${fontSize.sm};
        font-weight: ${fontWeight.semiBold};
        background-color: ${colors.grey600};
        color: ${colors.grey100};
        font-family: ${fontFamilyPrimary}
    }
`;
