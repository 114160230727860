import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { TypographyStyledGlobal, fontWeight, colors } from '../../../../theme';
import AnswersHotAlerts from '../answerHotAlert';

interface AnswersDetailBoxProps {
    username: string
    hotAlertDetails: {
        hotalertId: string
        user: {
            answers: {
                id: number
                label: string
                value: string
                answerid: string
            }[]
        }
        survey: {
            answer: string
            answerid: string
            question: string
            id: string
        }
    }
}

const AnswersDetailBox: FC<AnswersDetailBoxProps> = ({ username, hotAlertDetails }) => {
    const { t } = useTranslation('hotAlerts');

    return (
        <>
            <TypographyStyledGlobal
                color={colors.grey200}
                margin='48px 0 16px 0'
                size='21px'
                weight={fontWeight.semiBold}
            >
                {t('details.openAnswerOf', { name: username })}
            </TypographyStyledGlobal>
            <AnswersHotAlerts
                hotAlertId={hotAlertDetails?.hotalertId}
                label={hotAlertDetails?.survey?.answer}
                openDataId={hotAlertDetails?.survey?.answerid}
                question={hotAlertDetails?.survey?.question}
                surveyId={hotAlertDetails?.survey?.id}
                editInput
            />
            <TypographyStyledGlobal
                color={colors.grey200}
                margin='48px 0 16px 0'
                size='21px'
                weight={fontWeight.semiBold}
            >
                {t('details.allAnswerOf', { name: username })}
            </TypographyStyledGlobal>
            {hotAlertDetails?.user?.answers?.map(({ id, value, label }) => (
                <AnswersHotAlerts
                    key={id}
                    editInput={false}
                    label={value}
                    question={label}
                />
            ))}
        </>

    );
};

export default AnswersDetailBox;
