import myHeaders from '../myHeaders';

const createUserByFrontegg = async (roleId: string, userEmail: string, metadata: string) => {
    const createUserFrontEgg = `${process.env.REACT_APP_FRONTEGG_BASE_URL}/identity/resources/users/v2`;
    const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: JSON.stringify({ email: userEmail, roleIds: [roleId], metadata }),
    };

    return fetch(createUserFrontEgg, requestOptions)
        .then((response) => response.json())
        .then((response) => response.id)
        .catch((error) => {
            throw Error(error);
        });
};

export default createUserByFrontegg;
