import { ModalitiesGroupProps } from '../../components/Modals/DashboardModal/dashboardModalInterface';
import { TreeItem } from '../../components/SortableTree/types';
import { DraggableEntities } from '../DraggableEntities/DraggableEntities';

export default class GroupModality implements DraggableEntities {
    idGroup: string;

    label: string;

    constructor({ idGroup, label }: ModalitiesGroupProps) {
        this.idGroup = idGroup;
        this.label = label;
    }

    toTreeItem(): TreeItem {
        return { id: this.idGroup, label: this.label, children: [], isGroupale: true };
    }
}
