import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useSWR, { useSWRConfig } from 'swr';
import deleteMailingList from '../../../api/mailingList/deleteMailingList';
import updateMailingListName from '../../../api/mailingList/updateMailingListName';
import useFetcher from '../../../utils/hooks/useFetcher';
import Dropdown from '../../Dropdown/Dropdown';
import AddMailingListAction from './addMailingListAction';
import AddUserInMailingListAction from './addUserInMailingListAction';
import EmptyMailingListContent from './emptyMailingListContent';
import { MailingListInterface } from './mailingListInterface';
import { AddMailingListButtonStyled, HeaderMailingListStyled, MailingListActionsHeaderStyled, MailingListTitleStyled } from './styled';
import TableMailingList from './TableMailingList/tableMailingList';
import sortedItems from './utilities';

const MailingList: FC = () => {
    const { t } = useTranslation('settings');
    const fetcher = useFetcher();
    const { mutate } = useSWRConfig();
    const mailingListUrl = `${process.env.REACT_APP_API_URL}/mailing_list/`;
    const { data: fetchedMailingList } = useSWR(mailingListUrl, fetcher);
    const [mailingListFromFrontEgg, setMailingListFromFrontEgg] = useState<MailingListInterface[] | undefined>(undefined);
    const [currentMailingList, setCurrentMailingList] = useState<{name: string, id: string}>({ name: '', id: '' });
    const [openCreateMailingListModale, setOpenCreateMailingListModale] = useState<boolean>(false);

    useEffect(() => {
        setMailingListFromFrontEgg(fetchedMailingList);
        if (fetchedMailingList) {
            setCurrentMailingList(fetchedMailingList.find((ml: MailingListInterface) => ml?.id === currentMailingList?.id) || fetchedMailingList.sort(sortedItems)[0]);
        }
    }, [fetchedMailingList]);

    const refreshMutate = () => {
        mutate(mailingListUrl, setMailingListFromFrontEgg(fetchedMailingList));
        mutate(mailingListUrl, setCurrentMailingList(() => fetchedMailingList.find((ml: MailingListInterface) => ml.id === currentMailingList.id)));
    };

    const editNameMailingList = (value: string) => {
        updateMailingListName(currentMailingList.id, value, t('mailingList.editMailingListSuccess', { newName: value }), t('mailingList.editMailingListError'), refreshMutate);
    };

    const handleDeleteMailingList = () => {
        deleteMailingList(currentMailingList.id, t('mailingList.modal.successDeleteMailingList', { mailingListName: currentMailingList?.name }), t('mailingList.modal.errorDeleteMailingList', { mailingListName: currentMailingList?.name }), refreshMutate);
    };

    const editActionsLabel = {
        title: t('mailingList.modal.editMailingList', { mailingList: currentMailingList?.name }),
        inputTitle: t('mailingList.modal.editMailingListInputTitle'),
        labelButton: t('mailingList.modal.editMailingListButton'),
    };

    const [isVisible, setIsVisible] = useState(true);

    const listenToScroll = () => {
        const heightToHideFrom = 211.5;
        const winScroll = document.body.scrollTop
            || document.documentElement.scrollTop;
        if (winScroll > heightToHideFrom && isVisible) {
            setIsVisible(false);
        } else {
            setIsVisible(true);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', listenToScroll);

        return () => window.removeEventListener('scroll', listenToScroll);
    }, []);

    return (
        <>
            {mailingListFromFrontEgg && currentMailingList && (
                <>
                    <HeaderMailingListStyled>
                        <MailingListTitleStyled>{currentMailingList?.name}</MailingListTitleStyled>
                        <MailingListActionsHeaderStyled>
                            {mailingListFromFrontEgg.length > 1 && (
                                <Dropdown
                                    currentElement={currentMailingList}
                                    deleteMailingList={handleDeleteMailingList}
                                    editActions={editNameMailingList}
                                    editActionsLabel={editActionsLabel}
                                    editValue={currentMailingList.name}
                                    items={mailingListFromFrontEgg}
                                    setCurrentElement={setCurrentMailingList}
                                />
                            )}
                            <AddUserInMailingListAction mailingListId={currentMailingList.id} refreshMutate={refreshMutate} />
                            <AddMailingListAction
                                openCreateMailingListModale={openCreateMailingListModale}
                                setCurrentElement={setCurrentMailingList}
                                setOpenCreateMailingListModale={setOpenCreateMailingListModale}
                            />
                        </MailingListActionsHeaderStyled>
                    </HeaderMailingListStyled>
                    <TableMailingList mailingListId={currentMailingList.id} />
                </>
            )}

            {mailingListFromFrontEgg && mailingListFromFrontEgg.length === 0 && (
                <>
                    <AddMailingListButtonStyled>
                        <AddMailingListAction
                            openCreateMailingListModale={openCreateMailingListModale}
                            setCurrentElement={setCurrentMailingList}
                            setOpenCreateMailingListModale={setOpenCreateMailingListModale}
                        />
                    </AddMailingListButtonStyled>
                    <EmptyMailingListContent />
                </>
            )}
            {!isVisible && (
                <AddMailingListButtonStyled>
                    <AddUserInMailingListAction mailingListId={currentMailingList.id} refreshMutate={refreshMutate} />
                </AddMailingListButtonStyled>
            )}
            {(!isVisible && mailingListFromFrontEgg && mailingListFromFrontEgg.length === 0) && (
                <AddMailingListAction
                    openCreateMailingListModale={openCreateMailingListModale}
                    setCurrentElement={setCurrentMailingList}
                    setOpenCreateMailingListModale={setOpenCreateMailingListModale}
                />
            )}
        </>
    );
};

export default MailingList;
