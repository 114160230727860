import { Box } from '@mui/material';
import React, { FC } from 'react';

interface TabPanelProps {
    children: any
    value: string,
    index: string
}

const TabPanel: FC<TabPanelProps> = ({ children, value, index }) => (
    <div
        aria-labelledby={`create-indicator-${index}`}
        hidden={value !== index}
        role="tabpanel"
    >
        {value === index && (
            <Box>
                {children}
            </Box>
        )}
    </div>
);

export default TabPanel;
