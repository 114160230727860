import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { Box, Button, FormControl, FormControlLabel, FormLabel, TextField } from '@mui/material';
import InfiniteScroll from 'react-infinite-scroll-component';
import styled from 'styled-components';
import { colors, fontFamilyPrimary, fontSize, fontWeight } from '../../../../theme';
import { ButtonStyled, MenuItemsStyled } from '../../../../theme/style';

interface CardStyledProps {
    disabled?: boolean
}

export const CardStyled = styled(Box)(({ disabled }: CardStyledProps) => `
    background-color: ${disabled ? colors.grey600 : colors.grey000};
    color: ${colors.black000};
    box-shadow: 0px 10px 60px rgba(33, 22, 69, 0.04);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    width: 48%;
    padding: 26px;
    justify-content: center;
    align-items: center;
    transition: background-color 500ms ease-in-out;
    box-sizing: border-box;
    &:hover {
        background-color: ${disabled ? colors.grey600 : colors.lighten};
        cursor: ${disabled ? 'not-allowed' : 'pointer'};
        transition: background-color 500ms ease-in-out;
    }
`);

export const FlexBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;
    margin-top: 24px;
    flex-wrap: wrap;
    box-sizing: border-box;
`;

export const TitleStyled = styled.h2`
    color: ${colors.black000};
    margin-top: 0;
    font-weight: ${fontWeight.semiBold};
`;

export interface ActionButtonStyledProps {
    default?: boolean
}

export const ActionButtonStyled = styled(ButtonStyled)((props: ActionButtonStyledProps) => `
    && {
        width: 50%;
        padding: 12px;
        background-color: ${props.default ? colors.default : colors.grey000};
        color: ${props.default ? colors.grey000 : colors.default};
        font-weight: ${fontWeight.bold};
        max-height: 48px;
        &:hover {
            background-color: ${props.default ? colors.defaultHover : colors.grey700};
        }
        &:disabled {
            background-color: ${colors.grey600};
            color: ${colors.grey300};
        }
    }
`);

export const ActionBackButtonStyled = styled(ActionButtonStyled)`
    && {
        width: 100%;
        margin-top: 32px;
    }
`;

export const ActionButtonContainer = styled.div`
    display: flex;
    gap: 16px;
    margin-top: 32px;
`;

interface QuestionStyledProps {
    questionchecked?: boolean
}

export const BadgeQuestionStyled = styled.div(({ questionchecked }: QuestionStyledProps) => `
    display: inline;
    width: auto;
    border: 1px solid;
    border-color:  ${questionchecked ? colors.grey000 : colors.grey600};
    color: ${colors.black000};
    border-radius: 4px;
    padding: 4px;
    font-size: ${fontSize.s};
`);

export const CardQuestionStyled = styled(FormControlLabel)(({ questionchecked }: QuestionStyledProps) => `
&& {
    box-shadow: 0px 10px 60px rgba(33, 22, 69, 0.04);
    border-radius: 8px;
    padding: 12px;
    flex-direction: column;
    align-items: flex-start;
    margin: 4px 0;
    border: 1px solid;
    background-color: ${questionchecked ? colors.lighten : colors.grey000};
    border-color: ${questionchecked ? colors.default : 'transparent'};
    .MuiCheckbox-root {
        display: none;
    }
    &: hover {
        background-color: ${questionchecked ? colors.lighten : colors.grey600};
        border-color:  ${questionchecked ? colors.default : 'transparent'};

    }
    &:hover ${BadgeQuestionStyled} {
        border-color: ${questionchecked ? colors.grey000 : colors.grey400};
        color: ${questionchecked ? colors.grey100 : colors.grey100};
    }
}
`);

export const FormControlStyled = styled(FormControl)`
    && { width: 100%;
    max-height: 70vh;
    overflow-y: scroll;
    padding-right: 12px;
    &::-webkit-scrollbar {
        width: 2px;
        background-color: ${colors.grey500};
    }
    &::-webkit-scrollbar-thumb {
        background-color: ${colors.grey400};
    }
}
`;

export const FormLabelStyled = styled(FormLabel)`
    && {
        color: ${colors.grey200};
        font-family: ${fontFamilyPrimary};
        font-size: ${fontSize.sm};
        font-weight: ${fontWeight.regular};
        margin-top: 24px;
        display: none;
        & span {
            color:${colors.grey400};
        }
        &.Mui-focused {
            color:  ${colors.grey200};
        }
    }
`;

interface FormControlLabelStyledProps {
    ischecked: boolean
}

export const FormControlLabelStyled = styled(FormControlLabel)(({ ischecked }: FormControlLabelStyledProps) => `
    && {
        display: flex;
        & .MuiFormControlLabel-label {
            color: ${ischecked ? colors.grey100 : colors.grey300};
            font-family: ${fontFamilyPrimary};
            font-size: ${fontSize.sm};
            font-weight: ${fontWeight.regular};
        }
    }
`);

export const MenuItemSelectStyled = styled(MenuItemsStyled)`
    && {
        align-items: flex-start;
        margin-left: 8px;
        margin-right: 8px;
    }
`;

export const KeyFigureViewStyled = styled(Box)`
    && {
        border: 1px dashed ${colors.grey400};
        width: fit-content;
        padding: 12px 24px;
        border-radius: 8px;
        margin-top: 8px;
        color: ${colors.grey100};
        font-size: ${fontSize.l};
        font-weight: ${fontWeight.bold};
        display: none;
    }
`;

export const PlaceholderInputStyled = styled(FormLabelStyled)`
    && {
        color: ${colors.grey400};
        font-weight: ${fontWeight.semiRegular}
    }
`;

export const ButtonRefineModalitiesStyled = styled(Button)`
 && {
    color: ${colors.default};
    text-transform: none;
    font-weight: ${fontWeight.bold};
    font-family: ${fontFamilyPrimary};
    font-size : ${fontSize.m};
    text-decoration: underline;
    display: block;
    text-align: right;
    padding: 12px 16px;
    margin-bottom: 4px;
    &:hover {
        background-color: transparent;
    }
 }
`;

export const ModalitiesLegendStyled = styled.div`
    display: flex;
    margin-bottom: 24px;
`;

export const CardAnswerModalities = styled(Box)`
    && {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: ${colors.grey000};
        padding: 8px 12px 8px 8px;
        border-radius: 4px;
        width: 100%;
        box-sizing: border-box;
        min-height: 54px;
    }
`;

export const InputGroupStyled = styled(TextField)`
    && {
        margin-left: 16px;
        width: 100%;
        input::placeholder {
            color: ${colors.grey400};
        }
        input {
            font-family: ${fontFamilyPrimary};
            font-size: 14px;
            font-weight: ${fontWeight.regular};
            padding: 0;
            color: ${colors.grey200};
        }
        fieldset {
            border: none;
        }
    }
`;

export const FlexColumnRight = styled(Box)`
    && {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        align-items: flex-end;
        justify-content: center;
    }
`;

export const StyledInfiniteScroll = styled(InfiniteScroll)`
    && {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        padding-right: 12px;
        &::-webkit-scrollbar {
            width: 2px;
            background-color: ${colors.grey500};
        }
        &::-webkit-scrollbar-thumb {
            background-color: ${colors.grey400};
        }
    }
`;

export const RefineQuestionWrapper = styled(Box)`
    && {
        max-height: 30vh;
        padding-right: 12px;
        overflow-y: scroll;
        &::-webkit-scrollbar {
            width: 2px;
            background-color: ${colors.grey500};
        }
        &::-webkit-scrollbar-thumb {
            background-color: ${colors.grey400};
        }
    }
`;

export const FilterImageStyled = styled(FilterAltIcon)`
    && {
        cursor: pointer;
        font-size: 1rem;
        line-height: 0;
        padding-top: 1px;
        &:hover {
            color: ${colors.grey1100};
        }
    }
`;

export const TableCellStyled = styled.div`
    && {
        display:inline-flex;
        padding: 6px 13px;
    }
`;
