import React, { FC } from 'react';
import { colors, fontWeight, TypographyStyledGlobal, fontSize } from '../../../theme';
import EditInput from '../../EditInput/editInput';
import { AnswersHotAlertsBox } from './styled';

interface AnswersHotAlertsProps {
    editInput: boolean;
    label: string
    question: string
    surveyId?: string
    hotAlertId?: string
    openDataId?: string
}

const AnswersHotAlerts: FC<AnswersHotAlertsProps> = ({ editInput, label, question, surveyId, hotAlertId, openDataId }) => (question ? (
    <AnswersHotAlertsBox sx={{ backgroundColor: 'white' }}>
        <TypographyStyledGlobal
            color={colors.grey200}
            margin='0 0 4px 0'
            size={fontSize.m}
            weight={fontWeight.semiRegular}
        >
            {question}
        </TypographyStyledGlobal>
        {editInput && (
            <EditInput editLabel='Modérer la réponse' hotAlertId={Number(hotAlertId)} openDataId={Number(openDataId)} surveyId={Number(surveyId)} title={label} isOpendataUpdate />
        )}
        {!editInput && (
            <TypographyStyledGlobal
                color={colors.grey200}
                margin='0 0 4px 0'
                size={fontSize.m}
                weight={fontWeight.semiBold}
            >
                {label}
            </TypographyStyledGlobal>
        )}
    </AnswersHotAlertsBox>
) : null);

export default AnswersHotAlerts;
