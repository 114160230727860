import {
    Announcements,
    DndContext,
    closestCenter,
    KeyboardSensor,
    PointerSensor,
    useSensor,
    useSensors,
    DragStartEvent,
    DragOverlay,
    DragMoveEvent,
    DragEndEvent,
    DragOverEvent,
    MeasuringStrategy,
    DropAnimation,
    Modifier,
    defaultDropAnimation,
    UniqueIdentifier,
} from '@dnd-kit/core';
import {
    SortableContext,
    arrayMove,
    verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import React, { Dispatch, SetStateAction, useEffect, useMemo, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import SortableTreeItem from './components/TreeItem/SortableTreeItem';

import sortableTreeKeyboardCoordinates from './keyboardCoordinates';
import type { FlattenedItem, SensorContext, TreeItem } from './types';
import {
    buildTree,
    flattenTree,
    getProjection,
    getChildCount,
    removeChildrenOf,
    addItem,
} from './utilities';

const measuring = {
    droppable: {
        strategy: MeasuringStrategy.Always,
    },
};

const dropAnimationConfig: DropAnimation = {
    keyframes({ transform }) {
        return [
            { opacity: 1, transform: CSS.Transform.toString(transform.initial) },
            {
                opacity: 0,
                transform: CSS.Transform.toString({
                    ...transform.final,
                    x: transform.final.x + 5,
                    y: transform.final.y + 5,
                }),
            },
        ];
    },
    easing: 'ease-out',
    sideEffects({ active }) {
        active.node.animate([{ opacity: 0 }, { opacity: 1 }], {
            duration: defaultDropAnimation.duration,
            easing: defaultDropAnimation.easing,
        });
    },
};

const adjustTranslate: Modifier = ({ transform }) => ({
    ...transform,
    y: transform.y - 25,
});

interface Props {
    items: TreeItem[];
    setItems : Dispatch<SetStateAction<TreeItem[]>>;
    collapsible?: boolean;
    indentationWidth?: number;
    indicator?: boolean;
    removable?: boolean;
    newItem?: TreeItem;
    handleCollapse(id: UniqueIdentifier): void;
    handleRemove(id: UniqueIdentifier): void
    itemBuilder(id: UniqueIdentifier, isChildren: number):React.ReactNode;
}

const SortableTree = ({
    items,
    setItems,
    collapsible,
    indicator = false,
    indentationWidth = 50,
    newItem,
    removable,
    handleCollapse,
    itemBuilder,
    handleRemove,
}: Props) => {
    const [activeId, setActiveId] = useState<UniqueIdentifier | null>(null);
    const [overId, setOverId] = useState<UniqueIdentifier | null>(null);
    const [offsetLeft, setOffsetLeft] = useState(0);
    const [currentPosition, setCurrentPosition] = useState<{
    parentId: UniqueIdentifier | null;
    overId: UniqueIdentifier;
  } | null>(null);

    useEffect(() => {
        if (newItem) {
            setItems((itemsToAdd) => addItem(itemsToAdd, newItem));
        }
    }, [newItem]);

    const flattenedItems = useMemo(() => {
        const flattenedTree = flattenTree(items);
        const collapsedItems = flattenedTree.reduce<any>(
            (acc, { children, collapsed, id }) => (collapsed && children.length ? [...acc, id] : acc),
            [],
        );

        return removeChildrenOf(
            flattenedTree,
            activeId ? [activeId, ...collapsedItems] : collapsedItems,
        );
    }, [activeId, items]);

    const projected = activeId && overId
        ? getProjection(
            flattenedItems,
            activeId,
            overId,
            offsetLeft,
            indentationWidth,
        )
        : null;

    const sensorContext: SensorContext = useRef({
        items: flattenedItems,
        offset: offsetLeft,
    });

    const [coordinateGetter] = useState(() => sortableTreeKeyboardCoordinates(sensorContext, indicator, indentationWidth));

    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter,
        }),
    );

    const sortedIds = useMemo(() => flattenedItems.map(({ id }) => id), [
        flattenedItems,
    ]);
    const activeItem = activeId
        ? flattenedItems.find(({ id }) => id === activeId)
        : null;

    useEffect(() => {
        sensorContext.current = {
            items: flattenedItems,
            offset: offsetLeft,
        };
    }, [flattenedItems, offsetLeft]);

    const getMovementAnnouncement = (eventName: string, activeIdMovement: UniqueIdentifier, overIdMovement?: any) => {
        let announcement;

        if (overIdMovement && projected) {
            if (eventName !== 'onDragEnd') {
                if (currentPosition && projected.parentId === currentPosition.parentId && overIdMovement === currentPosition.overId) {
                    return undefined;
                }

                setCurrentPosition({
                    parentId: projected.parentId,
                    overId: overIdMovement,
                });
            }

            const clonedItems: FlattenedItem[] = JSON.parse(JSON.stringify(flattenTree(items)));
            const overIndex = clonedItems.findIndex(({ id }) => id === overIdMovement);
            const activeIndex = clonedItems.findIndex(({ id }) => id === activeIdMovement);
            const sortedItems = arrayMove(clonedItems, activeIndex, overIndex);

            const previousItem = sortedItems[overIndex - 1];
            const movedVerb = eventName === 'onDragEnd' ? 'dropped' : 'moved';
            const nestedVerb = eventName === 'onDragEnd' ? 'dropped' : 'nested';

            if (!previousItem) {
                const nextItem = sortedItems[overIndex + 1];
                announcement = `${activeIdMovement} was ${movedVerb} before ${nextItem.id}.`;
            } else if (projected.depth > previousItem.depth) {
                announcement = `${activeIdMovement} was ${nestedVerb} under ${previousItem.id}.`;
            } else {
                let previousSibling: FlattenedItem | undefined = previousItem;
                while (previousSibling && projected.depth < previousSibling.depth) {
                    const { parentId }: any = previousSibling;
                    previousSibling = sortedItems.find(({ id }) => id === parentId);
                }

                if (previousSibling) {
                    announcement = `${activeIdMovement} was ${movedVerb} after ${previousSibling.id}.`;
                }
            }
        }

        return announcement;
    };

    const resetState = () => {
        setOverId(null);
        setActiveId(null);
        setOffsetLeft(0);
        setCurrentPosition(null);

        document.body.style.setProperty('cursor', '');
    };

    const handleDragStart = ({ active: { id: activeIdStart } }: DragStartEvent) => {
        setActiveId(activeIdStart);
        setOverId(activeIdStart);

        const activeItemStart = flattenedItems.find(({ id }) => id === activeIdStart);

        if (activeItemStart) {
            setCurrentPosition({
                parentId: activeItemStart.parentId,
                overId: activeIdStart,
            });
        }

        document.body.style.setProperty('cursor', 'grabbing');
    };

    const handleDragMove = ({ delta }: DragMoveEvent) => {
        setOffsetLeft(delta.x);
    };

    const handleDragOver = ({ over }: DragOverEvent) => {
        setOverId(over?.id ?? null);
    };

    const handleDragEnd = ({ active, over }: DragEndEvent) => {
        resetState();
        if (projected && over) {
            const { depth, parentId } = projected;
            const clonedItems: FlattenedItem[] = JSON.parse(
                JSON.stringify(flattenTree(items)),
            );
            const overIndex = clonedItems.findIndex(({ id }) => id === over.id);
            const parent = clonedItems.find(({ id }) => parentId === id);
            const activeIndex = clonedItems.findIndex(({ id }) => id === active.id);
            const activeTreeItem = clonedItems[activeIndex];
            if (parent?.isGroupale) {
                clonedItems[activeIndex] = { ...activeTreeItem, depth, parentId };
            } else {
                clonedItems[activeIndex] = { ...activeTreeItem, depth: 0, parentId: null };
            }

            const sortedItems = arrayMove(clonedItems, activeIndex, overIndex);
            const newItems = buildTree(sortedItems);

            setItems(newItems);
        }
    };

    const handleDragCancel = () => {
        resetState();
    };

    const announcements: Announcements = {
        onDragStart({ active }) {
            return `Picked up ${active.id}.`;
        },
        onDragMove({ active, over }) {
            return getMovementAnnouncement('onDragMove', active.id, over?.id);
        },
        onDragOver({ active, over }) {
            return getMovementAnnouncement('onDragOver', active.id, over?.id);
        },
        onDragEnd({ active, over }) {
            return getMovementAnnouncement('onDragEnd', active.id, over?.id);
        },
        onDragCancel({ active }) {
            return `Moving was cancelled. ${active.id} was dropped in its original position.`;
        },
    };

    return (
        <DndContext
            accessibility={{ announcements }}
            collisionDetection={closestCenter}
            measuring={measuring}
            onDragCancel={handleDragCancel}
            onDragEnd={handleDragEnd}
            onDragMove={handleDragMove}
            onDragOver={handleDragOver}
            onDragStart={handleDragStart}
            sensors={sensors}
        >
            <SortableContext items={sortedIds} strategy={verticalListSortingStrategy}>
                {flattenedItems.map(({ id, children, collapsed, depth, label }) => (
                    <SortableTreeItem
                        key={id}
                        collapsed={Boolean(collapsed && children.length)}
                        depth={id === activeId && projected ? projected.depth : depth}
                        id={id}
                        indentationWidth={indentationWidth}
                        indicator={indicator}
                        itemBuilder={itemBuilder}
                        onCollapse={
                            collapsible && children.length
                                ? () => handleCollapse(id)
                                : undefined
                        }
                        onRemove={removable ? () => handleRemove(id) : undefined}
                        value={label}
                    />
                ))}
                {createPortal(
                    <DragOverlay
                        dropAnimation={dropAnimationConfig}
                        modifiers={indicator ? [adjustTranslate] : undefined}
                        style={{ zIndex: 99999999999 }}
                    >
                        {activeId && activeItem ? (
                            <SortableTreeItem
                                childCount={getChildCount(items, activeId) + 1}
                                depth={activeItem.depth}
                                id={activeId}
                                indentationWidth={indentationWidth}
                                itemBuilder={itemBuilder}
                                value={activeId.toString()}
                                clone
                            />
                        ) : null}
                    </DragOverlay>,
                    document.body,
                )}
            </SortableContext>
        </DndContext>
    );
};

export default SortableTree;
