import { Box, FormGroup, FormControlLabel } from '@mui/material';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { colors, fontSize, fontWeight, TypographyStyledGlobal } from '../../../../../../theme';
import { SwitchStyled } from '../../../../../../theme/style';
import { KPI_STATE } from '../../../constants';
import { EventUpdateStatus } from '../../../dashboardModalInterface';
import { CardAnswerModalities } from '../../styled';

interface CardSelectedProps {
    codemodalite?: number,
    label?: string,
    hide?: boolean,
    excluded?: boolean,
    eventUpdate?(id: number, event: EventUpdateStatus): void;
    isChildren?: boolean
}

const CardSelectAnswer: FC<CardSelectedProps> = ({ codemodalite, label, eventUpdate, hide = false, excluded = false, isChildren = false }) => {
    const [hiddenAnswer, setHiddenAnswer] = useState<boolean>(hide);
    const [hiddenAnswerDisabled, setHiddenAnswerDisabled] = useState<boolean>(false);
    const [excludeAnswer, setExcludeAnswer] = useState<boolean>(excluded);
    const { t } = useTranslation('dashboard');

    const handleStatusChange = () => {
        if (eventUpdate && codemodalite) {
            if (!hiddenAnswer && !excludeAnswer) {
                eventUpdate(codemodalite, { type: KPI_STATE.DISPLAYED });
            }
            if (excludeAnswer || (hiddenAnswer && excludeAnswer)) {
                eventUpdate(codemodalite, { type: KPI_STATE.EXCLUDED });
            }
            if (hiddenAnswer && !excludeAnswer) {
                eventUpdate(codemodalite, { type: KPI_STATE.HIDDEN });
            }
        }
    };
    useEffect(handleStatusChange, [hiddenAnswer, excludeAnswer]);

    return (
        <CardAnswerModalities>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <TypographyStyledGlobal
                    color={(hiddenAnswer || excludeAnswer) ? colors.grey400 : colors.grey200}
                    margin='0 0 0 15px'
                    size={fontSize.sm}
                    sx={excludeAnswer ? { textDecoration: 'line-through' } : {}}
                    weight={fontWeight.regular}
                >
                    {label}
                </TypographyStyledGlobal>
            </Box>
            {!isChildren && (
                <FormGroup sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'nowrap' }}>
                    <FormControlLabel
                        control={<SwitchStyled checked={hiddenAnswer} disabled={hiddenAnswerDisabled} />}
                        label={(
                            <TypographyStyledGlobal
                                color={(hiddenAnswer) ? colors.grey100 : colors.grey300}
                                size={fontSize.sm}
                                weight={fontWeight.regular}
                            >
                                {t('addIndicatorModal.refineIndicatorAnswer.hide')}
                            </TypographyStyledGlobal>
                        )}
                        onChange={() => {
                            setHiddenAnswer(!hiddenAnswer);
                        }}
                        value={hiddenAnswer}
                    />
                    <FormControlLabel
                        control={(
                            <SwitchStyled checked={excludeAnswer} />
                        )}
                        label={(
                            <TypographyStyledGlobal
                                color={(excludeAnswer) ? colors.grey100 : colors.grey300}
                                size={fontSize.sm}
                                weight={fontWeight.regular}
                            >
                                {t('addIndicatorModal.refineIndicatorAnswer.exclude')}
                            </TypographyStyledGlobal>
                        )}
                        onChange={() => {
                            setExcludeAnswer(!excludeAnswer);
                            setHiddenAnswer(!excludeAnswer);
                            setHiddenAnswerDisabled(!hiddenAnswerDisabled);
                        }}
                    />
                </FormGroup>
            )}
        </CardAnswerModalities>
    );
};

export default CardSelectAnswer;
