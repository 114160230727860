import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import useSWR from 'swr';
import { colors, fontWeight, TypographyStyledGlobal } from '../../theme';
import useFetcher from '../../utils/hooks/useFetcher';
import { DashboardParams } from '../Dashboard/dashboardInterfaces';
import Loader from '../Loader/loader';
import { SurveyProps } from '../Survey/surveyInterface';
import { ButtonBackToListStyled, LinkBackToListStyled } from './DetailsPage/styled';
import HeaderHotAlertsSummary from './headerHotAlertsSummary';
import { GetHotAlertsInterface, HeaderHotAlertsSummaryProps } from './hotAlertsInterface';
import HotAlertSummaryTable from './TableSummary/hotAlertsTable';

const HotAlertsSummary: FC = () => {
    const { t } = useTranslation(['hotAlerts', 'navigation']);
    const [hotAlerts, setHotAlerts] = useState<GetHotAlertsInterface>({ items: [], page: 1, size: 1, total: 0 });
    const [page, setPage] = useState<number>(hotAlerts.page);
    const { surveyId = '' } = useParams<DashboardParams>();
    const [surveyDetail, setSurveyDetail] = useState<HeaderHotAlertsSummaryProps>(
        { name: '',
            question: {
                questionLabel: '',
                questionId: 0,
                questionType: 'OPEN',
            } },
    );

    const fetcher = useFetcher();
    const { data } = useSWR(`${process.env.REACT_APP_API_URL}/survey`, useFetcher());
    const { defaultDashboardId } = data.find((survey: SurveyProps) => String(survey.surveyid) === surveyId);
    const { data: fetchedHotAlerts } = useSWR([`${process.env.REACT_APP_API_URL}/survey/${surveyId}/hot_alert?page=${page}&size=10`], fetcher);
    const { data: hotAlertConfig } = useSWR([`${process.env.REACT_APP_API_URL}/survey/${surveyId}/hot_alert_config/`], fetcher);

    useEffect(() => {
        if (fetchedHotAlerts) {
            setHotAlerts(fetchedHotAlerts.alerts);
        }
        if (fetchedHotAlerts?.surveyDetails) {
            setSurveyDetail(fetchedHotAlerts.surveyDetails);
        }
    }, [fetchedHotAlerts, page]);

    return (
        <>
            {hotAlerts.total !== 0 && (
                <>
                    <ButtonBackToListStyled startIcon={<KeyboardArrowLeftIcon sx={{ width: '14px' }} />} disableRipple>
                        <LinkBackToListStyled
                            to={`/survey/${surveyId}/dashboard/${defaultDashboardId}`}
                        >{t('navigation:backToDashboard')}
                        </LinkBackToListStyled>

                    </ButtonBackToListStyled>
                    <HeaderHotAlertsSummary {...surveyDetail} />
                    <div>
                        <TypographyStyledGlobal
                            color={colors.grey200}
                            margin='0 0 24px 0'
                            size='21px'
                            weight={fontWeight.semiBold}
                        >

                            { t('hotAlerts:summary.count', { count: hotAlerts.total })}

                        </TypographyStyledGlobal>
                        <HotAlertSummaryTable
                            firstnameQuestionId={hotAlertConfig?.firstnameQuestionId}
                            hotAlerts={hotAlerts}
                            lastnameQuestionId={hotAlertConfig?.lastnameQuestionId}
                            page={page}
                            setPage={setPage}
                            statusList={hotAlertConfig?.status}
                            surveyId={surveyId}
                        />
                    </div>
                </>
            )}
            {hotAlerts.total === 0 && (
                <Loader />
            )}
        </>
    );
};

export default HotAlertsSummary;
