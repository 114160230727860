import styled from 'styled-components';
import { colors, fontSize, fontWeight } from '../../theme';
import { ActionButtonStyled, ActionButtonStyledProps } from '../Modals/DashboardModal/DashboardModalContent/styled';

export const OnBoardingContainerStyled = styled.div`
    display: flex;
    flex-direction: column;
    width: 50%;
    align-items: center;
    justify-content: center;
    margin: auto;
    height: 83vh;
    text-align: center;
    max-width: 450px;
`;

export const ActionsButtonContainer = styled.div`
    display:flex;
    gap: 16px;
    width: 100%;
    justify-content: center;
`;

export const ActionButtonOnBoardingStyled = styled(ActionButtonStyled)((props : ActionButtonStyledProps) => `
&& {
    padding: 16px 14px;
    font-weight: ${fontWeight.semiBold};
    font-size: ${fontSize.sm};
    width: auto;
    box-shadow: ${props.default ? colors.defaultShadow : 'none'};
    &:hover {
        box-shadow: none;
    }
};
`);
