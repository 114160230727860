import styled from 'styled-components';
import { colors, fontFamilyPrimary, fontSize, fontWeight } from '../../theme';

export const TitleStyled = styled.h1`
    font-weight: ${fontWeight.bold};
    color: ${colors.grey100};
    font-size: ${fontSize.l};
    font-family: ${fontFamilyPrimary}
`;
export const ModalTitleStyled = styled.h2`
    font-weight: ${fontWeight.semiBold};
    color: ${colors.grey100};
    font-size: ${fontSize.ml};
    font-family: ${fontFamilyPrimary};
    margin: 0 0 32px 0;
`;
