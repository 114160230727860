import { Box, Input } from '@mui/material';
import TextField from '@mui/material/TextField';
import styled from 'styled-components';
import { colors, fontFamilyPrimary, fontSize, fontWeight } from '../../../../../theme';

export const FilterTableBoxStyled = styled(Box)`
    &&{
        display: flex;
    }
`;

export const TextFieldDivStyled = styled.div`
    && {
        display: block;
        vertical-align: bottom;
    }

`;

export const ConversationDivStyled = styled.div`
    && {
        width: 95%;
        height: 100%;
        background-color: #F5F5F5;
        border-radius: 8px;
        max-height: 340px;
        padding: 38px 20px;
        overflow-y: scroll;
        &&::-webkit-scrollbar {
            -webkit-appearance: none;
            width: 7px;
        }
        &&::-webkit-scrollbar-thumb {
            border-radius: 4px;
            background-color: #A2A2A2;
        }
    }

`;

export const ChatbotResponseStyled = styled.div`
    && {
        color: ${colors.grey1300};
        font-weight: normal;
        font-size: ${fontSize.m};
        background-color: transparent;
        margin-bottom: 15px;
    }

`;

export const ChatbotQuestionStyled = styled.div`
    && {
        color: ${colors.grey1300};
        margin-bottom: 10px;
        font-weight: ${fontWeight.semiBold};
        font-size: ${fontSize.m};
        background-color: transparent;
    }

`;

export const TextFieldStyled = styled(TextField)`
    && {
        background-color: ${colors.grey1200};
        color: #8C8C8C;
        max-width: 150px;
        border-radius: 5px;
    }

`;

export const InputStyled = styled(Input)`
    && {
        font-family: ${fontFamilyPrimary};
        font-size: ${fontSize.sm};
        background-color: ${colors.grey1200};
        border-radius: 8px;
        padding: 10px 16px;
        margin-top: 8px;
        color: ${colors.grey1300};
        width: 100%;
        &::before {
            border: none;
        }
        &::after {
            border: none;
        }
        &:hover::after {
            border: none;
        }
        &:hover:not(.Mui-disabled):before {
            border: none;
        }
        & ::placeholder {
            color: ${colors.grey400};
            font-size: ${fontSize.sm};
            opacity: 1;
        }
    }
`;
