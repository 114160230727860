import { UniqueIdentifier } from '@dnd-kit/core';
import classNames from 'classnames';
import React from 'react';
import Action from '../Item/components/Action/Action';
import Handle from '../Item/components/Handle';
import Remove from '../Item/components/Remove';

import styles from './TreeItem.module.scss';

interface TreeItemProps{
    id: UniqueIdentifier;
    childCount?: number;
    clone?: boolean;
    collapsed?: boolean;
    depth: number;
    disableInteraction?: boolean;
    disableSelection?: boolean;
    ghost?: boolean;
    handleProps?: any;
    indicator?: boolean;
    indentationWidth: number;
    style: any;
    value: string;
    onCollapse?(): void;
    onRemove?(): void;
    wrapperRef?(node: HTMLLIElement): void;
    itemBuilder(id: UniqueIdentifier, isChildren: number):React.ReactNode;
}

const TreeItem = React.forwardRef<HTMLDivElement, TreeItemProps>(
    (
        {
            id,
            childCount,
            clone,
            depth,
            disableSelection,
            disableInteraction,
            ghost,
            handleProps,
            indentationWidth,
            indicator,
            collapsed,
            onCollapse,
            onRemove,
            style,
            wrapperRef,
            itemBuilder,
            ...props
        },
        ref,
    ) => (
        <li
            ref={wrapperRef}
            className={classNames(
                styles.Wrapper,
                clone && styles.clone,
                ghost && styles.ghost,
                indicator && styles.indicator,
                disableSelection && styles.disableSelection,
                disableInteraction && styles.disableInteraction,
            )}
            style={
          {
              '--spacing': `${indentationWidth * depth}px`,
          } as React.CSSProperties
            }
            {...props}
        >
            <div ref={ref} className={styles.TreeItem} style={style}>
                <Handle {...handleProps} />
                {onCollapse && (
                    <Action
                        className={classNames(
                            styles.Collapse,
                            collapsed && styles.collapsed,
                        )}
                        onClick={onCollapse}
                    >
                        T
                    </Action>
                )}
                {itemBuilder(id, indentationWidth * depth)}
                {!clone && onRemove && <Remove onClick={onRemove} />}
                {clone && childCount && childCount > 1 ? (
                    <span className={styles.Count}>{childCount}</span>
                ) : null}

            </div>
        </li>
    ),
);

export default TreeItem;
