import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import useSWR, { useSWRConfig } from 'swr';
import updateHotAlertState from '../../../api/hotAlert/updateHotAlertState';
import useFetcher from '../../../utils/hooks/useFetcher';
import Loader from '../../Loader/loader';
import { StatusHotAlertsInterface } from '../hotAlertsInterface';
import AnswersDetailBox from './AnswersDetailBox/answerBox';
import HotAlertDetailsHeader from './hotAlertDetailsHeader';
import StatusHotAlert from './statusHotAlert';
import { ButtonBackToListStyled, DetailHotAlertsColumnStyled, DetailHotAlertsContentStyled, LinkBackToListStyled } from './styled';
import UserDetails from './UserDetails/userDetails';

const HotAlertDetails: FC = () => {
    const { t } = useTranslation(['navigation', 'hotAlerts']);

    const { surveyId = '', hotAlertId = '' } = useParams();
    const fetcher = useFetcher();
    const { mutate } = useSWRConfig();
    const { data: hotAlertDetails } = useSWR([`${process.env.REACT_APP_API_URL}/survey/${surveyId}/hot_alert/${hotAlertId}/details`], fetcher);
    const { data: hotAlertConfig } = useSWR([`${process.env.REACT_APP_API_URL}/survey/${surveyId}/hot_alert_config/`], fetcher);

    const [username, setUsername] = useState('');

    useEffect(() => {
        setUsername(`${hotAlertDetails?.user?.firstname} ${hotAlertDetails?.user?.lastname?.toUpperCase()}`);
        mutate(`${process.env.REACT_APP_API_URL}/survey/${surveyId}/hot_alert/${hotAlertId}/details`);
    }, [hotAlertDetails]);

    function handleHotAlertStatusChange(status: StatusHotAlertsInterface) {
        updateHotAlertState(surveyId, hotAlertId, String(status.id), t('hotAlerts:actions.statusUpdated'), t('hotAlerts:actions.statusNotUpdated'));
    }

    return hotAlertDetails ? (
        <>
            <ButtonBackToListStyled startIcon={<KeyboardArrowLeftIcon sx={{ width: '14px' }} />} disableRipple>
                <LinkBackToListStyled
                    to={`/survey/${surveyId}/hot-alerts`}
                >{t('navigation:backToHotAlertList')}
                </LinkBackToListStyled>

            </ButtonBackToListStyled>
            <HotAlertDetailsHeader
                name={username}
                satisfaction={hotAlertDetails?.survey?.satisfaction}
            />
            <DetailHotAlertsContentStyled>
                <UserDetails hotAlertDetails={hotAlertDetails} username={username} />
                <DetailHotAlertsColumnStyled>
                    <StatusHotAlert onChange={(status) => handleHotAlertStatusChange(status)} status={hotAlertDetails?.survey?.status} statusList={hotAlertConfig?.status} username={username} />
                    <AnswersDetailBox hotAlertDetails={hotAlertDetails} username={username} />
                </DetailHotAlertsColumnStyled>

            </DetailHotAlertsContentStyled>
        </>
    ) : (<Loader />);
};

export default HotAlertDetails;
