import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import React, { FC } from 'react';
import { colors } from '../../theme';
import { AlertToasterStyled } from './styled';

interface AlertToasterProps {
    content: string
    type: string
}

const AlertToaster: FC<AlertToasterProps> = ({ content, type }) => (
    <AlertToasterStyled
        icon={<CheckCircleIcon sx={{ color: colors.grey000 }} />}
        severity={type}
        sx={{ width: '100%' }}
        type={type}
    >
        {content}
    </AlertToasterStyled>
);

export default AlertToaster;
