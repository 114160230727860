import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { colors, TypographyStyledGlobal } from '../../../theme';
import { ButtonDisabledStyled } from '../../../theme/style';

const ComingSoonCard: FC = () => {
    const { t } = useTranslation(['common', 'survey']);

    return (
        <>
            <TypographyStyledGlobal
                color={colors.grey200}
                margin='0 0 24px 0'
            >
                {t('survey:comingSoonContent')}
            </TypographyStyledGlobal>
            <ButtonDisabledStyled disableRipple>{t('common:comingSoon')}</ButtonDisabledStyled>

        </>
    );
};

export default ComingSoonCard;
