import { displayToast } from '../../components/Toaster/displayToast';

const LockFilterFetcher = async (
    surveyId: string,
    dashboardId: string,
    filterId: string,
    filters: string[],
    successMessage?: string,
    errorMessage?: string,
) => {
    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    myHeaders.append('Accept', 'application/json');
    myHeaders.append('Authorization', `Bearer ${localStorage.getItem('token')}`);

    const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: JSON.stringify({ filterId, jsonFilters: filters.toString() }),
    };

    return fetch(`${process.env.REACT_APP_API_URL}/survey/${surveyId}/dashboard/${dashboardId}/lock-filters`, requestOptions)
        .then((response) => {
            if (!response.ok && errorMessage) {
                displayToast(errorMessage, 'error');

                return undefined;
            }
            if (successMessage) {
                displayToast(successMessage, 'success');
            }

            return response.json();
        }).catch(() => {
            if (errorMessage) {
                displayToast(errorMessage, 'error');
            }
        });
};

export default LockFilterFetcher;
