import { Box } from '@mui/material';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { fontSize, TypographyStyledGlobal, colors, fontWeight } from '../../../theme';
import { CALCULATION_MODE } from '../../Modals/DashboardModal/constants';
import { KeyFigureIndicatorFooter, KeyFigureIndicatorStyled, DividerStyled, ComputeIndicatorStyled } from '../styled';

interface KeyFigureIndicatorProps {
        keyFigureNumber?: string[] | number[] | undefined | (string | number)[],
        previousNumber?: string
        round?: number
        year?: string
        computeMethod?: string
}

const KeyFigureIndicator: FC<KeyFigureIndicatorProps> = ({ keyFigureNumber, previousNumber, round, year, computeMethod }) => {
    const { t } = useTranslation('dashboard');
    let computeMethodLabel = t('percentage', { modality: computeMethod });
    const isNPSPositive = computeMethod === CALCULATION_MODE.NPS && keyFigureNumber && keyFigureNumber[0] > 0;
    const isAverage = computeMethod === CALCULATION_MODE.AVERAGE;
    const isNPS = computeMethod === CALCULATION_MODE.NPS;
    const plus = isNPSPositive ? '+' : '';
    const percent = !isNPSPositive && !isAverage && !isNPS ? '%' : '';

    if (isNPS) {
        computeMethodLabel = t('nps');
    } else if (isAverage) {
        computeMethodLabel = t('average');
    }

    return (
        <KeyFigureIndicatorStyled>
            <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                <TypographyStyledGlobal
                    color={colors.grey100}
                    margin='0 0px 0 0'
                    size={fontSize.xxl}
                    weight={fontWeight.bold}
                >
                    {plus + Number(keyFigureNumber).toFixed(round) + percent}
                </TypographyStyledGlobal>
                <ComputeIndicatorStyled>
                    {computeMethodLabel}
                </ComputeIndicatorStyled>
            </Box>
            {previousNumber && (
                <>
                    <TypographyStyledGlobal
                        color={colors.grey400}
                        fontStyle='italic'
                        size={fontSize.xl}
                        weight={fontWeight.semiBold}
                    >
                        {previousNumber}
                    </TypographyStyledGlobal>
                    <KeyFigureIndicatorFooter>
                        <DividerStyled />
                        {t('waveReminder', { year })}
                    </KeyFigureIndicatorFooter>
                </>
            )}
        </KeyFigureIndicatorStyled>
    );
};

export default KeyFigureIndicator;
