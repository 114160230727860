import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { EmptyMailingListStyled, EmptyMailingListTitle, MailingListTitleStyled } from './styled';

const EmptyMailingListContent: FC = () => {
    const { t } = useTranslation('settings');

    return (
        <>
            <EmptyMailingListTitle>
                <MailingListTitleStyled>{t('mailingList.myMailingList')}</MailingListTitleStyled>
            </EmptyMailingListTitle>

            <EmptyMailingListStyled>
                {t('mailingList.emptyMailingList')}
            </EmptyMailingListStyled>
        </>
    );
};

export default EmptyMailingListContent;
