import { Box } from '@mui/material';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { TypographyStyledGlobal, fontWeight, colors } from '../../../../theme';
import CopyBox from '../../../CopyBox/copyBox';
import Loader from '../../../Loader/loader';
import { DetailHotAlertsColumnStyled } from '../styled';
import DetailsUser from './detailsUser';

interface UserDetailsProps {
    username: string
    hotAlertDetails: {
        user: {
            contact: {
                phonenumber: string
                email: string
            }
        }
        survey: {
            details: {
                id: number
                label: string
                value: string
            }[]
            answer: string
            question: string
        }
    }
}

const UserDetails: FC<UserDetailsProps> = ({ username, hotAlertDetails }) => {
    const { t } = useTranslation('hotAlerts');

    return !hotAlertDetails ? (<Loader />) : (
        <DetailHotAlertsColumnStyled>
            <TypographyStyledGlobal
                color={colors.grey200}
                margin='0 0 24px 0'
                size='21px'
                weight={fontWeight.semiBold}
            >
                {t('details.informationOf', { name: username })}
            </TypographyStyledGlobal>

            <Box sx={{ marginBottom: '40px' }}>
                <CopyBox
                    label={t('details.phoneNumber').toString()}
                    value={hotAlertDetails.user.contact.phonenumber}
                />
                <CopyBox
                    label={t('details.email').toString()}
                    value={hotAlertDetails.user.contact.email}
                />
            </Box>

            {hotAlertDetails.survey.details.map((detail) => {
                const value = new Date(detail.value);
                // eslint-disable-next-line no-restricted-globals
                if (!isNaN(value.getTime())) {
                    return <DetailsUser key={detail.id} label={detail.label} value={new Date(detail.value).toLocaleDateString('fr')} />;
                }

                return <DetailsUser key={detail.id} label={detail.label} value={detail.value} />;
            })}
        </DetailHotAlertsColumnStyled>
    );
};

export default UserDetails;
