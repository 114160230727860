import { AuthorizedContent } from '@frontegg/react';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useSWR from 'swr';
import RolesType from '../../utils/enum/RolesType';
import useFetcher from '../../utils/hooks/useFetcher';
import Loader from '../Loader/loader';
import OnBoarding from '../OnBoarding/onboarding';
import AddDiffusionList from './Card/addDiffusionList';
import ComingSoonCard from './Card/comingSoonContent';
import SurveyCard from './Card/surveyCard';
import SurveyCardWithApi from './Card/surveyCardWithApi';
import SurveyWrapper from './Card/surveyWrapper';
import { SurveyTitleStyled, ContainerSurveyStyled, ShareSurveyTitleStyled, ShareSurveyCardStyled } from './styled';
import { SurveyProps, SurveyStatus } from './surveyInterface';

const Surveys: FC = () => {
    const { t } = useTranslation('survey');
    const [surveys, setSurveys] = useState<SurveyProps[]>([]);
    const [shareSurveysId, setShareSurveysId] = useState<string[]>(['']);
    const [displayOnboarding, setDisplayOnboarding] = useState<boolean>(false);
    const { data, error } = useSWR(`${process.env.REACT_APP_API_URL}/survey`, useFetcher());
    const { data: fetchedShareDashboard } = useSWR(`${process.env.REACT_APP_API_URL}/survey/dashboards/shared`, useFetcher());

    useEffect(() => {
        if (data) {
            setSurveys(data);
            setDisplayOnboarding(data.every(({ status }: SurveyProps) => status === SurveyStatus.IN_PREPARATION));
        }
        if (fetchedShareDashboard) {
            const surveyIds = fetchedShareDashboard.map((dashboard: any) => dashboard.surveyId);
            setShareSurveysId(surveyIds.filter((value: string, index: number) => surveyIds.indexOf(value) === index));
        }
    }, [data]);

    const displaySurveys = surveys.map((survey) => (
        <SurveyWrapper key={survey.surveyid} name={survey.customSurveyTitle ?? survey.name} surveyId={survey.surveyid}>
            {[SurveyStatus.IN_PREPARATION].includes(survey.status) && (
                <ComingSoonCard />
            )}
            {[SurveyStatus.IN_PROGRESS, SurveyStatus.COMPLETED].includes(survey.status) && (
                <SurveyCard survey={survey} />
            )}
        </SurveyWrapper>
    ));

    const getDashboardBySurveyId = (surveyId: string) => fetchedShareDashboard.map(
        (dashboard:any) => {
            if (dashboard.surveyId === surveyId) {
                return dashboard.id;
            }

            return undefined;
        },
    ).filter((id: string) => id !== undefined);

    const SurveyListWrapper: FC = () => (
        <>
            <SurveyTitleStyled marginBottom='24px'>Bonjour !</SurveyTitleStyled>
            <AuthorizedContent requiredRoles={[RolesType.ADMIN, RolesType.ADMIN_MOAI]}>
                {(!data || error) && (
                    <Loader />
                )}
                {(data && surveys.length >= 1) && (
                    <ContainerSurveyStyled>
                        {displaySurveys}
                    </ContainerSurveyStyled>
                )}
            </AuthorizedContent>
            <AuthorizedContent requiredRoles={[RolesType.ADMIN, RolesType.ADMIN_MOAI, RolesType.VIEWER_DASHBOARD]}>
                {fetchedShareDashboard && (fetchedShareDashboard.length > 0) && (
                    <ShareSurveyTitleStyled>{t('mySharedDashboard')}</ShareSurveyTitleStyled>
                )}
                <ShareSurveyCardStyled>
                    {fetchedShareDashboard && shareSurveysId.map((surveyId) => (
                        <SurveyCardWithApi key={surveyId} dashboardId={getDashboardBySurveyId(surveyId)} id={surveyId} />
                    ))}
                </ShareSurveyCardStyled>
            </AuthorizedContent>
            <AuthorizedContent requiredRoles={[RolesType.ADMIN, RolesType.ADMIN_MOAI]}>
                {(data && surveys.length >= 1) && (
                    <AddDiffusionList />
                )}
            </AuthorizedContent>
        </>
    );

    return (
        <>
            { displayOnboarding && <OnBoarding />}
            { !displayOnboarding && <SurveyListWrapper />}
        </>
    );
};

export default Surveys;
