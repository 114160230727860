import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { colors, fontSize, fontWeight, TypographyStyledGlobal } from '../../theme';
import { ButtonValidated } from '../../theme/style';
import ShareModal from '../Modals/ShareModal/shareModal';

const ShareButton: FC = () => {
    const [openShareModal, setOpenShareModal] = useState<boolean>(false);
    const { t } = useTranslation('dashboard');

    return (
        <>
            <ButtonValidated onClick={() => setOpenShareModal(true)} disableRipple>
                <ShareOutlinedIcon sx={{ paddingRight: '11px' }} />
                <TypographyStyledGlobal color={colors.grey000} size={fontSize.sm} weight={fontWeight.semiBold}>
                    {t('share.shareButton')}
                </TypographyStyledGlobal>
            </ButtonValidated>

            {openShareModal && (
                <ShareModal
                    openShareModal={openShareModal}
                    setOpenShareModal={setOpenShareModal}
                />
            )}
        </>
    );
};

export default ShareButton;
