import styled from 'styled-components';

export const DefaultLayoutStyled = styled.div`
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  justify-content: space-between;
  @media print {
    padding: 20px;
}

`;

export const MainStyled = styled.div`
    min-height: 83vh;
    overflow: hidden;
    padding: 16px 32px;
    @media print {
        width: 100%;
    }
`;
