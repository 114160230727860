import { AuthorizedContent } from '@frontegg/react';
import React, { FC } from 'react';
import RolesType from '../../../utils/enum/RolesType';
import { CloseButtonStyled, DragButtonStyled, ActionsCardStyled } from './styled';

// TODO : https://thetribeio.atlassian.net/browse/MOAI-67
type ActionsCardProps = {
    handleDeleteCard: () => void;
};

const ActionsCard: FC<ActionsCardProps> = ({ handleDeleteCard }) => (
    <ActionsCardStyled>
        <DragButtonStyled sx={{ display: 'none' }} />
        <AuthorizedContent requiredRoles={[RolesType.ADMIN, RolesType.ADMIN_MOAI]}>
            <CloseButtonStyled onClick={handleDeleteCard} />
        </AuthorizedContent>
    </ActionsCardStyled>
);

export default ActionsCard;
