import { FormGroup } from '@mui/material';
import React, { FC, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { fontWeight, TypographyStyledGlobal, colors } from '../../../../../theme';
import { adaptTranslationByChartTypeTranslationByChartType, CALCULATION_MODE, CHART_INDICATOR_NAME, CreateIndicatorContext, QUESTION_TYPE, KPI_STATE } from '../../constants';
import { AddIndicatorProps } from '../../dashboardModalInterface';
import ActionsButton from '../ActionsButton/actionButton';
import CalculationMode from './NumericIndicator/calculationMode';
import CustomTitleInput from './NumericIndicator/customTitleInput';
import DecimalRadioButtons from './NumericIndicator/decimalRadioButtons';
import ResultNumericIndicator from './NumericIndicator/resultNumericIndicator';

const AddNumericIndicator: FC<AddIndicatorProps> = ({
    createIndicator,
    selectedQuestion,
    setCurrentContent,
    setCreateIndicator,
    setOpenModal,
    displayType,
    isEdit,
}) => {
    const { t } = useTranslation(['common', 'dashboard']);
    const [calculationMode, setCalculationMode] = useState<CALCULATION_MODE>(createIndicator.computeMethod as CALCULATION_MODE || CALCULATION_MODE.AVERAGE);
    const [resultView, setResultView] = useState<boolean>(isEdit ? createIndicator.displayModality.isInteger : false);
    const { setSendCreateIndicator } = useContext(CreateIndicatorContext);

    useEffect(() => {
        if (selectedQuestion.type === QUESTION_TYPE.CLOSED_DATA) {
            if (!isEdit) {
                setCalculationMode(CALCULATION_MODE.PERCENTAGE);
                setCreateIndicator((prev) => ({ ...prev, kpiType: 'CLOSED_DATA', displayModality: { ...prev.displayModality, isInteger: resultView }, computeMethod: CALCULATION_MODE.PERCENTAGE }));
            } else {
                const computeMethodTmp = createIndicator.computeMethod as CALCULATION_MODE;
                setCalculationMode(computeMethodTmp);
                setCreateIndicator((prev) => ({ ...prev, kpiType: 'CLOSED_DATA', displayModality: { ...prev.displayModality, isInteger: resultView }, computeMethod: computeMethodTmp }));
            }
        } else {
            setCreateIndicator((prev) => ({ ...prev, kpiType: 'NUMERIC', displayModality: { ...prev.displayModality, isInteger: resultView }, computeMethod: CALCULATION_MODE.AVERAGE }));
        }
    }, []);

    const handleCreation = () => {
        if (createIndicator.displayType === CHART_INDICATOR_NAME.KEY_FIGURE || createIndicator.displayType === CHART_INDICATOR_NAME.LINE_CHART) {
            setCreateIndicator((prev) => {
                const newKpiModalityConfig = prev.kpiModalityConfig?.map((item) => {
                    if (item.modalityCode !== createIndicator.displayModality.donutKeyModality) {
                        // if modality state is not excluded we have to hide it. The displayed result in card need to be the selected one.
                        return { ...item, state: item.state !== KPI_STATE.EXCLUDED ? KPI_STATE.HIDDEN : item.state };
                    }

                    return item;
                });

                const newKpiGroupConfig = prev.kpiGroupConfig?.map((item) => {
                    if (item.modalityCodes !== createIndicator.displayModality.donutKeyModality) {
                        // if modality state is not excluded we have to hide it. The displayed result in card need to be the selected one.
                        return { ...item, state: item.state !== KPI_STATE.EXCLUDED ? KPI_STATE.HIDDEN : item.state };
                    }

                    return item;
                });

                return { ...prev, kpiModalityConfig: newKpiModalityConfig, kpiGroupConfig: newKpiGroupConfig };
            });
        }
        if (createIndicator.displayModality === undefined) {
            setCreateIndicator((prev) => ({ ...prev, displayModality: { ...prev.displayModality, isInteger: false } }));
        }
        setOpenModal(false);
        setCurrentContent('sendIndicator');
        setSendCreateIndicator(true);
    };

    return (
        <FormGroup sx={{ width: '100%' }}>
            <CustomTitleInput
                defaultValue={createIndicator.customItemTitle}
                displayType={displayType}
                setCreateIndicator={setCreateIndicator}
            />
            {createIndicator.displayType === CHART_INDICATOR_NAME.DONUT && (
                <TypographyStyledGlobal
                    color={colors.black000}
                    margin='15px 0 12px 0'
                    size='18px'
                    weight={fontWeight.semiBold}
                >
                    {t('dashboard:addIndicatorModal.addDonutIndicator.selectKeyFigureTitle')}
                </TypographyStyledGlobal>
            )}
            {createIndicator.displayType !== CHART_INDICATOR_NAME.HORIZONTAL_BAR && (
                <CalculationMode
                    calculationMode={calculationMode}
                    createIndicator={createIndicator}
                    isEdit={isEdit}
                    selectedQuestion={selectedQuestion}
                    setCalculationMode={setCalculationMode}
                    setCreateIndicator={setCreateIndicator}
                />
            )}

            <DecimalRadioButtons
                createIndicator={createIndicator}
                isIntegerView={resultView}
                setCreateIndicator={setCreateIndicator}
                setIsIntegerView={setResultView}
            />

            {calculationMode.length > 0 && (
                <ResultNumericIndicator value={resultView ? 93 : 93.4} />
            )}

            <ActionsButton
                handleClickBack={() => setCurrentContent('selectDisplayType')}
                handleClickNext={handleCreation}
                isDisabled={calculationMode.length <= 0}
                labelBack={t('common:back')}
                labelNext={adaptTranslationByChartTypeTranslationByChartType(displayType).button}
            />

        </FormGroup>

    );
};

export default AddNumericIndicator;
