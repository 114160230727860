import { Checkbox, CircularProgress } from '@mui/material';
import React, { Dispatch, FC, SetStateAction, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import useSWR from 'swr';
import { colors, fontSize, fontWeight, TypographyStyledGlobal } from '../../../../../theme';
import useFetcher from '../../../../../utils/hooks/useFetcher';
import useCustomPagination from '../../../../../utils/hooks/usePagination';
import { DashboardParams } from '../../../../Dashboard/dashboardInterfaces';
import { CreateIndicatorContext } from '../../constants';
import { CreateIndicatorFetchProps, SelectedQuestionProps } from '../../dashboardModalInterface';
import ActionsButton from '../ActionsButton/actionButton';
import { CardQuestionStyled, StyledInfiniteScroll } from '../styled';

interface AddGlobalVerbatimIndicatorProps {
    setCurrentContent: Dispatch<SetStateAction<string>>,
    setCreateIndicator: Dispatch<SetStateAction<CreateIndicatorFetchProps>>,
    setOpenModal: Dispatch<SetStateAction<boolean>>,
    selectedQuestion: SelectedQuestionProps,
    maxSelectedQuestion: number,
    handleChangeCount: (count: number) => void,
    isEdit?: boolean,
    createIndicator: CreateIndicatorFetchProps,
}

const AddGlobalVerbatimIndicator: FC<AddGlobalVerbatimIndicatorProps> = ({ setCreateIndicator, setCurrentContent, setOpenModal, selectedQuestion, maxSelectedQuestion, handleChangeCount, isEdit, createIndicator }) => {
    const { t } = useTranslation(['common', 'dashboard']);
    const { surveyId, dashboardId } = useParams<DashboardParams>();
    const [question, setQuestion] = useState<Array<SelectedQuestionProps>>([]);
    const [selectedAnswer, setSelectedAnswer] = useState<SelectedQuestionProps[]>([]);
    const pagination = useCustomPagination();
    const { setSendCreateIndicator } = useContext(CreateIndicatorContext);

    const handleCreation = () => {
        setCreateIndicator((prev) => ({ ...prev, kpiType: 'OPEN', additionalVars: selectedAnswer.map((questionToAdd:SelectedQuestionProps) => questionToAdd.question_id), kpiModalityConfig: [], kpiGroupConfig: [] }));
        setOpenModal(false);
        setCurrentContent('sendIndicator');
        setSendCreateIndicator(true);
    };

    const handleChange = (answer: SelectedQuestionProps) => {
        if (selectedAnswer.some((e) => e.question_id === answer.question_id)) {
            const anwerToAdd = selectedAnswer.filter((elt) => elt.question_id !== answer.question_id);
            setSelectedAnswer(anwerToAdd);
            handleChangeCount(anwerToAdd.length);
        } else if (selectedAnswer.length < maxSelectedQuestion) {
            setSelectedAnswer((prev) => [...prev, answer]);
            handleChangeCount([...selectedAnswer, answer].length);
        }
    };

    const isAnswerChecked = (
        selectedAnswerChecked: SelectedQuestionProps[],
        questionId: number,
    ) => selectedAnswerChecked.some((e) => e.question_id === questionId);

    const { data: fetchedQuestions, error } = useSWR(
        `${process.env.REACT_APP_API_URL}/survey/${surveyId}/dashboard/${dashboardId}/questions/`,
        useFetcher(),
    );

    useEffect(() => {
        const fetchedQuestionsToDisplay = fetchedQuestions?.filter((item: SelectedQuestionProps) => item.type === 'ClosedData');
        if (fetchedQuestionsToDisplay) {
            setQuestion((prev) => [...prev, ...fetchedQuestionsToDisplay]);
            pagination.setTotalItem(fetchedQuestionsToDisplay.count);
        }
        const editSelectedAnswer = isEdit && fetchedQuestionsToDisplay?.filter((elt: SelectedQuestionProps) => createIndicator.additionalVars.indexOf(elt.question_id) >= 0);
        setSelectedAnswer(editSelectedAnswer as SelectedQuestionProps[] || []);
        handleChangeCount(editSelectedAnswer?.length || 0);
    }, [fetchedQuestions]);

    const fetchMoreData = () => {
        pagination.setCurrentPage((prev) => prev + 1);
    };

    return (
        <>
            <TypographyStyledGlobal
                color={colors.grey300}
                margin='0 0 8px 0'
                size={fontSize.sm}
                weight={fontWeight.regular}
            >
                {t('dashboard:addIndicatorModal.addVerbatimIndicator.subtitle', { question: selectedQuestion.label })}
            </TypographyStyledGlobal>
            {error && (
                <TypographyStyledGlobal
                    color={colors.grey300}
                    margin='0 0 8px 0'
                    size={fontSize.sm}
                    weight={fontWeight.semiRegular}
                >
                    {t('dashboard:addIndicatorModal.addVerbatimIndicator.noVerbatim')}
                </TypographyStyledGlobal>
            )}
            {(question && !error) && (
                <StyledInfiniteScroll
                    dataLength={question.length}
                    hasMore={false}
                    height='60vh'
                    loader={<CircularProgress color="secondary" />}
                    next={fetchMoreData}
                >
                    {question.map((answer: SelectedQuestionProps) => (
                        <CardQuestionStyled
                            key={answer.question_id}
                            control={<Checkbox />}
                            isDisabled={selectedAnswer.length >= maxSelectedQuestion}
                            label={(
                                <TypographyStyledGlobal
                                    margin='0px 0px 4px 0px'
                                    sx={{ color: colors.grey100, fontSize: '14px' }}
                                    weight='500'
                                >
                                    {answer.label}
                                </TypographyStyledGlobal>

                            )}
                            onChange={() => handleChange(answer)}
                            questionchecked={isAnswerChecked(selectedAnswer, answer.question_id) ? 1 : 0}
                        />

                    ))}

                </StyledInfiniteScroll>
            )}

            <ActionsButton
                handleClickBack={() => {
                    setCurrentContent('selectDisplayType');
                    setCreateIndicator((prev: CreateIndicatorFetchProps) => ({ ...prev, additionalVars: [] }));
                }}
                handleClickNext={() => (handleCreation())}
                isDisabled={false}
                labelBack={t('common:back')}
                labelNext={t('common:next')}
            />
        </>
    );
};

export default AddGlobalVerbatimIndicator;
