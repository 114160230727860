import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CloseIconStyled } from '../../../../theme/style';
import DeleteContentModal from '../../../Modals/DeleteModal/deleteModal';

interface DeleteUserCellInterface {
    userId: string;
    email: string
    mailingListName: string
    handleDeleteUser: (userId: string, email: string) => void
}

const DeleteUserCell: FC<DeleteUserCellInterface> = ({ userId, email, mailingListName, handleDeleteUser }) => {
    const { t } = useTranslation('settings');
    const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
    const handleDeleteUserTest = () => {
        handleDeleteUser(userId, email);
        setOpenDeleteModal(false);
    };
    const modalTitle = t('mailingList.deleteUserModal', { userEmail: email, mailingListName });

    return (
        <>
            <CloseIconStyled onClick={() => setOpenDeleteModal(true)} />
            {openDeleteModal && (
                <DeleteContentModal
                    handleClickConfirm={handleDeleteUserTest}
                    handleClose={() => setOpenDeleteModal(false)}
                    openModal={openDeleteModal}
                    title={modalTitle}
                />
            )}
        </>
    );
};

export default DeleteUserCell;
