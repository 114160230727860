import { Accordion, AccordionDetails, AccordionSummary, FormControl } from '@mui/material';
import styled from 'styled-components';
import { colors, fontWeight } from '../../../theme';
import { ButtonStyled } from '../../../theme/style';

interface ActionButtonStyledProps {
    default?: boolean
}

export const ActionButtonContainer = styled.div`
    display: flex;
    gap: 16px;
    margin-top: 32px;
    width: 100%;
    justify-content: center;
`;

export const ActionButtonStyled = styled(ButtonStyled)((props : ActionButtonStyledProps) => `
    && {
        padding: 12px;
        width: 100%;
        background-color: ${props.default ? colors.default : colors.grey000};
        color: ${props.default ? colors.grey000 : colors.default};
        font-weight: ${fontWeight.bold};
        max-height: 48px;
        &:hover {
            background-color: ${props.default ? colors.defaultHover : colors.grey700};
        }
        &:disabled {
            background-color: ${colors.grey600};
            color: ${colors.grey300};
        }
    }
`);

export const FormControlStyled = styled(FormControl)`
    && { width: 100%;
    max-height: 70vh;
    overflow-y: scroll;
    padding-right: 12px;
    background-color: ${colors.grey800};
    &::-webkit-scrollbar {
        width: 2px;
        background-color: ${colors.grey500};
    }
    &::-webkit-scrollbar-thumb {
        background-color: ${colors.grey400};
    }
}
`;

export const StyledAccordionSummary = styled(AccordionSummary)`
    && {
        color: ${colors.grey000};
        height: 40px;
    }
`;

export const StyledAccordionDetails = styled(AccordionDetails)`
    && {
        border: none;
        background-color: ${colors.grey800};
        padding: 0 0 0 24px;
        margin: 0px;
    }
`;

export const StyledAccordion = styled(Accordion)`
    && {
        border-radius: 4px;
        margin-bottom: 8px;
        border: none;
        box-shadow: none;
        ::before {
            display: none;
        }
        ::focus {
            display: none;
        }
    }
`;
