import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DoneIcon from '@mui/icons-material/Done';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { fontWeight, TypographyStyledGlobal, colors, fontSize } from '../../theme';
import { CopyBoxStyled, CopyBoxContent, ButtonCopyStyled } from './styled';

interface CopyBoxProps {
    label: string
    value: string
}

const CopyBox: FC<CopyBoxProps> = ({ label, value }) => {
    const { t } = useTranslation('common');
    const [copyText, setCopyText] = useState(t('copy'));

    const handleClick = () => {
        navigator.clipboard.writeText(value);
        setCopyText(t('copied'));

        setTimeout(() => {
            setCopyText(t('copy'));
        }, 2000);
    };

    return (
        <CopyBoxStyled>
            <CopyBoxContent>
                <TypographyStyledGlobal
                    color={colors.grey400}
                    size={fontSize.m}
                    weight={fontWeight.semiBold}
                >
                    {label} :
                </TypographyStyledGlobal>
                <TypographyStyledGlobal
                    color={colors.grey100}
                    size={fontSize.m}
                    weight={fontWeight.semiBold}
                >
                    {value}
                </TypographyStyledGlobal>
            </CopyBoxContent>

            <ButtonCopyStyled
                endIcon={copyText === t('copy') ? <ContentCopyIcon /> : <DoneIcon />}
                onClick={handleClick}
                disableRipple
            >
                {copyText}
            </ButtonCopyStyled>
        </CopyBoxStyled>
    );
};

export default CopyBox;
