import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { TypographyStyledGlobal, fontSize, fontWeight, colors } from '../../../theme';
import { ButtonValidated } from '../../../theme/style';

const EmptyContent: FC = () => {
    const navigate = useNavigate();
    const { t } = useTranslation('dashboard');

    return (
        <>
            <TypographyStyledGlobal
                color={colors.grey200}
                margin="32px 0"
                size={fontSize.m}
                weight={fontWeight.regular}
            >
                {t('share.modal.emptyContent')}
            </TypographyStyledGlobal>
            <ButtonValidated onClick={() => navigate('/settings')} sx={{ width: '100%' }} disableRipple> {t('share.modal.createNew')}</ButtonValidated>
        </>
    );
};

export default EmptyContent;
