import myHeaders from '../myHeaders';

const addUserInMailingList = async (userId: string, userEmail: string, mailingListId: string, mutate: () => void) => {
    const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: JSON.stringify({ user_email: userEmail, user_frontegg_id: userId }),
    };

    return fetch(`${process.env.REACT_APP_API_URL}/mailing_list/${mailingListId}/add_user`, requestOptions)
        .then((response) => response.json())
        .then(() => mutate())
        .catch((error) => {
            throw Error(error);
        });
};

export default addUserInMailingList;
