import { Box } from '@mui/material';
import styled from 'styled-components';
import { colors, fontSize } from '../../theme';
import { ButtonWithoutBackgroundStyled } from '../../theme/style';

export const CopyBoxStyled = styled(Box)`
    && {
        border: 1px dashed ${colors.default};
        padding: 16px;
        border-radius: 8px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 8px;
        margin-bottom: 12px;
        background-color: ${colors.grey000};
    }
`;

export const CopyBoxContent = styled(Box)`
    && {
        display: flex;
        gap: 8px;
    }
`;

export const ButtonCopyStyled = styled(ButtonWithoutBackgroundStyled)`
    && {
        padding: 0;
        font-size: ${fontSize.m};
        &:hover {
            background: transparent;
        }
    }
`;
