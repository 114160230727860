import styled from 'styled-components';
import { colors, fontWeight, fontSize } from '../../../../theme';
import { ButtonStyled } from '../../../../theme/style';
import { ReactComponent as ResetFiltersImage } from '../../../../assets/filters/resetFilters.svg';

export const ResetButton = styled(ButtonStyled)`
    && {
        font-weight: ${fontWeight.regular};
        padding: 5px 12px;
        margin: 5px 0px 0px 0px;;
        background-color: ${colors.grey000};
        border: 1px solid;
        border-color: ${'transparent'};
        color: ${colors.grey1000};
        width: 247px;
        font-size: ${fontSize.sm}
    }
`;

export const ResetFiltersImageStyled = styled(ResetFiltersImage)`
    && {
        padding-right: 5px;
        width: 20px;
        min-width:20px;
    }
`;
