import { AuthorizedContent } from '@frontegg/react';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { Grid } from '@mui/material';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import useSWR from 'swr';
import { colors } from '../../theme';
import { LogoStyled } from '../../theme/style';
import RolesType from '../../utils/enum/RolesType';
import useFetcher from '../../utils/hooks/useFetcher';
import isElementVisible from '../../utils/isElementVisible';
import { SurveyProps } from '../Survey/surveyInterface';
import DropdownUser from './DropdownUserMenu/dropdownUserMenu';
import { AppBarStyled, LinkStyled, FlexBox, ButtonHeaderStyled } from './styled';

const Header: FC = () => {
    const location = useLocation();
    const { t } = useTranslation('navigation');
    const [hasHotAlert, setHasHotAlert] = useState<boolean>(false);
    const locationSplit = location.pathname.split('/');
    const linkToHotAlert = `/survey/${locationSplit[2]}/hot-alerts/`;
    const fetcher = useFetcher();
    const { data: SurveysConfig } = useSWR([`${process.env.REACT_APP_API_URL}/survey`], fetcher);

    useEffect(() => {
        if (locationSplit[2]) {
            const SurveyConfig = SurveysConfig.filter((element: SurveyProps) => element.surveyid === Number(locationSplit[2]))[0];
            setHasHotAlert(SurveyConfig.hotAlertCount > 0);
        }
    }, [locationSplit]);

    return (
        <AppBarStyled position="static">
            <Grid alignItems="center" justifyContent="center" container>
                <FlexBox justifyContent='flex-start'>
                    <Link style={{ display: 'flex' }} to="/">
                        <LogoStyled />
                    </Link>
                    {isElementVisible(location.pathname, '/') && (
                        <ButtonHeaderStyled startIcon={<KeyboardArrowLeftIcon />} disableRipple>
                            <LinkStyled
                                to="/"
                                underline="none"

                            >
                                {t('backToHome')}
                            </LinkStyled>
                        </ButtonHeaderStyled>
                    )}
                </FlexBox>
                <FlexBox justifyContent='flex-end'>
                    <AuthorizedContent requiredRoles={[RolesType.ADMIN, RolesType.ADMIN_MOAI]}>
                        {((locationSplit.includes('dashboard') && locationSplit.includes('survey')) && hasHotAlert) && (
                            <Link to={linkToHotAlert}>
                                <ButtonHeaderStyled
                                    colorfont={location.pathname === '/hot-alerts' ? colors.default : colors.grey300}
                                    disableRipple
                                >
                                    {t('hotAlert')}
                                </ButtonHeaderStyled>
                            </Link>
                        )}
                    </AuthorizedContent>
                    <DropdownUser />
                </FlexBox>
            </Grid>
        </AppBarStyled>
    );
};

export default Header;
