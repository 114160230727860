import { Box, Checkbox } from '@mui/material';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { TypographyStyledGlobal } from '../../../../../theme';
import { QUESTION_TYPE } from '../../constants';
import { CardQuestionModalProps, TypeOfQuestionIdProps } from '../../dashboardModalInterface';
import { BadgeQuestionStyled, CardQuestionStyled } from '../styled';

const CardQuestionModal: FC<CardQuestionModalProps> = ({
    question,
    setTypeOfQuestionId,
    typeOfQuestionId,
    setSelectedQuestion,
}) => {
    const { t } = useTranslation('dashboard');

    const numberOfModalities = question.modality_count;

    const translationTypeQuestion = {
        [QUESTION_TYPE.NUMERIC]: t('addIndicatorModal.selectIndicatorQuestion.questionType.numeric'),
        [QUESTION_TYPE.OPEN]: t('addIndicatorModal.selectIndicatorQuestion.questionType.open'),
        [QUESTION_TYPE.CLOSED_DATA]: t('addIndicatorModal.selectIndicatorQuestion.questionType.unique'),
    };

    const isQuestionChecked = (
        typeQuestionId?: number,
        questionId?: number,
    ) => typeQuestionId === questionId;

    const handleChange = () => {
        const newTypeOfQuestionId = { id: question.question_id, modalityCount: numberOfModalities } as TypeOfQuestionIdProps;
        setTypeOfQuestionId(newTypeOfQuestionId);
        setSelectedQuestion(question);
    };

    const translateTypeQuestion = (typeQuestion: QUESTION_TYPE, isMultiple: boolean) => {
        if (isMultiple && typeQuestion === QUESTION_TYPE.CLOSED_DATA) {
            return t('addIndicatorModal.selectIndicatorQuestion.questionType.multiple');
        }

        return translationTypeQuestion[typeQuestion];
    };

    return (
        <CardQuestionStyled
            key={`card-${question.question_id}`}
            control={<Checkbox />}
            label={(
                <>
                    <TypographyStyledGlobal
                        margin='0px 0px 4px 0px'
                        sx={{ color: 'black', fontSize: '14px' }}
                        weight='500'
                    >
                        {question.label}
                    </TypographyStyledGlobal>
                    <Box sx={{ display: 'flex', gap: '4px' }}>
                        <BadgeQuestionStyled
                            className='badge-question'
                            questionchecked={isQuestionChecked(typeOfQuestionId.id, question.question_id) ? 1 : 0}
                        >
                            {translateTypeQuestion(question.type, question.isMultiple)}
                        </BadgeQuestionStyled>
                        { numberOfModalities > 0 && (
                            <BadgeQuestionStyled
                                className='badge-question'
                                questionchecked={isQuestionChecked(typeOfQuestionId.id, question.question_id) ? 1 : 0}
                            >
                                {numberOfModalities} {t('addIndicatorModal.selectIndicatorQuestion.modalities')}
                            </BadgeQuestionStyled>
                        )}
                    </Box>
                </>
            )}
            onChange={handleChange}
            questionchecked={isQuestionChecked(typeOfQuestionId.id, question.question_id) ? 1 : 0}
        />
    );
};

export default CardQuestionModal;
