import { Table, TableBody, TableHead } from '@mui/material';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useSWR, { useSWRConfig } from 'swr';
import deleteUserFromMailingList from '../../../../api/mailingList/deleteUserFromMailingList';
import useFetcher from '../../../../utils/hooks/useFetcher';
import { TableMailingListInterface, UserInterface } from '../mailingListInterface';
import { TableCellHead, TableRowStyled, TableContainerStyled } from '../styled';
import TableRow from './tableRow';

const TableMailingList: FC<TableMailingListInterface> = ({ mailingListId }) => {
    const { t } = useTranslation('settings');
    const fetcher = useFetcher();
    const { mutate } = useSWRConfig();
    const [mailingListUsersId, setMailingListUsersId] = useState<[]>([]);
    const { data: mailingList } = useSWR([`${process.env.REACT_APP_API_URL}/mailing_list/${mailingListId}`], fetcher);
    const { data: users } = useSWR([`${process.env.REACT_APP_FRONTEGG_BASE_URL}/identity/resources/users/v2?ids=${mailingListUsersId}`], fetcher);

    useEffect(() => {
        if (mailingList) {
            const usersId = mailingList.users.map((user: {fronteggId: string}) => user.fronteggId);
            setMailingListUsersId(usersId);
        }
    }, [mailingList, users]);

    const handleDeleteUser = (userId: string, email: string) => {
        const successMessage = t('mailingList.modal.successMessageDeleteUser', { userEmail: email });
        const errorMessage = t('mailingList.modal.errorMessageDeleteUser', { user: email });
        deleteUserFromMailingList(mailingListId, userId, successMessage, errorMessage);
        mutate(`${process.env.REACT_APP_API_URL}/mailing_list/`, setMailingListUsersId(mailingList.users.map((user: {fronteggId: string}) => user.fronteggId)));
    };

    const tableCell = [t('mailingList.tableCell.email'), t('mailingList.tableCell.lastConnection'), t('mailingList.tableCell.role'), ''];

    return (
        <TableContainerStyled>
            {users && (
                <Table sx={{ minWidth: 650, marginBottom: '3rem' }}>
                    <TableHead>
                        <TableRowStyled>
                            {tableCell.map((cell, index) => (
                                <TableCellHead id={`${cell}-${index}`}>{cell}</TableCellHead>
                            ))}
                        </TableRowStyled>
                    </TableHead>
                    <TableBody>
                        {users?.items.map((user: UserInterface) => (
                            <TableRow
                                email={user.email}
                                handleDeleteUser={handleDeleteUser}
                                id={user.id}
                                lastLogin={user.lastLogin}
                                mailingListName={mailingList?.name}
                            />
                        ))}
                    </TableBody>
                </Table>
            )}
        </TableContainerStyled>
    );
};

export default TableMailingList;
