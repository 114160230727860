import Box from '@mui/material/Box';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import Title from '../Title/title';
import LinkTab from './linkTab';
import MailingList from './MailingList/mailingList';
import { TabsSettingsStyled, SettingsStyled } from './styled';
import TabPanel from './TabPanel/tabPanel';

const Settings: FC = () => {
    const { hash } = useLocation();
    const [currentContent, setCurrentContent] = useState(hash || '#mailing-list');
    const { t } = useTranslation('settings');

    const handleChange = (event: React.SyntheticEvent, newValue: string): void => {
        setCurrentContent(newValue);
    };

    return (
        <SettingsStyled>
            <Title content={t('title')} />
            <Box sx={{ marginTop: '24px' }}>
                <TabsSettingsStyled onChange={handleChange} value={currentContent}>
                    <LinkTab currentContent={currentContent} label={t('tabs.mailingList')} setCurrentContent={setCurrentContent} value="#mailing-list" disableRipple wrapped />
                </TabsSettingsStyled>
                <TabPanel index='#mailing-list' value={currentContent}>
                    <MailingList />
                </TabPanel>
            </Box>
        </SettingsStyled>
    );
};

export default Settings;
