import React, { FC } from 'react';
import { fontWeight, colors, fontSize } from '../../../../theme';
import { DetailsContentStyled, DetailsUserStyled } from '../styled';

interface DetailsUserProps {
    label: string;
    value: string
}

const DetailsUser: FC<DetailsUserProps> = ({ label, value }) => (
    <DetailsUserStyled>
        <DetailsContentStyled
            color={colors.grey300}
            size={fontSize.m}
            weight={fontWeight.regular}
        >
            {label}
        </DetailsContentStyled>
        <DetailsContentStyled
            color={colors.grey100}
            size={fontSize.m}
            weight={fontWeight.semiBold}
        >
            {value}
        </DetailsContentStyled>
    </DetailsUserStyled>
);

export default DetailsUser;
