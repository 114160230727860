import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { fontWeight, TypographyStyledGlobal, colors, fontSize } from '../../../theme';
import { HeaderHotAlertTitleStyled } from '../styled';
import { ReactComponent as EmojiAngry } from '../../../assets/hotAlert/angry.svg';
import { ReactComponent as EmojiHappy } from '../../../assets/hotAlert/happy.svg';

interface HotAlertDetailsHeaderProps {
    name: string;
    satisfaction: boolean
}

const HotAlertDetailsHeader: FC<HotAlertDetailsHeaderProps> = ({ name, satisfaction }) => {
    const { t } = useTranslation('hotAlerts');

    return (
        <HeaderHotAlertTitleStyled>
            <div>
                <TypographyStyledGlobal
                    color={colors.grey100}
                    margin='15px 0 0 0'
                    size={fontSize.l}
                    sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}
                    weight={fontWeight.bold}
                >
                    {t('details.title', { name, satisfaction: satisfaction ? t('details.satisfied') : t('details.dissatisfied') })}
                    {satisfaction ? <EmojiHappy /> : <EmojiAngry />}
                </TypographyStyledGlobal>
                <TypographyStyledGlobal
                    color={colors.grey200}
                    margin='12px 0 48px 0'
                    size={fontSize.m}
                    sx={{ lineHeight: '24px' }}
                    weight={fontWeight.semiRegular}
                />
            </div>
            {/* <Link  to='/settings#hot-alerts'>
                <SettingsOutlinedIconStyled />
            </Link> */}
        </HeaderHotAlertTitleStyled>
    );
};

export default HotAlertDetailsHeader;
