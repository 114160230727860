import Fade from '@mui/material/Fade';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import useSWR from 'swr';
import { colors, fontSize, fontWeight, TypographyStyledGlobal } from '../../../theme';
import useFetcher from '../../../utils/hooks/useFetcher';
import { DashboardParams } from '../../Dashboard/dashboardInterfaces';
import Title from '../../Title/title';
import BoxModalStyled from '../styled';
import ActionsButton from './actionButton';
import FilterAccordion from './filterAccordion';
import { Filter, FilterModalProps, FilterResult, Modality } from './filterModalInterface';
import { CloseIconModalStyled, FormControlStyled, ModalStyled } from './styled';

const FilterModal: FC<FilterModalProps> = ({ openModal, setOpenModal, updateDashboard, setUpdatedDasboard, alreadySelectedFilter }) => {
    const { t } = useTranslation(['common', 'dashboard']);
    const { surveyId } = useParams<DashboardParams>();

    const { data } = useSWR(`${process.env.REACT_APP_API_URL}/filter/${surveyId}/filters`, useFetcher());

    const handleClose = () => {
        setOpenModal(false);
    };
    // TODO https://mui.com/material-ui/react-progress/
    const mapOnObject = (modality: Filter['modalities']) : Modality[] => {
        const modalities : Modality[] = [];
        for (const [key, value] of Object.entries(modality)) {
            modalities.push({ key, value });
        }

        return modalities;
    };

    const [selectedFilterWithModality, setSelectedFilterWithModality] = useState<FilterResult[]>([]);
    const [selectedModalityId, setSelectedModalityId] = useState<string[]>([]);
    useEffect(() => {
        if (alreadySelectedFilter) {
            setSelectedFilterWithModality(alreadySelectedFilter);
        }
    }, [alreadySelectedFilter]);

    const handleClickConfirm = () => {
        setUpdatedDasboard((prevState) => {
            if (prevState) {
                const updatedCopy = { ...prevState };
                updatedCopy.dashboardFilterConfig = selectedFilterWithModality ?? [];

                return updatedCopy;
            }

            return prevState;
        });
        updateDashboard(selectedFilterWithModality);
        setOpenModal(false);
    };

    const handleSelectedFilterWithModality = (filterId: string, modalityId: string, checked: boolean): void => {
        const indexOf = selectedFilterWithModality.findIndex((alreadySelected: FilterResult) => alreadySelected.filterId === filterId);
        if (checked) {
            if (indexOf === -1) {
                setSelectedFilterWithModality([...selectedFilterWithModality, { filterId, modalitiesIds: [modalityId] }]);
            } else {
                const newSelectedModality = [...selectedFilterWithModality[indexOf].modalitiesIds, modalityId];

                selectedFilterWithModality.splice(indexOf, 1);
                setSelectedFilterWithModality([...selectedFilterWithModality, { filterId, modalitiesIds: newSelectedModality }]);
            }
        } else {
            const modalityIndexId = selectedFilterWithModality[indexOf].modalitiesIds.findIndex((index:string) => index === modalityId);

            setSelectedModalityId(selectedModalityId.filter((alreadySelectedModality: string) => alreadySelectedModality !== modalityId));
            selectedFilterWithModality[indexOf].modalitiesIds.splice(modalityIndexId, 1);
            setSelectedFilterWithModality([...selectedFilterWithModality]);
        }
    };

    const selectAllModalityIdOfAFilter = (filterId: string): Array<string> => {
        const modalityIds = data
            .filter((filters: Filter) => filters.id.toString(10) === filterId)
            .map((filter: Filter) => filter.modalities)
            .map((modalities: Modality) => mapOnObject(modalities).map((modality: Modality) => modality.key));

        return modalityIds.flat();
    };

    const handleSelectAll = (selectAllFilterId: string, checked:boolean) => {
        const indexOf = selectedFilterWithModality.findIndex((alreadySelected: FilterResult) => alreadySelected.filterId === selectAllFilterId.toString());
        if (indexOf !== -1) {
            selectedFilterWithModality.splice(indexOf, 1);
        }
        if (checked) {
            setSelectedFilterWithModality([...selectedFilterWithModality,
                { filterId: selectAllFilterId.toString(), modalitiesIds: selectAllModalityIdOfAFilter(selectAllFilterId.toString()) }]);
        } else {
            setSelectedFilterWithModality([...selectedFilterWithModality, { filterId: selectAllFilterId.toString(), modalitiesIds: [] }]);
        }
    };
    const alreadySelectedModality = (filterId: number): Array<string> | undefined => {
        const alreadySelectedModalities = alreadySelectedFilter?.filter((oldFilter: FilterResult) => filterId === parseInt(oldFilter.filterId, 10))
            .map((filter) => filter.modalitiesIds)
            .flatMap((modalityId: Array<string>) => modalityId);

        return alreadySelectedModalities ?? [];
    };

    return (
        <ModalStyled
            aria-describedby="modal-modal-description"
            aria-labelledby="modal-modal-title"
            onClose={handleClose}
            open={openModal}
        >
            <Fade in={openModal}>
                <BoxModalStyled>
                    <Title content={t('dashboard:addFilter.modal.title')} />
                    <TypographyStyledGlobal
                        color={colors.grey200}
                        marginBottom={2}
                        size={fontSize.m}
                        weight={fontWeight.semiBold}
                    >
                        {t('dashboard:addFilter.modal.description')}
                    </TypographyStyledGlobal>

                    <FormControlStyled>
                        {data && data.map((filter:Filter) => (
                            <FilterAccordion
                                key={filter.id}
                                alreadySelectedModality={alreadySelectedModality(filter.id)}
                                filter={filter}
                                handleSelectAll={handleSelectAll}
                                handleSelectedFilterWithModality={handleSelectedFilterWithModality}
                                mapOnObject={mapOnObject}
                            />
                        ))}
                    </FormControlStyled>
                    <CloseIconModalStyled onClick={handleClose} />
                    <ActionsButton
                        handleClickCancel={handleClose}
                        handleClickConfirm={handleClickConfirm}
                        labelCancel={t('common:cancel')}
                        labelConfirm={t('common:confirmModification')}
                    />
                </BoxModalStyled>
            </Fade>
        </ModalStyled>
    );
};

export default FilterModal;
