import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ResetButton, ResetFiltersImageStyled } from './styled';

interface ButtonResetFiltersProps {
    handleResetClauseFilter: (resetFilters: number) => void;
}

const ButtonResetFilters: FC<ButtonResetFiltersProps> = ({ handleResetClauseFilter }) => {
    const { t } = useTranslation('dashboard');

    return (
        <ResetButton
            onClick={() => handleResetClauseFilter(1)}
            startIcon={<ResetFiltersImageStyled />}
            variant="text"
            disableRipple
        > {t('filter.resetFilters')}
        </ResetButton>
    );
};

export default ButtonResetFilters;
