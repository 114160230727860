import { FormControl, Tooltip } from '@mui/material';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import updateHotAlertState from '../../../api/hotAlert/updateHotAlertState';
import { colors, fontFamilyPrimary, fontSize, fontWeight } from '../../../theme';
import { ArrowDropDownIconStyled, ArrowForwardIconStyled, ChatBubbleOutlineIconStyled } from '../../../theme/style';
import { ColumnLabels, CreateRowInterface, hotAlertsColors } from '../constants';
import {
    ColorHotAlertInterface,
    ColumnsInterface,
    HotAlertsInterface,
    StatusHotAlertsInterface,
    VariablesHotAlertsInterface,
} from '../hotAlertsInterface';
import {
    TableRowStyled,
    LinkToDetailStyled,
    TableCellStyled,
    SelectStatusHotAlertStyled,
    MenuItemStyled,
} from '../styled';
import { ReactComponent as EmojiAngry } from '../../../assets/hotAlert/angry.svg';
import { ReactComponent as EmojiHappy } from '../../../assets/hotAlert/happy.svg';

interface HotAlertRowInterface {
    row: HotAlertsInterface
    columns: ColumnsInterface[]
    statusList: Array<StatusHotAlertsInterface>
    surveyId: string
}

function colorStatus(label: string, statusList: Array<StatusHotAlertsInterface>): ColorHotAlertInterface {
    const statusColor = statusList.find((item) => item.label === label);

    return statusColor ? statusColor.color : hotAlertsColors.GREY;
}

function getStatusIdByLabel(label: string, statusList: Array<StatusHotAlertsInterface>): any {
    const statusId = statusList.find((item) => item.label === label);

    return statusId ? statusId.id : 0;
}

export const CreateRow = ({
    columnName, row, status, setStatus, statusList, surveyId,
}: CreateRowInterface) => {
    const { id } = row;
    const navigate = useNavigate();
    const { t } = useTranslation('hotAlerts');
    const variablesData = row.variables.find((variable: VariablesHotAlertsInterface) => variable.id === columnName);

    switch (columnName) {
        case ColumnLabels.SATISFACTION:
            return (
                <TableCellStyled key={`${columnName}-${id}`} onClick={() => navigate(`${row.id}/`)} paddingcell="7px 7px 7px 8px">
                    {row[columnName] ? <EmojiHappy /> : <EmojiAngry />}
                </TableCellStyled>
            );
        case ColumnLabels.ANSWER:
            return (
                <TableCellStyled key={`${columnName}-${id}`} onClick={() => navigate(`${row.id}/`)} paddingcell="10px 0" sx={{ minWidth: 0, width: 'auto', textAlign: 'center' }}>
                    {row[columnName] && (
                        <Tooltip
                            componentsProps={{
                                tooltip: {
                                    sx: {
                                        bgcolor: colors.grey100,
                                        borderRadius: '4px',
                                        fontFamily: fontFamilyPrimary,
                                        fontSize: fontSize.s,
                                        padding: '4px 8px',
                                        color: colors.grey000,
                                        fontWeight: fontWeight.semiRegular,
                                        textAlign: 'center',
                                        '& .MuiTooltip-arrow': {
                                            color: colors.grey100,
                                        },
                                    },
                                },
                            }}
                            title={row[columnName]}
                            arrow
                        >
                            <ChatBubbleOutlineIconStyled />
                        </Tooltip>
                    )}
                </TableCellStyled>
            );
        case ColumnLabels.STATUS:
            return (
                <TableCellStyled key={`${columnName}-${id}`} paddingcell="10px 0">
                    <FormControl>
                        <SelectStatusHotAlertStyled
                            IconComponent={ArrowDropDownIconStyled}
                            MenuProps={{
                                PaperProps: {
                                    sx: {
                                        marginTop: 0.5,
                                        '& .MuiList-root.MuiList-padding.MuiMenu-list': {
                                            padding: 0,
                                        },
                                    },
                                },
                            }}
                            background={colorStatus(status.label, statusList).color}
                            fontcolor={colorStatus(status.label, statusList).fontColor}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                setStatus({
                                    label: event.target.value,
                                    color: colorStatus(event.target.value, statusList),
                                });
                                updateHotAlertState(surveyId, String(row.id), String(getStatusIdByLabel(event.target.value, statusList)), t('actions.statusUpdated'), t('actions.statusNotUpdated'));
                            }}
                            value={status.label}
                            displayEmpty
                        >
                            {statusList.map(({ label }) => (
                                <MenuItemStyled key={label} value={label}>
                                    {label}
                                </MenuItemStyled>
                            ))}

                        </SelectStatusHotAlertStyled>
                    </FormControl>
                </TableCellStyled>
            );
        case ColumnLabels.DATE:
            return (
                <TableCellStyled key={`${columnName}-${id}`} onClick={() => navigate(`${row.id}/`)} paddingcell="10px 10px">
                    {new Date(row[columnName].toString()).toLocaleDateString('fr')}
                </TableCellStyled>
            );
        default:
            if (variablesData) {
                return (
                    <TableCellStyled key={variablesData.id} onClick={() => navigate(`${row.id}/`)} paddingcell="10px 10px">
                        {variablesData.value}
                    </TableCellStyled>
                );
            }

            return (
                <TableCellStyled key={`${columnName}-${id}`} onClick={() => navigate(`${row.id}/`)} paddingcell="10px 10px">
                    {row[columnName]}
                </TableCellStyled>
            );
    }
};

const HotAlertsRow: FC<HotAlertRowInterface> = ({ row, columns, statusList, surveyId }) => {
    const [status, setStatus] = useState<StatusHotAlertsInterface>(row.status);

    useEffect(() => {
        setStatus({
            label: row.status.label,
            color: colorStatus(row.status.label, statusList),
        });
    }, [row]);

    return (
        <TableRowStyled>
            {columns.map((column) => {
                const columnName = column.id as keyof HotAlertsInterface;

                return CreateRow({
                    columnName,
                    row,
                    status,
                    setStatus,
                    statusList,
                    surveyId,
                });
            })}
            <LinkToDetailStyled className='link-to-detail-hot-alert' paddingcell="10px 0">
                <Link to={`${row.id}/`}>
                    <ArrowForwardIconStyled />
                </Link>
            </LinkToDetailStyled>
        </TableRowStyled>
    );
};

export default HotAlertsRow;
