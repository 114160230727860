import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { TypographyStyledGlobal, fontSize, fontWeight, colors } from '../../../theme';
import { ActionSurveyButtonStyled, AddDiffusionListContainerStyled, ErrorOutlinedIconStyled } from '../styled';

const AddDiffusionList: FC = () => {
    const { t } = useTranslation('survey');
    const navigate = useNavigate();

    return (
        <AddDiffusionListContainerStyled>
            <ErrorOutlinedIconStyled />
            <TypographyStyledGlobal
                color={colors.grey100}
                margin='26px 0 8px 0'
                size={fontSize.m}
                weight={fontWeight.bold}
            >
                {t('addDiffusionList.title')}
            </TypographyStyledGlobal>
            <TypographyStyledGlobal
                color={colors.grey100}
                margin='0 0 24px 0'
                size={fontSize.m}
                weight={fontWeight.semiRegular}
            >
                {t('addDiffusionList.subtitle')}
            </TypographyStyledGlobal>
            <ActionSurveyButtonStyled onClick={() => navigate('/settings#mailing-list')} default disableRipple>
                {t('addDiffusionList.button')}
            </ActionSurveyButtonStyled>
        </AddDiffusionListContainerStyled>
    );
};

export default AddDiffusionList;
