import { endOfMonth, endOfWeek, endOfYear, startOfMonth, startOfWeek, startOfYear } from 'date-fns';

const defineds = {
    startOfWeek: startOfWeek(new Date()),
    endOfWeek: endOfWeek(new Date()),
    startOfMonth: startOfMonth(new Date()),
    endOfMonth: endOfMonth(new Date()),
    startOfYear: startOfYear(new Date()),
    endOfYear: endOfYear(new Date()),
};

export const staticRanges = [
    {
        label: 'Aujourd\'hui',
        range: () => ({
            startDate: new Date(),
            endDate: new Date(),
        }),
    },
    {
        label: 'Cette semaine',
        range: () => ({
            startDate: defineds.startOfWeek,
            endDate: defineds.endOfWeek,
        }),
    },
    {
        label: 'Ce mois',
        range: () => ({
            startDate: defineds.startOfMonth,
            endDate: defineds.endOfMonth,
        }),
    },
    {
        label: 'Cette année',
        range: () => ({
            startDate: defineds.startOfYear,
            endDate: defineds.endOfYear,
        }),
    },
];
export default staticRanges;
