import React, { ChangeEvent, Dispatch, FC, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { CreateDashboardProps } from '../../Dashboard/dashboardInterfaces';
import { InputStyled, StyledFormControl } from './styled';

interface CustomTitleInputProps {
    setCreateDashboard: Dispatch<SetStateAction<CreateDashboardProps>>
}

const CustomTitleInput: FC<CustomTitleInputProps> = ({ setCreateDashboard }) => {
    const { t } = useTranslation('dashboard');
    const handleChangeTitle = (event: ChangeEvent<HTMLInputElement>) => {
        setCreateDashboard((prev) => ({ ...prev, name: event.target.value }));
    };

    return (
        <StyledFormControl>
            <InputStyled
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChangeTitle(e)}
                placeholder={t('addDashboard.placeholder')}
            />
        </StyledFormControl>
    );
};

export default CustomTitleInput;
