import { useEffect, useState } from 'react';

const useCustomPagination = () => {
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [totalItem, setTotalItem] = useState<number>(0);
    const [hasMore, setHasMore] = useState<boolean>(true);
    const itemsPerPage = 25;

    useEffect(() => {
        if (totalItem > 0 && itemsPerPage * currentPage >= totalItem) {
            setHasMore(false);
        }
    }, [currentPage]);

    return { currentPage, setCurrentPage, hasMore, setTotalItem };
};

export default useCustomPagination;
