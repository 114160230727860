import React, { ChangeEvent, Dispatch, FC, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { TypographyStyledGlobal, fontSize, fontWeight, colors } from '../../../theme';
import { ButtonValidated } from '../../../theme/style';
import CheckboxWithLabel from '../../Checkbox/checkbox';
import { FormGroupStyled, LinkModal } from '../styled';

interface ChooseMailingListProps {
    mailingList: {id: string,
    name: string}[]
    handleSendInvit: Function
    selectedMailingList: string[]
    setSelectedMailingList: Dispatch<SetStateAction<string[]>>
}

const ChooseMailingList: FC<ChooseMailingListProps> = ({ mailingList, handleSendInvit, selectedMailingList, setSelectedMailingList }) => {
    const { t } = useTranslation('dashboard');
    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const inputValue: string = e.target.id;
        if (inputValue && !selectedMailingList.includes(inputValue)) {
            setSelectedMailingList((old) => [...old, e.target.id]);
        } else {
            setSelectedMailingList((old) => {
                const oldValue = [...old];
                const removeValue = oldValue.filter((val) => val !== e.target.id);

                return removeValue;
            });
        }
    };

    return (
        <>
            <TypographyStyledGlobal
                color={colors.grey200}
                margin="32px 0 16px"
                size={fontSize.m}
                weight={fontWeight.semiBold}
            >
                {t('share.modal.selectTitle')}
            </TypographyStyledGlobal>
            <FormGroupStyled>
                {mailingList.map((list) => (
                    <CheckboxWithLabel
                        key={list.id.toString()}
                        handleChange={handleChange}
                        id={list.id}
                        label={list.name}
                        selectedMailingList={selectedMailingList}
                    />
                ))}
            </FormGroupStyled>
            <LinkModal to='/settings'> {t('share.modal.createNew')}</LinkModal>
            <ButtonValidated onClick={handleSendInvit} sx={{ width: '100%' }} disableRipple> {t('share.modal.sendInvit')}</ButtonValidated>
        </>
    );
};

export default ChooseMailingList;
