import AddIcon from '@mui/icons-material/Add';
import React, { useState, FC } from 'react';
import FilterModal from '../../../Modals/FilterModal/FilterModal';
import { FilterResults } from '../../../Modals/FilterModal/filterModalInterface';
import { UpdateDashboardProps } from '../../dashboardInterfaces';
import { AddFilterButtonStyled } from '../styled';

interface AddFilterButtonProps {
    updateDashboard: (filters: FilterResults) => void;
    setUpdatedDasboard: React.Dispatch<React.SetStateAction<UpdateDashboardProps| undefined>>;
    alreadySelectedFilter: UpdateDashboardProps['dashboardFilterConfig'] | undefined;
}

const AddFilterButton: FC<AddFilterButtonProps> = ({ updateDashboard, setUpdatedDasboard, alreadySelectedFilter }) => {
    const [openModal, setOpenModal] = useState(false);

    return (
        <>
            <AddFilterButtonStyled
                onClick={() => setOpenModal(true)}
                startIcon={<AddIcon sx={{ width: '24px' }} />}
                disableRipple
            />
            <FilterModal
                alreadySelectedFilter={alreadySelectedFilter}
                openModal={openModal}
                setOpenModal={setOpenModal}
                setUpdatedDasboard={setUpdatedDasboard}
                updateDashboard={updateDashboard}
            />
        </>
    );
};

export default AddFilterButton;
