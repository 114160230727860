import classNames from 'classnames';
import React, { CSSProperties } from 'react';

import styles from './Action.module.css';

interface ActionsProps extends React.HTMLAttributes<HTMLButtonElement> {
  active?: {
      fill: string;
      background: string;
  };
  cursor?: CSSProperties['cursor'];
  className?: string
  style?: any
}

const Action = React.forwardRef<HTMLButtonElement, ActionsProps>(
    ({ active, className, cursor, style, ...props }, ref) => (
        <button
            ref={ref}
            type='button'
            {...props}
            className={classNames(styles.Action, className)}
            style={
          {
              ...style,
              cursor,
              '--fill': active?.fill,
              background: 'transparent',
              border: 'none',
          } as CSSProperties
            }
            tabIndex={0}
        />
    ),
);

export default Action;
