import { Box, CircularProgress } from '@mui/material';
import React, { FC } from 'react';
import { colors } from '../../theme';

interface LoaderProps {
    justifyContent?: string
    size?: number
}

const Loader: FC<LoaderProps> = ({ justifyContent = 'center', size = 130 }) => (
    <Box sx={{ display: 'flex', justifyContent: { justifyContent } }}>
        <CircularProgress color='inherit' size={size} sx={{ color: colors.default }} thickness={1} />
    </Box>
);

export default Loader;
