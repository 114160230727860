import { Box } from '@mui/material';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { TypographyStyledGlobal, colors, fontSize } from '../../../theme';
import { ButtonHeaderStyled } from '../../Header/styled';
import { SelectStatusHotAlertStyled } from '../styled';

export const DetailHotAlertsContentStyled = styled(Box)`
    && {
        display: flex;
        flex-direction: row;
        gap: 48px;
        flex-wrap: nowrap;
        box-sizing: border-box;
    }
`;

export const DetailHotAlertsColumnStyled = styled(Box)`
    && {
        width: 50%;
    }
`;

export const DetailsUserStyled = styled(Box)`
    && {
        display: flex;
        gap: 54px;
    }
`;

export const DetailsContentStyled = styled(TypographyStyledGlobal)`
    && {
        width: 50%;
        margin-bottom: 16px;
    }
`;

export const AnswersHotAlertsBox = styled(Box)`
    && {
        background-color: ${colors.grey000};
        box-shadow: 0px 10px 60px rgba(33, 22, 69, 0.04);
        border-radius: 8px;
        padding: 12px 12px 8px 12px;
        margin-bottom: 8px;
    }
`;

export const StatusHotAlertStyled = styled(Box)`
    && {
        background-color: ${colors.lighten};
        border: 1px dashed ${colors.default};
        border-radius: 8px;
        padding: 24px;
    }
`;

export const SelectStatusHotAlertDetailsStyled = styled(SelectStatusHotAlertStyled)`
    && {
        .MuiSelect-select {
            padding: 12px 16px;

        }
    }
`;

export const LinkBackToListStyled = styled(Link)`
    && {
        cursor: pointer;
        text-decoration: none;
        font-size: ${fontSize.sm};
        color: ${colors.grey300};
    }
    `;

export const ButtonBackToListStyled = styled(ButtonHeaderStyled)`
    && {
        background: transparent;
        margin: 0;
        box-shadow: none;
    }
`;
