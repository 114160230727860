import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useSWRConfig } from 'swr';
import deleteFetcher from '../../../api/deleteFetcher';
import { DashboardParams } from '../../Dashboard/dashboardInterfaces';
import DeleteContentModal from '../DeleteModal/deleteModal';
import { DeleteIndicatorProps } from './ConfirmationModalInterface';

const DeleteIndicatorModal: FC<DeleteIndicatorProps> = ({ openModal, setOpenModal, indicator }) => {
    const { t } = useTranslation(['common', 'dashboard']);
    const { surveyId, dashboardId } = useParams<DashboardParams>();
    const { mutate } = useSWRConfig();
    const dashboardUrl = `${process.env.REACT_APP_API_URL}/survey/${surveyId}/dashboard/${dashboardId}`;

    const handleClose = () => {
        setOpenModal(false);
    };

    const handleClickConfirm = () => {
        deleteFetcher(
            t('dashboard:deleteIndicatorModal.success'),
            t('dashboard:deleteIndicatorModal.error'),
            `${dashboardUrl}/indicator/${indicator.id}`,
        );
        mutate(`${dashboardUrl}/indicator`);
        setOpenModal(false);
    };

    return (
        <DeleteContentModal
            handleClickConfirm={handleClickConfirm}
            handleClose={handleClose}
            openModal={openModal}
            title={t('dashboard:deleteIndicatorModal.title', { cardTitle: indicator.customItemTitle ?? indicator.question.label })}
        />
    );
};

export default DeleteIndicatorModal;
