import React, { FC } from 'react';
import { ActionsButtonProps } from './addDashboardModalInterface';
import { ActionButtonContainer, ActionButtonStyled } from './styled';

const ActionsButton: FC<ActionsButtonProps> = ({
    handleClickConfirm,
    labelConfirm,
}) => (
    <ActionButtonContainer>
        <ActionButtonStyled
            onClick={handleClickConfirm}
            variant="text"
            default
            disableRipple
        >
            {labelConfirm}
        </ActionButtonStyled>
    </ActionButtonContainer>
);

export default ActionsButton;
