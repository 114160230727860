import { AppBar } from '@mui/material';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { colors } from '../../theme';
import { ButtonStyled } from '../../theme/style';

export const AppBarStyled = styled(AppBar)`
  && {
    background: ${colors.grey000};
    box-shadow: none;
    padding: 0 32px;
    @media print{
        display: none;
    }
  }
`;

export const LinkStyled = styled(Link)`
    && {
        align-items: center;
        display: flex;
        color: ${colors.grey300};
    }
`;

interface FlexBoxProps {
    justifyContent?: string
}

export const FlexBox = styled.div(({ justifyContent }: FlexBoxProps) => `
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content:  ${justifyContent};
    width: 50%;
`);

export const ButtonHeaderStyled = styled(ButtonStyled)(({ colorfont } : any) => `
    && {
        margin-left: 32px;
        color: ${colorfont};
    }
`);
