import React from 'react';
import { DragButtonStyled } from '../../../../../Dashboard/ActionsCard/styled';
import Action from '../Action/Action';
import ActionsProps from '../Action/actionInterface';

const Handle = React.forwardRef<HTMLButtonElement, ActionsProps>(
    (props, ref) => (
        <Action
            ref={ref}
            cursor="grab"
            {...props}
        >
            <DragButtonStyled />
        </Action>
    ),
);

export default Handle;
