import { displayToast } from '../../components/Toaster/displayToast';
import myHeaders from '../myHeaders';

const createMailingList = (
    mailingListTitle: string,
    successMessage: string,
    errorMessage: string,
    mutate: () => void,
) => {
    const url = `${process.env.REACT_APP_API_URL}/mailing_list/`;

    const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: JSON.stringify({ name: mailingListTitle }),
    };

    return fetch(url, requestOptions)
        .then((response) => {
            if (!response.ok) {
                displayToast(errorMessage, 'error');

                return undefined;
            }
            displayToast(successMessage, 'success');
            mutate();

            return response.json();
        }).catch(() => {
            displayToast(errorMessage, 'error');
        });
};

export default createMailingList;
