import { colors } from '../../theme';
import AlertType from '../enum/AlertType';

const useGetAlertColor = (alert: AlertType) => {
    switch (alert) {
        case AlertType.SUCCESS:
            return colors.alertSuccess;
        case AlertType.ERROR:
            return colors.alertDanger;
        case AlertType.WARNING:
            return colors.alertWarning;
        case AlertType.INFO:
            return colors.alertInfo;
        default:
            return colors.grey000;
    }
};

export default useGetAlertColor;
