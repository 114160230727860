import { displayToast } from '../../components/Toaster/displayToast';

const updateHotAlertState = (
    surveyId: string,
    hotAlertId: string,
    newStateId: string,
    successMessage: string,
    errorMessage: string,
) => {
    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    myHeaders.append('Accept', 'application/json');
    myHeaders.append('Authorization', `Bearer ${localStorage.getItem('token')}`);

    const requestOptions = {
        method: 'POST',
        headers: myHeaders,
    };
    fetch(`${process.env.REACT_APP_API_URL}/survey/${surveyId}/hot_alert/${hotAlertId}/state/${newStateId}`, requestOptions)
        .then((response) => {
            if (!response.ok) {
                displayToast(errorMessage, 'error');
            } else {
                displayToast(successMessage, 'success');
            }
        }).catch(() => {
            displayToast(errorMessage, 'error');
        });
};

export default updateHotAlertState;
