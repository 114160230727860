import { AuthorizedContent } from '@frontegg/react';
import React, { FC } from 'react';
import RolesType from '../../../utils/enum/RolesType';
import ButtonExport from '../../ExportButton/ExportButton';
import ShareButton from '../../ShareButton/shareButton';
import Title from '../../Title/title';
import DashboardDropdown from '../DashboardDropdown/dashboardDropdown';
import DashboardHeaderStyled, { ButtonContainer } from './styled';

const DashboardHeader: FC<{name: string}> = ({ name }) => (
    <DashboardHeaderStyled>
        <Title content={name} />
        <ButtonContainer>
            <DashboardDropdown />
            <ButtonExport />
            <AuthorizedContent requiredRoles={[RolesType.ADMIN, RolesType.ADMIN_MOAI]}>
                <ShareButton />
            </AuthorizedContent>
        </ButtonContainer>
    </DashboardHeaderStyled>
);

export default DashboardHeader;
