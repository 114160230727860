import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import DateRangeIcon from '@mui/icons-material/DateRange';
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { SurveyProps } from './surveyInterface';

const surveyListElement = (survey : SurveyProps) => {
    const { t } = useTranslation('survey');
    const lastModificationDate = new Date(survey?.lastSynchronizationDate ? survey?.lastSynchronizationDate : survey?.lastModificationDate).toLocaleDateString();

    return [
        {
            key: 'date_range',
            icon: <DateRangeIcon />,
            title: t('resultDate', { date: lastModificationDate }),
        },
        {
            key: 'share',
            icon: <ShareOutlinedIcon />,
            title: t('sharesCount', { count: survey.sharesCount }),
        },
        {
            key: 'dashboardCount',
            icon: <DashboardOutlinedIcon />,
            title: t('surveyDashboard', { count: survey.dashboardCount }),
        },
        {
            key: 'hot_alert',
            icon: <NotificationsOutlinedIcon />,
            title: survey.hotAlertCount === 0 ? t('hotAlertCount', { count: survey.hotAlertCount }) : t('hotAlertCount', { count: survey.hotAlertCount }) + t('toTreatHotAlertCount', { count: survey.toTreatHotAlertCount }),
        },
    ];
};

export default surveyListElement;
