import { FormControl, TextField } from '@mui/material';
import styled from 'styled-components';
import { colors, fontFamilyPrimary, fontSize, fontWeight } from '../../theme';

export const ActionsInputStyled = styled.div`
    display: flex;
    gap: 4px;
`;

export const IconsEditContainer = styled.div`
    && {
        background-color: ${colors.grey000};
        border: 1px solid ${colors.grey600};
        padding: 6px 7px;
        width: 32px;
        height: 32px;
        display: flex;
        align-items: center;
        border-radius: 8px;
        cursor: pointer;
        box-sizing: border-box;
        svg {
            width:16px;
            color: ${colors.grey300};
        }
        &:hover {
            background-color: ${colors.grey700};
            border-color: ${colors.grey600};
            svg {
                color: ${colors.default};
            }
        }
    }
`;

export const FormControlStyled = styled(FormControl)(({ width, column } : any) => `
    && {
        display: flex;
        flex-direction: ${column || 'row'};
        gap: 8px;
        width: ${width || '80%'};
        margin-bottom: 12px;
    }
`);

export const InputTitleStyled = styled(TextField)`
    && {
        border: none;
        font-weight: ${fontWeight.semiBold};
        font-size: ${fontSize.m};
        margin: 0;
        border: none;
        width: 100%;
        .Mui-disabled, .MuiInputBase-multiline {
            padding: 0;
            border: none;
            & textarea {
                -webkit-text-fill-color: ${colors.grey100};
                font-family: ${fontFamilyPrimary};
                font-weight: ${fontWeight.semiBold};
            }
            fieldset {
                border:none;
            }
        }
    }
`;
