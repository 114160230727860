import { Checkbox, FormControlLabel } from '@mui/material';
import React, { FC, useEffect } from 'react';
import { colors, fontSize, fontWeight, TypographyStyledGlobal } from '../../../theme';

interface FilterAccordionCheckboxProps {
    modalityValue: string;
    handleSelectedFilterWithModality: (modalityId: string, checked: boolean) => void;
    isChecked: boolean;
    modalityKey: string;
}

const FilterAccordionCheckbox: FC<FilterAccordionCheckboxProps> = ({
    modalityValue,
    handleSelectedFilterWithModality,
    isChecked,
    modalityKey,
}) => {
    const [checked, setChecked] = React.useState(false);

    useEffect(() => {
        setChecked(isChecked);
    }, [isChecked]);

    const handleCheckboxChange = () => {
        handleSelectedFilterWithModality(modalityKey, !checked);
    };

    return (
        <FormControlLabel
            control={(
                <Checkbox
                    checked={checked}
                    onChange={handleCheckboxChange}
                    sx={{ color: colors.grey500, '&.Mui-checked': { color: colors.alertSuccess } }}
                />
            )}
            label={(
                <TypographyStyledGlobal
                    color={colors.grey200}
                    size={fontSize.sm}
                    weight={fontWeight.regular}
                >
                    {modalityValue}
                </TypographyStyledGlobal>
            )}
        />
    );
};

export default FilterAccordionCheckbox;
