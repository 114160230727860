import { AuthorizedContent } from '@frontegg/react';
import React, { FC, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import updateOpendata from '../../api/hotAlert/updateOpendata';
import updateCustomSurveyTitle from '../../api/survey/updateCustomTitle';
import { EditIconStyled } from '../../theme/style';
import RolesType from '../../utils/enum/RolesType';
import { ButtonCopyStyled } from '../CopyBox/styled';
import ActionsInput from './actionsEdit';
import { FormControlStyled, InputTitleStyled } from './styled';

interface EditInputProps {
    title: string
    editLabel?: string
    surveyId?: number
    showEditButton?: boolean
    isOpendataUpdate?: boolean
    hotAlertId?: number
    openDataId?: number
}

const EditInput: FC<EditInputProps> = ({ title, editLabel, surveyId, showEditButton = true, isOpendataUpdate = false, hotAlertId, openDataId }) => {
    const { t } = useTranslation(['survey', 'hotAlerts']);
    const [disabledInput, setDisabledInput] = useState<boolean>(true);
    const [customSurveyTitle, setCustomSurveyTitle] = useState<string>(title);
    const inputEl = useRef<HTMLInputElement>(null);

    useEffect(() => {
        setCustomSurveyTitle(title);
    }, [title]);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCustomSurveyTitle(event.target.value);
        inputEl.current?.focus();
    };
    const modificationAction = () => {
        if (!isOpendataUpdate) {
            const updateUrl = `${process.env.REACT_APP_API_URL}/survey/${surveyId}/custom_survey_title`;
            updateCustomSurveyTitle(customSurveyTitle, updateUrl, t('survey:customTitleSuccess'), t('survey:customTitleError'));
        } else {
            const updateUrl = `${process.env.REACT_APP_API_URL}/survey/${surveyId}/hot_alert/${hotAlertId}/moderate/${openDataId}`;
            updateOpendata(customSurveyTitle, updateUrl, t('hotAlerts:actions.customOpendataSuccess'), t('hotAlerts:actions.customOpendataError'));
        }
    };

    return (
        <>
            <FormControlStyled
                column={editLabel ? 'column' : undefined}
                width={editLabel ? '100%' : undefined}
            >
                <InputTitleStyled
                    disabled={disabledInput}
                    id="title"
                    inputRef={inputEl}
                    onChange={handleChange}
                    value={customSurveyTitle}
                    multiline
                />
                {!disabledInput && (
                    <ActionsInput
                        modificationAction={modificationAction}
                        oldTitle={title}
                        setCustomSurveyTitle={setCustomSurveyTitle}
                        setDisabledInput={setDisabledInput}
                    />
                )}
            </FormControlStyled>
            <AuthorizedContent requiredRoles={[RolesType.ADMIN, RolesType.ADMIN_MOAI]}>
                {!editLabel && showEditButton && (
                    <EditIconStyled onClick={() => setDisabledInput(!disabledInput)} sx={{ width: '24px' }} />
                )}
                {(editLabel && disabledInput) && (
                    <ButtonCopyStyled
                        onClick={() => setDisabledInput(!disabledInput)}
                        sx={{ marginBottom: '12px' }}
                    >
                        {editLabel}
                    </ButtonCopyStyled>
                )}
            </AuthorizedContent>
        </>
    );
};

export default EditInput;
