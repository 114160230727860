import { AuthorizedContent, useAuth } from '@frontegg/react';
import { ContextHolder } from '@frontegg/rest-api';
import { MenuList, Box } from '@mui/material';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { colors, fontWeight, TypographyStyledGlobal } from '../../../theme';
import {
    ArrowDropUpIconStyled,
    ArrowDropDownIconStyled,
    MenuItemsStyled,
    DividerMenuStyled,
} from '../../../theme/style';
import RolesType from '../../../utils/enum/RolesType';
import { AvatarStyled, ButtonDropdownStyled, MenuDropdownStyled } from './styled';

const DropdownUserMenu: FC = () => {
    const [openDropdownMenu, setOpenDropdownMenu] = useState<boolean>(false);
    const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);
    const { t } = useTranslation('navigation');
    const navigate = useNavigate();

    const handleChangeStatusMenu = (event: React.MouseEvent<HTMLElement>) => {
        setOpenDropdownMenu(!openDropdownMenu);
        setAnchorElNav(event.currentTarget);
    };

    const { isAuthenticated, user } = useAuth();

    const logout = () => {
        const { baseUrl } = ContextHolder.getContext();
        window.location.href = `${baseUrl}/oauth/logout?post_logout_redirect_uri=${window.location}`;
    };

    const getUserNamePrefix = (name: string) => {
        const words = name.split(' ').filter((word) => word.length);

        return words.length > 1 ? words[0][0] + words[1][0] : words[0][0];
    };

    return isAuthenticated ? (
        <ButtonDropdownStyled
            endIcon={openDropdownMenu ? <ArrowDropUpIconStyled /> : <ArrowDropDownIconStyled />}
            menuopen={openDropdownMenu ? 1 : 0}
            onClick={handleChangeStatusMenu}
            disableRipple
        >
            <Box sx={{ display: 'flex', width: '100%' }}>
                <AvatarStyled>{getUserNamePrefix(user.name)}</AvatarStyled>
                <TypographyStyledGlobal
                    color={colors.grey200}
                    margin="auto auto auto 8px"
                    weight='500'
                >
                    { user?.name }
                </TypographyStyledGlobal>
            </Box>

            <MenuDropdownStyled
                anchorEl={anchorElNav}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={openDropdownMenu}
            >
                <MenuList sx={{ padding: 0 }}>
                    <AuthorizedContent requiredRoles={[RolesType.ADMIN, RolesType.ADMIN_MOAI]}>
                        <MenuItemsStyled key='params' onClick={() => navigate('/settings')} disableRipple>
                            <TypographyStyledGlobal
                                textAlign="center"
                                weight={fontWeight.semiRegular}
                            >
                                {t('mySettings')}
                            </TypographyStyledGlobal>
                        </MenuItemsStyled>
                    </AuthorizedContent>
                    <DividerMenuStyled sx={{ display: 'none' }} />
                    <MenuItemsStyled key='logout' onClick={logout}>
                        <TypographyStyledGlobal
                            textAlign="center"
                            weight={fontWeight.semiRegular}
                        >
                            {t('logout')}
                        </TypographyStyledGlobal>
                    </MenuItemsStyled>
                </MenuList>
            </MenuDropdownStyled>
        </ButtonDropdownStyled>
    ) : null;
};

export default DropdownUserMenu;
