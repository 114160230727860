import { Fade } from '@mui/material';
import React, { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import useSWR from 'swr';
import shareDashboard from '../../../api/dashboard/shareDashboard';
import useFetcher from '../../../utils/hooks/useFetcher';
import { DashboardItemListFromApi } from '../../Dashboard/dashboardInterfaces';
import { ModalTitleStyled } from '../../Title/styled';
import { CloseIconModalStyled } from '../EditModal/styled';
import BoxModalStyled, { ModalStyled } from '../styled';
import ChooseMailingList from './chooseMailingList';
import EmptyContent from './emptyContent';

interface ShareModalInterface {
    openShareModal: boolean
    setOpenShareModal: Dispatch<SetStateAction<boolean>>
}

const ShareModal: FC<ShareModalInterface> = ({ openShareModal, setOpenShareModal }) => {
    const { t } = useTranslation('dashboard');
    const { dashboardId = '', surveyId = '' } = useParams();
    const [selectedMailingList, setSelectedMailingList] = useState<string[]>([]);
    const [mailingList, setMailingList] = useState<[]>([]);
    const [currentDashboard, setCurrentDashboard] = useState<{name: string}>({ name: '' });
    const { data: fetchedDashboards } = useSWR(`${process.env.REACT_APP_API_URL}/survey/${surveyId}/dashboard`, useFetcher());

    const { data: fetchedMailingList } = useSWR(`${process.env.REACT_APP_API_URL}/mailing_list/`, useFetcher());

    useEffect(() => {
        if (fetchedMailingList) {
            setMailingList(fetchedMailingList);
        }
    }, [fetchedMailingList]);

    useEffect(() => {
        if (fetchedDashboards) {
            const findCurrentDashboard = fetchedDashboards.find((dashboard: DashboardItemListFromApi) => dashboard.id.toString() === dashboardId);
            setCurrentDashboard(findCurrentDashboard);
        }
    }, [fetchedDashboards]);

    const handleSendInvit = () => {
        shareDashboard(selectedMailingList, dashboardId, surveyId, t('share.modal.successMessage'), t('share.modal.errorMessage'));
        setOpenShareModal(false);
    };

    return (
        <ModalStyled
            aria-describedby="modal-modal-description"
            aria-labelledby="modal-modal-title"
            onClose={() => setOpenShareModal(false)}
            open={openShareModal}
        >
            <Fade in={openShareModal}>
                <BoxModalStyled>
                    <ModalTitleStyled>{t('share.modal.title', { name: currentDashboard?.name })}</ModalTitleStyled>
                    {mailingList.length > 0 && (
                        <ChooseMailingList
                            handleSendInvit={handleSendInvit}
                            mailingList={mailingList}
                            selectedMailingList={selectedMailingList}
                            setSelectedMailingList={setSelectedMailingList}
                        />
                    )}
                    {mailingList.length === 0 && (
                        <EmptyContent />
                    )}
                    <CloseIconModalStyled onClick={() => setOpenShareModal(false)} />
                </BoxModalStyled>
            </Fade>
        </ModalStyled>
    );
};

export default ShareModal;
