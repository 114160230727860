import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React, { FC, SyntheticEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SurveyTemporalFilterType } from '../../Surveys/surveyInterface';
import { DashboardFromApi } from '../dashboardInterfaces';
import DateRangeSelect from './DateRangeSelect/dateRangeSelect';
import SelectedFilterSummary from './selectedFilterSummary';
import { AccordionStyled, AccordionDetailsStyled, AccordionSummaryStyled, TemporalScopeStyled, TemporalScopeElementStyled, TemporaFilterImageStyled } from './styled';
import TemporalScopeSelect from './TemporalScopeSelect/temporalScopeSelect';

interface TemporalScopeProps {
    temporalScopes: DashboardFromApi['temporalScopesConfig'];
    handleSelectedScope: ([scopeId, modalityId]: [string, Array<string> | null]) => void;
    handleSelectedScopeLabel: ([scopeLabel, modalitiesLabel]: [string, string | null]) => void;
    maxDate?: Date;
    minDate?: Date;
    dashboardId?: number;
    selectedScopeLabel?: any;
    dateRange?: any;
    handleChangeDateRangeScope: (dateRange : any) => void;
    temporalFilterType: string;
}

const TemporalScope: FC<TemporalScopeProps> = ({ temporalScopes, handleSelectedScope, handleSelectedScopeLabel, dateRange, handleChangeDateRangeScope, dashboardId, selectedScopeLabel, temporalFilterType, minDate, maxDate }) => {
    const { t } = useTranslation('dashboard');
    const [expanded, setExpanded] = useState<string | false>(false);
    const dateRangeLabel = dateRange.selection.startDate.toLocaleDateString('fr-FR') === dateRange.selection.endDate.toLocaleDateString('fr-FR') ? `Le ${dateRange.selection.startDate.toLocaleDateString('fr-FR')}` : `Du ${dateRange.selection.startDate.toLocaleDateString('fr-FR')} au ${dateRange.selection.endDate.toLocaleDateString('fr-FR')}`;
    const handleChange = (panel: string) => (event: SyntheticEvent, isExpanded: boolean) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        <AccordionStyled expanded={expanded === 'panel1'} height={expanded ? '54px' : '54px'} onChange={handleChange('panel1')}>
            <AccordionSummaryStyled
                aria-controls="panel1a-content"
                expandIcon={<ExpandMoreIcon />}
                id="panel1a-header"
            >
                <TemporaFilterImageStyled />
                { (temporalFilterType === SurveyTemporalFilterType.VAGUE && (expanded || selectedScopeLabel.length === 0)) && (
                    <SelectedFilterSummary
                        varTitle={t('temporalScope.superTitle')}
                    />
                )}
                { temporalFilterType === SurveyTemporalFilterType.VAGUE && !expanded && selectedScopeLabel.length > 0
            && selectedScopeLabel.map((temporalLabel: any) => (
                <SelectedFilterSummary
                    key={temporalLabel.scopeLabel}
                    selectedValuesLabel={temporalLabel.modalitiesLabel}
                    varTitle={temporalLabel.scopeLabel}
                />
            ))}
                { temporalFilterType === SurveyTemporalFilterType.DATE && (
                    <SelectedFilterSummary
                        selectedValuesLabel={dateRangeLabel}
                    />
                )}
            </AccordionSummaryStyled>
            <AccordionDetailsStyled>
                <TemporalScopeStyled>
                    {temporalFilterType === SurveyTemporalFilterType.DATE && (
                        <DateRangeSelect
                            dateRange={dateRange}
                            handleChangeDateRangeScope={handleChangeDateRangeScope}
                            maxDate={maxDate}
                            minDate={minDate}
                        />
                    )}
                    { temporalFilterType === SurveyTemporalFilterType.VAGUE && temporalScopes.length > 0
                && (
                    <TemporalScopeElementStyled>
                        {temporalScopes.map((temporal) => (
                            <TemporalScopeSelect
                                key={Object.keys(temporal.scope)[0]}
                                dashboardId={dashboardId}
                                handleSelectedScope={handleSelectedScope}
                                handleSelectedScopeLabel={handleSelectedScopeLabel}
                                temporal={temporal}
                            />
                        ))}
                    </TemporalScopeElementStyled>
                )}
                </TemporalScopeStyled>
            </AccordionDetailsStyled>
        </AccordionStyled>
    );
};

export default TemporalScope;
