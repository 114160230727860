import { Accordion, AccordionDetails, AccordionSummary, Card } from '@mui/material';
import styled from 'styled-components';
import { colors, fontFamilyPrimary, fontSize, fontWeight } from '../../../theme';
import { ButtonStyled } from '../../../theme/style';
import { ReactComponent as FilterImage } from '../../../assets/filters/filter.svg';

export const FilterBarStyled = styled(Card)`
&& {
    background-color: ${colors.grey000};
    box-shadow: 0px 10px 60px rgb(33, 22, 69, 0.04);
    border-radius: 8px;
    display: flex;
    padding: 12px;
    break-after: avoid;
    break-inside: avoid;
    @media print {
        margin: 10px;
    }
}`;

export const FilterElementStyled = styled(Card)`
    && {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: flex-start;
        position: relative;
        gap: 40px;
        box-shadow: none;
    }
`;

export const AddFilterButtonStyled = styled(ButtonStyled)`
  && {
    color: ${colors.grey300};
    font-weight: ${fontWeight.bold};
    padding: 16px;
    max-height: 44px;
    max-width: 56px;
    margin-right: 0px;
    align-self: flex-end;
    margin-bottom: 8px;
    background-color: ${colors.grey800};
    span {
      margin-right: 0px;
    }
    &:hover {
        color: ${colors.defaultHover};
    }
    &:active {
        font-weight: ${fontWeight.bold};
    }
    @media print{
        display: none;
    }
  }
`;

export const FilterImageStyled = styled(FilterImage)`
    && {
        padding-right: 15px;
        width: 30px;
        min-width:30px;
    }
`;

export const AccordionStyled = styled(Accordion)(({ height }: {height: string}) => `
    && {
        font-family: ${fontFamilyPrimary};
        background-color: white;
        font-size: ${fontSize.sm};
        color: ${colors.grey100};
        width: auto;
        min-width: 250px;
        vetical-align: middle;
        border: none;
        box-shadow: none;
        margin-bottom: 15px;
        margin-right: 10px;
        border-radius: 8px;
        height: ${height};
        ::before {
            display: none;
        };
        &&.Mui-expanded {
            margin: 0px 10px 15px 0px;
            height: max-content;
        };
        box-shadow: 0px 10px 60px rgba(33,22,69,0.04);
    }
    }
`);

export const AccordionDetailsStyled = styled(AccordionDetails)`
    && {
        background-color: #FFFFFF;
        &&.Mui-selected {
            opacity: 1;
            max-height: 54px;
        };
    }
`;

export const AccordionSummaryStyled = styled(AccordionSummary)`
    && {
        max-height: 54px;
        height: 54px;
        &&.Mui-expanded {
            max-height: 54px;
            margin-right: 10px;
            margin-bottom: 15px;
            min-height: 54px;
            margin: 0;
        };
    }
`;
