import {
    Paper,
    Table,
    TableHead,
} from '@mui/material';
import React, { FC, Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import {
    GetHotAlertsInterface,
    HotAlertsInterface,
    StatusHotAlertsInterface,
    VariablesHotAlertsInterface,
} from '../hotAlertsInterface';
import { TableContainerStyled, TableBodyStyled, TableColumnStyled, TableColumnContainerStyled, TablePaginationStyled } from '../styled';
import HotAlertsRow from './hotAlertsRow';

interface HotAlertSummaryTableInterface {
    lastnameQuestionId: any
    firstnameQuestionId: any
    hotAlerts: GetHotAlertsInterface
    page: number
    setPage: Dispatch<SetStateAction<number>>
    statusList: Array<StatusHotAlertsInterface>
    surveyId: string
}

const HotAlertSummaryTable: FC<HotAlertSummaryTableInterface> = ({ lastnameQuestionId, firstnameQuestionId, hotAlerts, page, setPage, statusList, surveyId }) => {
    const { t } = useTranslation('hotAlerts');

    // TODO : Revoir code pour intitulé de colonnes

    const columns = [
        { id: 'satisfaction', label: 'Satisfaction' },
        { id: 'answerVerbatim', label: 'Réponse' },
        lastnameQuestionId ? { id: 'lastname', label: 'Nom' } : { id: '', label: '' },
        firstnameQuestionId ? { id: 'firstname', label: 'Prénom' } : { id: '', label: '' },
        { id: 'answerDate', label: 'Date' },
    ].filter((element) => element.id !== '');

    const formatVariables = hotAlerts.items[0]?.variables.map((variable: VariablesHotAlertsInterface) => ({ id: variable.id, label: variable.label }));

    const allColumns = [...columns, ...formatVariables, { id: 'status', label: 'Statut' }];

    const rowsPerPage = 10;

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        if (newPage + 1 > page) {
            setPage(page + 1);
        } else {
            setPage(page - 1);
        }
    };

    const defaultLabelDisplayedRows = ({ from, count } : {from: number, count: number}) => t('summary.pagination', { numberOfPage: from, totalOfPage: count });

    return (
        <>
            <TableContainerStyled component={Paper}>
                <Table sx={{ minWidth: 650 }}>
                    <TableHead>
                        <TableColumnContainerStyled>
                            {allColumns.map((column) => (
                                <TableColumnStyled key={column.id}>{column.label}</TableColumnStyled>
                            ))}
                        </TableColumnContainerStyled>
                    </TableHead>
                    <TableBodyStyled>
                        {hotAlerts.items.map((row: HotAlertsInterface) => (
                            <HotAlertsRow key={row.id} columns={allColumns} row={row} statusList={statusList} surveyId={surveyId} />
                        ))}
                    </TableBodyStyled>
                </Table>
            </TableContainerStyled>
            <TablePaginationStyled
                component="div"
                count={hotAlerts.total}
                labelDisplayedRows={() => defaultLabelDisplayedRows({ from: page, count: Math.ceil(hotAlerts.total / rowsPerPage) })}
                onPageChange={handleChangePage}
                page={page - 1}
                rowsPerPage={rowsPerPage}
                rowsPerPageOptions={[10]}

            />
        </>
    );
};

export default HotAlertSummaryTable;
