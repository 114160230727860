import { Alert } from '@mui/material';
import styled from 'styled-components';
import { colors, fontSize, fontWeight } from '../../theme';

export const AlertToasterStyled = styled(Alert)`
    && {
        background-color:${({ type }: any) => (type === 'success' ? colors.alertSuccess : colors.alertDanger)};
        color: ${colors.grey000};
        font-size: ${fontSize.m};
        font-weight: ${fontWeight.semiBold};
        padding: 0;
    }
`;

export default AlertToasterStyled;
