import myHeaders from '../myHeaders';

const getUserIdIfUserAlreadyExist = async (userEmail: string) => {
    const requestOptions = {
        method: 'GET',
        headers: myHeaders,
    };

    return fetch(`${process.env.REACT_APP_FRONTEGG_BASE_URL}/identity/resources/users/v3?_email=${encodeURIComponent(userEmail)}`, requestOptions)
        .then((response) => response.json())
        .then((response) => response.items[0].id)
        .catch((err) => console.error(err));
};

export default getUserIdIfUserAlreadyExist;
