import { Fade } from '@mui/material';
import React, { ChangeEvent, FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { colors, fontSize, fontWeight, TypographyStyledGlobal } from '../../../theme';
import Title from '../../Title/title';
import SimpleActionButton from '../ActionButton/simpleActionButton';
import { InputStyled, StyledFormControl } from '../AddDashboardModal/styled';
import { CloseIconModalStyled, ModalStyled } from '../DashboardModal/styled';
import BoxModalStyled, { ErrorInputMessageStyled } from '../styled';

interface AddMailingListModalInterface {
    handleClose: () => void
    openModal: boolean
    sendValue: (value: string) => void
    title: string
    labelInput: string
    placeholder: string
    labelButton: string
    value?:string
}

const AddMailingListModal: FC<AddMailingListModalInterface> = ({ handleClose, openModal, sendValue, title, labelInput, placeholder, labelButton, value }) => {
    const { t } = useTranslation('settings');
    const [mailingListValue, setMailingListValue] = useState<string>(value || '');
    const [errorMessage, setErrorMessage] = useState<string>('');

    const handleChangeMailingList = (e: ChangeEvent<HTMLInputElement>) => {
        setMailingListValue(e.target.value);
    };
    const handleAddMailingList = () => {
        if (mailingListValue) {
            setErrorMessage('');
            sendValue(mailingListValue);
            handleClose();
        } else {
            setErrorMessage(t('mailingList.modal.errorInputMessage'));
        }
    };

    return (
        <ModalStyled
            aria-describedby="modal-modal-description"
            aria-labelledby="modal-modal-title"
            onClose={handleClose}
            open={openModal}
        >
            <Fade in={openModal}>
                <BoxModalStyled>
                    <Title content={title} modalTitle />
                    <CloseIconModalStyled onClick={handleClose} />
                    <StyledFormControl>
                        <TypographyStyledGlobal
                            color={colors.grey200}
                            size={fontSize.m}
                            weight={fontWeight.regular}
                        >
                            {labelInput}
                        </TypographyStyledGlobal>
                        <InputStyled
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChangeMailingList(e)}
                            placeholder={placeholder}
                            value={mailingListValue}
                        />
                        {errorMessage && (
                            <ErrorInputMessageStyled>{errorMessage}</ErrorInputMessageStyled>
                        )}
                        <SimpleActionButton
                            handleClickConfirm={handleAddMailingList}
                            labelConfirm={labelButton}
                        />
                    </StyledFormControl>
                </BoxModalStyled>
            </Fade>
        </ModalStyled>
    );
};

export default AddMailingListModal;
