import { Alert } from '@mui/material';
import styled from 'styled-components';
import { colors, fontSize, fontWeight } from '../../theme';
import useGetAlertColor from '../../utils/hooks/useGetAlertColor';

export const MessageStyled = styled(Alert)`
    && {
        background-color:${({ type }: any) => useGetAlertColor(type)};
        color: ${colors.grey000};
        font-size: ${fontSize.m};
        font-weight: ${fontWeight.semiBold};
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding: 8px 12px 8px 8px;
        gap: 4px;
        border-radius: 8px;
    }
`;

export default MessageStyled;
