import { AuthorizedContent, useAuthUser } from '@frontegg/react';
import ArrowDropDownRoundedIcon from '@mui/icons-material/ArrowDropDownRounded';
import ArrowDropUpRoundedIcon from '@mui/icons-material/ArrowDropUpRounded';
import ControlPointOutlinedIcon from '@mui/icons-material/ControlPointOutlined';
import React, { useState, FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import useSWR, { useSWRConfig } from 'swr';
import { TypographyStyledGlobal } from '../../../theme';
import { DividerMenuStyled } from '../../../theme/style';
import RolesType from '../../../utils/enum/RolesType';
import getRoles from '../../../utils/getRoles';
import useFetcher from '../../../utils/hooks/useFetcher';
import AddDashboardModal from '../../Modals/AddDashboardModal/addDashboardModal';
import DeleteDashboardModal from '../../Modals/ConfirmationModal/DeleteDashboard';
import DuplicateDashboardModal from '../../Modals/ConfirmationModal/DuplicateDashboardModal';
import RenameDashboardModal from '../../Modals/ConfirmationModal/RenameDashboardModal';
import { DashboardItemFromApi, DashboardItemListFromApi, DashboardParams } from '../dashboardInterfaces';
import DashboardDropdownMenuItems from './dashboardDropdownMenuItems';
import {
    DashboardDropdownButtonStyled,
    AddDashboardButton,
    MenuDashboardDropdownStyled,
} from './styled';

const DashboardDropdown: FC = () => {
    const { t } = useTranslation('dashboard');
    const [openDashboardDropdown, setOpenDashboardDropdown] = useState<boolean>(false);
    const { surveyId = 'unknown', dashboardId } = useParams<DashboardParams>();
    const navigate = useNavigate();
    const user = useAuthUser();
    const { mutate } = useSWRConfig();
    const [currentDashboard, setCurrentDashboard] = useState<DashboardItemListFromApi>({ name: 'Aucun élément', id: '' });

    const { data: fetchedDashboards } = useSWR(`${process.env.REACT_APP_API_URL}/survey/${surveyId}/dashboard`, useFetcher());
    const { data: fetchedShareDashboard } = useSWR(`${process.env.REACT_APP_API_URL}/survey/dashboards/shared`, useFetcher());

    const [menuItems, setMenuItems] = useState<Array<DashboardItemListFromApi>>([]);
    const [openAddModal, setOpenAddModal] = useState<boolean>(false);
    const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
    const [openDuplicateModal, setOpenDuplicateModal] = useState<boolean>(false);
    const [openRenameModal, setOpenRenameModal] = useState<boolean>(false);
    const [dashboardTitle, setdashboardTitle] = useState('');
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const userRoles = getRoles(user);
    const isOnlyViewerDashboard = userRoles.includes('viewer_dashboard') && userRoles.length <= 1;

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setOpenDashboardDropdown(!openDashboardDropdown);
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    useEffect(() => {
        if (fetchedDashboards) {
            const findCurrentDashboard = fetchedDashboards.find((dashboard: DashboardItemListFromApi) => dashboard.id.toString() === dashboardId);
            setCurrentDashboard(findCurrentDashboard);
            const shareDashboardBySurvey = fetchedShareDashboard.filter((dashboard: DashboardItemFromApi) => dashboard.surveyId.toString() === surveyId);
            const dashboardMenuItems = isOnlyViewerDashboard ? shareDashboardBySurvey : fetchedDashboards;
            setMenuItems(dashboardMenuItems.sort((a: DashboardItemFromApi, b: DashboardItemFromApi) => a.id - b.id));
        }
    }, [fetchedDashboards]);

    useEffect(() => {
        if (!openDeleteModal) {
            mutate(`${process.env.REACT_APP_API_URL}/survey/${surveyId}/dashboard`);
        }
    }, [openDeleteModal]);

    const handleClickAddDashboard = () => {
        setOpenAddModal(true);
    };

    const handleChangeDashboard = (menuItem:DashboardItemListFromApi) => {
        setCurrentDashboard(menuItem);
        navigate(`/survey/${surveyId}/dashboard/${menuItem.id}`);
    };

    return (
        <>
            <DashboardDropdownButtonStyled
                endIcon={openDashboardDropdown ? <ArrowDropUpRoundedIcon /> : <ArrowDropDownRoundedIcon />}
                menuopen={openDashboardDropdown ? 1 : 0}
                onClick={handleClick}
                disableRipple
            >
                {currentDashboard?.name}
                <MenuDashboardDropdownStyled
                    anchorEl={anchorEl}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    onClose={handleClose}
                    open={openDashboardDropdown}
                >
                    {menuItems.length > 0 && menuItems.map((menuItem) => (
                        <DashboardDropdownMenuItems
                            key={menuItem.id}
                            content={menuItem.name}
                            currentDashboard={currentDashboard?.id === menuItem.id}
                            id={menuItem.id}
                            onClick={() => handleChangeDashboard(menuItem)}
                            setDashboardToDeleteTitle={setdashboardTitle}
                            setOpenDeleteModal={setOpenDeleteModal}
                            setOpenDuplicateModal={setOpenDuplicateModal}
                            setOpenRenameModal={setOpenRenameModal}
                        />
                    ))}

                    <DividerMenuStyled />
                    <AuthorizedContent requiredRoles={[RolesType.ADMIN, RolesType.ADMIN_MOAI]}>
                        <AddDashboardButton onClick={handleClickAddDashboard} startIcon={<ControlPointOutlinedIcon />}>
                            <TypographyStyledGlobal>
                                {t('createNewDashboard')}
                            </TypographyStyledGlobal>
                        </AddDashboardButton>
                    </AuthorizedContent>
                </MenuDashboardDropdownStyled>
            </DashboardDropdownButtonStyled>
            <AddDashboardModal openModal={openAddModal} setOpenModal={setOpenAddModal} surveyId={surveyId} />
            <DeleteDashboardModal
                menuItemsToNavigate={menuItems}
                openModal={openDeleteModal}
                setOpenModal={setOpenDeleteModal}
                title={dashboardTitle}
            />
            <DuplicateDashboardModal
                openModal={openDuplicateModal}
                setOpenModal={setOpenDuplicateModal}
                title={dashboardTitle}
            />
            <RenameDashboardModal
                openModal={openRenameModal}
                setOpenModal={setOpenRenameModal}
                title={dashboardTitle}
            />
        </>
    );
};

export default DashboardDropdown;
