import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { fontSize, fontWeight, TypographyStyledGlobal, colors } from '../../theme';
import ActionsButtons from './actionsButtons';
import { OnBoardingContainerStyled } from './styled';
import { ReactComponent as WavingHand } from '../../assets/waving-hand.svg';

const OnBoarding: FC = () => {
    const { t } = useTranslation(['navigation', 'onBoarding']);

    return (
        <OnBoardingContainerStyled>
            <WavingHand />
            <TypographyStyledGlobal
                color={colors.grey100}
                margin='16px 0 12px'
                size={fontSize.l}
                weight={fontWeight.bold}

            >
                {t('navigation:welcome', { name: '' })}
            </TypographyStyledGlobal>
            <TypographyStyledGlobal
                color={colors.grey200}
                margin='0 0 24px 0'
            >
                {t('onBoarding:content')}
            </TypographyStyledGlobal>
            <ActionsButtons />
        </OnBoardingContainerStyled>
    );
};

export default OnBoarding;
