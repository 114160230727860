import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useSWRConfig } from 'swr';
import deleteFetcher from '../../../api/deleteFetcher';
import { DashboardParams, DashboardItemListFromApi } from '../../Dashboard/dashboardInterfaces';
import DeleteContentModal from '../DeleteModal/deleteModal';
import { DeleteDashboardProps } from './ConfirmationModalInterface';

const DeleteDashboardModal: FC<DeleteDashboardProps> = ({ openModal, setOpenModal, title, menuItemsToNavigate }) => {
    const { t } = useTranslation(['common', 'dashboard']);
    const { surveyId, dashboardId } = useParams<DashboardParams>();
    const navigate = useNavigate();
    const { mutate } = useSWRConfig();
    const dashboardUrl = `${process.env.REACT_APP_API_URL}/survey/${surveyId}/dashboard/${dashboardId}`;
    const navigateToId = menuItemsToNavigate?.filter((elt: DashboardItemListFromApi) => String(elt.id) !== dashboardId)[0]?.id;
    const handleClose = () => {
        setOpenModal(false);
    };

    const handleClickConfirm = () => {
        deleteFetcher(
            t('dashboard:deleteDashboardModal.success'),
            t('dashboard:deleteDashboardModal.error'),
            `${dashboardUrl}`,
        );
        mutate(`${process.env.REACT_APP_API_URL}/survey/${surveyId}/dashboard`);
        if (navigateToId) {
            navigate(`/survey/${surveyId}/dashboard/${navigateToId}`);
        } else {
            navigate('/');
        }
        setOpenModal(false);
    };

    return (
        <DeleteContentModal
            handleClickConfirm={handleClickConfirm}
            handleClose={handleClose}
            openModal={openModal}
            title={t('dashboard:deleteDashboardModal.title', { dashboardTitle: title })}
        />
    );
};

export default DeleteDashboardModal;
