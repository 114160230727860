export interface SurveyProps {
    lastModificationDate: string
    surveyid: number
    name: string
    status: SurveyStatus
    dashboardCount: number
    dateResult?: string
    shareResult?: {
        status: string
        count: number
    }
    defaultDashboardId?: number
    customSurveyTitle?: string
    hotAlertCount?: number
    toTreatHotAlertCount?: number
    sharesCount?: number
    lastSynchronizationDate: string
}

export enum SurveyStatus {
    IN_PREPARATION = 'IN_PREPARATION',
    IN_PROGRESS = 'IN_PROGRESS',
    COMPLETED = 'COMPLETED',
    ARCHIVED = 'ARCHIVED',
}

export enum SurveyTemporalFilterType {
    VAGUE = 'VAGUE',
    DATE = 'DATE',
}
