import { FronteggProvider } from '@frontegg/react';
import React, { FC } from 'react';
import authContextOptions from './authContextOptions';

const AuthProvider: FC = ({ children }) => (
    <FronteggProvider contextOptions={authContextOptions} hostedLoginBox>
        { children }
    </FronteggProvider>
);

export default AuthProvider;
