import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useSWRConfig } from 'swr';
import renameDashboardFetcher from '../../../api/dashboard/renameDashboard';
import { DashboardParams } from '../../Dashboard/dashboardInterfaces';
import RenameContentModal from '../RenameModal/renameModal';
import { DeleteDashboardProps } from './ConfirmationModalInterface';

const RenameDashboardModal: FC<DeleteDashboardProps> = ({ openModal, setOpenModal, title }) => {
    const { t } = useTranslation(['common', 'dashboard']);
    const { surveyId, dashboardId } = useParams<DashboardParams>();
    const { mutate } = useSWRConfig();

    const handleClose = () => {
        setOpenModal(false);
    };

    const handleClickConfirm = async (value: string) => {
        const customDashboardTitle = { customTitle: value };
        await renameDashboardFetcher(
            surveyId || '',
            dashboardId || '',
            customDashboardTitle || { customTitle: '' },
            t('dashboard:renameDashboardModal.success', { dashboardTitle: title }),
            t('dashboard:renameDashboardModal.error'),
        );
        setOpenModal(false);
        mutate(`${process.env.REACT_APP_API_URL}/survey/${surveyId}/dashboard`);
    };

    return (
        <RenameContentModal
            description={t('dashboard:renameDashboardModal.description')}
            handleClose={handleClose}
            openModal={openModal}
            placeholder={t('dashboard:addDashboard.placeholder')}
            sendValue={handleClickConfirm}
            title={t('dashboard:renameDashboardModal.title', { dashboardTitle: title }) as string}
        />
    );
};

export default RenameDashboardModal;
