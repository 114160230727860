import React, { FC } from 'react';
import { ActionsButtonProps } from '../../dashboardModalInterface';
import { ActionButtonContainer, ActionButtonStyled } from '../styled';

const ActionsButton: FC<ActionsButtonProps> = ({
    handleClickBack,
    handleClickNext,
    labelNext,
    labelBack,
    isDisabled,
}) => (
    <ActionButtonContainer>
        <ActionButtonStyled
            lighten={1}
            onClick={handleClickBack}
            variant="text"
            disableRipple
        >
            {labelBack}
        </ActionButtonStyled>
        <ActionButtonStyled
            disabled={isDisabled}
            onClick={handleClickNext}
            variant="text"
            default
            disableRipple
        >
            {labelNext}
        </ActionButtonStyled>
    </ActionButtonContainer>
);

export default ActionsButton;
