import ArrowDropDownRoundedIcon from '@mui/icons-material/ArrowDropDownRounded';
import LockIcon from '@mui/icons-material/Lock';
import { SelectChangeEvent } from '@mui/material';
import React, { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import LockFilterFetcher from '../../../../api/dashboard/lockFilters';
import { colors } from '../../../../theme/colors';
import { fontSize, fontWeight, TypographyStyledGlobal } from '../../../../theme/font';
import { DashboardParams, FilterFromApi } from '../../dashboardInterfaces';
import { StyledMenuItem, StyledSelectFormControl } from '../../styled';
import ActionsFilterButtons from './actionsButton';
import { ButtonActionsStyled, MenuItemStyled, SelectFilterStyled, PdfPrintValueStyled, LockIconFilterStyled } from './styled';

interface FilterSelectProps {
    filter: FilterFromApi;
    handleSelectClauseFilter: ([filterId, modalityId]: [string, Array<string> | null]) => void;
    handleSelectClauseFilterLabel: ([filterLabel, modalitiesLabel]: [string, string | null]) => void;
    removeDashboardFilter: (filterId: string) => void;
    lockedFilters: string[];
    lockSelectedFilter: string[];
    resetFilters: number;
    setLockSelectedFilter: Dispatch<SetStateAction<string[]>>;
}
const FilterSelect: FC<FilterSelectProps> = ({ filter, handleSelectClauseFilter, handleSelectClauseFilterLabel, removeDashboardFilter, lockedFilters, resetFilters, setLockSelectedFilter, lockSelectedFilter }) => {
    const { t } = useTranslation('dashboard');
    const { surveyId = '', dashboardId = '' } = useParams<DashboardParams>();

    const [selectedFilter, setSelectedFilter] = useState<string[]>([]);
    const [currentLockedFilter, setCurrentLockedFilter] = useState<string[]>([]);

    const [openFilterSelect, setOpenFilterSelect] = useState(false);
    const [openLockIcon, setOpenLockIcon] = useState<boolean>(true);

    useEffect(() => {
        if (lockedFilters.length !== 0) {
            setSelectedFilter(lockedFilters);
            const currentFilters = lockedFilters.filter((lockFilter) => lockFilter in filter?.modalities);

            if (currentFilters.length > 0) {
                setSelectedFilter(currentFilters || []);
                setCurrentLockedFilter(currentFilters);
                setOpenLockIcon(false);
            } else {
                setSelectedFilter([]);
            }
        } else {
            setSelectedFilter([]);
            setCurrentLockedFilter([]);
            setOpenLockIcon(true);
        }
    }, [filter, resetFilters]);

    const handleChange = (event: SelectChangeEvent<typeof selectedFilter>, child: any) => {
        const {
            target: { value },
        } = event;

        const currentValue = child.props.value;
        const lockFilter = lockSelectedFilter.filter((element) => selectedFilter.includes(element));

        if (lockFilter.length > 0 && (selectedFilter.includes(currentValue) && lockSelectedFilter.includes(currentValue))) {
            return;
        }

        if (value.includes('null')) {
            setSelectedFilter([]);
            handleSelectClauseFilter([Object.keys(filter.filter)[0], null]);
            handleSelectClauseFilterLabel([Object.values(filter.filter)[0], null]);
        } else {
            setSelectedFilter(value as Array<string>);
            handleSelectClauseFilter([Object.keys(filter.filter)[0], value as Array<string>]);
            handleSelectClauseFilterLabel([Object.values(filter.filter)[0], Object.entries(filter.modalities).filter((modality) => value.includes(modality[0])).map((modality) => modality[1]).join(', ') as string]);
        }
    };

    const handleDeleteFilter = () => {
        removeDashboardFilter(Object.keys(filter.filter)[0]);
        const lockedFilterInFilter = lockedFilters.filter((lockFilter) => lockFilter in filter?.modalities);
        if (lockedFilterInFilter && !openFilterSelect) {
            const updatedLockedFilter: string[] = [];
            lockSelectedFilter.map((oldSelectedFilter) => {
                if (!lockedFilterInFilter.includes(oldSelectedFilter)) {
                    updatedLockedFilter.push(oldSelectedFilter);
                }

                return oldSelectedFilter;
            });
            setLockSelectedFilter(updatedLockedFilter);
            LockFilterFetcher(surveyId, dashboardId, Object.keys(filter.filter)[0], updatedLockedFilter);
        }
    };

    const handleLockFilter = () => {
        let successMessage = t('filter.addLockedFilterSuccessMessage');
        let lockFiltersToSend = selectedFilter;
        if (lockedFilters && !openLockIcon) {
            lockFiltersToSend = lockedFilters.filter((lockFilter) => !(lockFilter in filter?.modalities));
        }
        if (lockedFilters && openLockIcon) {
            lockFiltersToSend = [...lockedFilters, ...selectedFilter];
        }
        if (!openLockIcon) {
            successMessage = t('filter.addUnlockedFilterSuccessMessage');
            LockFilterFetcher(surveyId, dashboardId, Object.keys(filter.filter)[0], lockFiltersToSend, successMessage as string, t('filter.addLockedFilterErrorMessage'));
            setCurrentLockedFilter([]);
        } else {
            setCurrentLockedFilter(selectedFilter);
            LockFilterFetcher(surveyId, dashboardId, Object.keys(filter.filter)[0], lockFiltersToSend, successMessage as string, t('filter.addLockedFilterErrorMessage'));
        }
    };

    const menuItems = Object.entries(filter.modalities).map((modality) => (
        <MenuItemStyled
            key={modality[0]}
            disabled={!openLockIcon}
            value={modality[0]}
            disableRipple
        >
            {modality[1]}

            <LockIconFilterStyled size='small' disableRipple>
                {currentLockedFilter.includes(modality[0]) && (
                    <LockIcon sx={{ width: '18px' }} />
                )}
            </LockIconFilterStyled>

        </MenuItemStyled>
    ));

    const closeSelectFilter = (e: React.ChangeEvent) => {
        const targetRole = e.target.getAttribute('role');
        if (targetRole === 'option' && e.target.getAttribute('data-value') !== 'null') {
            setOpenFilterSelect(true);

            return;
        }
        if (targetRole === 'button') {
            setOpenFilterSelect(true);

            return;
        }

        setOpenFilterSelect(false);
    };

    return (
        <>
            <StyledSelectFormControl sx={{ m: 1, minWidth: 120 }}>
                <ButtonActionsStyled>
                    <TypographyStyledGlobal
                        color={colors.grey200}
                        size={fontSize.sm}
                        weight={fontWeight.regular}
                    >
                        {Object.values(filter.filter)}
                    </TypographyStyledGlobal>
                    <ActionsFilterButtons handleDeleteFilter={handleDeleteFilter} handleLockFilter={handleLockFilter} openLockIcon={openLockIcon} setOpenLockIcon={setOpenLockIcon} />
                </ButtonActionsStyled>

                <SelectFilterStyled
                    IconComponent={ArrowDropDownRoundedIcon}
                    onChange={handleChange}
                    onClick={closeSelectFilter}
                    open={openFilterSelect}
                    renderValue={(selected: Array<string>) => {
                        if (selected.length === 0) {
                            return t('filter.selectFilter');
                        }

                        return Object.entries(filter.modalities)
                            .filter((modality) => selected.includes(modality[0]))
                            .map((modality) => modality[1])
                            .join(', ');
                    }}
                    sx={selectedFilter.length > 0 ? { color: colors.grey100 } : { color: colors.grey400 }}
                    value={selectedFilter}
                    variant="standard"
                    displayEmpty
                    multiple
                >
                    <StyledMenuItem sx={{ fontSize: fontSize.sm, fontWeight: fontWeight.regular }} value='placeholder' disabled>
                        {t('filter.selectFilter')}
                    </StyledMenuItem>
                    <StyledMenuItem disabled={!openLockIcon} onClick={() => setSelectedFilter([])} sx={{ fontSize: fontSize.sm, color: colors.grey400, fontStyle: 'italic' }} value='null'>
                        {t('filter.noFilter')}
                    </StyledMenuItem>
                    {menuItems}
                </SelectFilterStyled>

            </StyledSelectFormControl>
            <PdfPrintValueStyled>
                <TypographyStyledGlobal weight={fontWeight.semiBold}>
                    {Object.values(filter.filter)} :
                </TypographyStyledGlobal>
                <TypographyStyledGlobal>
                    {Object.entries(filter.modalities)
                        .filter((modality) => selectedFilter.includes(modality[0]))
                        .map((modality) => modality[1])
                        .join(', ')}
                </TypographyStyledGlobal>
            </PdfPrintValueStyled>
        </>
    );
};

export default FilterSelect;
