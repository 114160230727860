import { KPI_STATE } from '../../components/Modals/DashboardModal/constants';
import { ModalitiesProps } from '../../components/Modals/DashboardModal/dashboardModalInterface';
import { TreeItem } from '../../components/SortableTree/types';
import { DraggableEntities } from '../DraggableEntities/DraggableEntities';

export default class Modality implements ModalitiesProps, DraggableEntities {
    codemodalite: number;

    label: string;

    hide?: boolean;

    excluded?: boolean;

    constructor({ codemodalite = 0, label = '', hide, excluded }: ModalitiesProps) {
        this.codemodalite = codemodalite;
        this.label = label;
        this.hide = hide;
        this.excluded = excluded;
    }

    toModalitiesProps(): ModalitiesProps {
        return { codemodalite: this.codemodalite, label: this.label, excluded: this.excluded, hide: this.hide ?? this.excluded };
    }

    toTreeItem(): TreeItem {
        return {
            id: this.codemodalite,
            label: this.label,
            isGroupale: false,
            children: [],
        };
    }

    getStatus(): KPI_STATE | null {
        return (this.excluded ? KPI_STATE.EXCLUDED : null) ?? (this.hide ? KPI_STATE.HIDDEN : null);
    }

    copyWith({ label, hide, excluded }: ModalitiesProps): Modality {
        return new Modality({
            codemodalite: this.codemodalite,
            label: label ?? this.label,
            hide: hide ?? this.hide,
            excluded: excluded ?? this.excluded,
        });
    }
}
