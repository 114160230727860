import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { fontWeight, TypographyStyledGlobal } from '../../../../../theme';
import { DISPLAY_TYPE } from '../../constants';
import { FirstSelectionModalContentProps } from '../../dashboardModalInterface';
import { CardStyled, FlexBox } from '../styled';
import { ReactComponent as ImageChart } from '../../../../../assets/charts/image.svg';
import { ReactComponent as SurveyChart } from '../../../../../assets/charts/survey.svg';

const SelectIndicatorType: FC<FirstSelectionModalContentProps> = ({
    setCurrentContent,
    setCreateIndicator,
}) => {
    const { t } = useTranslation('dashboard');

    const handleClick = (typeOfItem: DISPLAY_TYPE) => {
        if (typeOfItem === DISPLAY_TYPE.IMAGE) { setCurrentContent('selectIndicatorType'); }
        if (typeOfItem === DISPLAY_TYPE.KPI) { setCurrentContent('selectIndicatorQuestion'); }

        setCreateIndicator((prev) => ({ ...prev, typeOfIndicator: typeOfItem }));
    };

    return (

        <FlexBox>
            <CardStyled onClick={() => handleClick(DISPLAY_TYPE.KPI)}>
                <SurveyChart />
                <TypographyStyledGlobal
                    margin='10px auto auto'
                    weight={fontWeight.regular}
                >
                    {t('addIndicatorModal.selectIndicatorType.indicator')}
                </TypographyStyledGlobal>
            </CardStyled>
            <CardStyled onClick={() => handleClick(DISPLAY_TYPE.IMAGE)} disabled>
                <ImageChart />
                <TypographyStyledGlobal
                    margin='10px auto auto'
                    weight={fontWeight.regular}
                >
                    {t('addIndicatorModal.selectIndicatorType.image')}
                </TypographyStyledGlobal>
            </CardStyled>
        </FlexBox>

    );
};

export default SelectIndicatorType;
