import ArrowDropDownRoundedIcon from '@mui/icons-material/ArrowDropDownRounded';
import { SelectChangeEvent } from '@mui/material';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { colors } from '../../../../theme/colors';
import { fontSize, fontWeight, TypographyStyledGlobal } from '../../../../theme/font';
import { TemporalScopeFromApi } from '../../dashboardInterfaces';
import { StyledMenuItem, StyledSelectFormControl } from '../../styled';
import { ButtonActionsStyled, MenuItemStyled, SelectFilterStyled } from './styled';

interface TemporalScopeSelectProps {
    temporal: TemporalScopeFromApi;
    handleSelectedScopeLabel: ([scopeLabel, modalitiesLabel]: [string, string | null]) => void;
    handleSelectedScope: ([scopeId, modalityId]: [string, Array<string> | null]) => void;
    dashboardId?: number;
}

const TemporalScopeSelect: FC<TemporalScopeSelectProps> = ({ temporal, handleSelectedScope, handleSelectedScopeLabel, dashboardId }) => {
    const { t } = useTranslation('dashboard');

    const [selectedFilter, setSelectedFilter] = useState<string[]>([]);

    const [openFilterSelect, setOpenFilterSelect] = useState(false);

    useEffect(() => {
        // Sélection automatique de la vague la plus récente
        const maxScopeModality = Math.max(...Object.keys(temporal.modalities).map(Number));
        const maxScopeModalityLabel = Object.entries(temporal.modalities).filter((modality) => String(maxScopeModality) === modality[0]).map((modality) => modality[1]).join(', ');
        setSelectedFilter([`${maxScopeModality}`]);
        handleSelectedScope([Object.keys(temporal.scope)[0], [`${maxScopeModality}`]]);
        handleSelectedScopeLabel([Object.values(temporal.scope)[0], `${maxScopeModalityLabel}`]);
    }, [dashboardId]);

    const menuItems = Object.entries(temporal.modalities).map((modality) => (
        <MenuItemStyled
            key={modality[0]}
            value={modality[0]}
            disableRipple
        >
            {modality[1]}
        </MenuItemStyled>
    ));

    const handleChange = (event: SelectChangeEvent<typeof selectedFilter>) => {
        const {
            target: { value },
        } = event;

        if (value.includes('null')) {
            setSelectedFilter([]);
            handleSelectedScope([Object.keys(temporal.scope)[0], null]);
            handleSelectedScopeLabel([Object.values(temporal.scope)[0], null]);
        } else {
            setSelectedFilter(value as Array<string>);
            handleSelectedScope([Object.keys(temporal.scope)[0], value as Array<string>]);
            handleSelectedScopeLabel([Object.values(temporal.scope)[0], Object.entries(temporal.modalities).filter((modality) => value.includes(modality[0])).map((modality) => modality[1]).join(', ') as string]);
        }
    };

    const closeSelectFilter = (e: React.ChangeEvent) => {
        const targetRole = e.target.getAttribute('role');
        if (targetRole === 'option' && e.target.getAttribute('data-value') !== 'null') {
            setOpenFilterSelect(true);

            return;
        }
        if (targetRole === 'button') {
            setOpenFilterSelect(true);

            return;
        }

        setOpenFilterSelect(false);
    };

    return (
        <StyledSelectFormControl sx={{ m: 1, minWidth: 120 }}>
            <ButtonActionsStyled>
                <TypographyStyledGlobal
                    color={colors.grey200}
                    size={fontSize.sm}
                    weight={fontWeight.regular}
                >
                    {t('temporalScope.title')}
                </TypographyStyledGlobal>
            </ButtonActionsStyled>

            <SelectFilterStyled
                IconComponent={ArrowDropDownRoundedIcon}
                onChange={handleChange}
                onClick={closeSelectFilter}
                open={openFilterSelect}
                renderValue={(selected: Array<string>) => {
                    if (selected.length === 0) {
                        return t('filter.selectFilter');
                    }

                    return Object.entries(temporal.modalities)
                        .filter((modality) => selected.includes(modality[0]))
                        .map((modality) => modality[1])
                        .join(', ');
                }}
                sx={selectedFilter.length > 0 ? { color: colors.grey100 } : { color: colors.grey400 }}
                value={selectedFilter}
                variant="standard"
                displayEmpty
                multiple
            >
                <StyledMenuItem sx={{ fontSize: fontSize.sm, fontWeight: fontWeight.regular }} value='placeholder' disabled>
                    {t('filter.selectFilter')}
                </StyledMenuItem>
                <StyledMenuItem onClick={() => setSelectedFilter([])} sx={{ fontSize: fontSize.sm, color: colors.grey400, fontStyle: 'italic' }} value='null'>
                    {t('filter.noFilter')}
                </StyledMenuItem>
                {menuItems}
            </SelectFilterStyled>

        </StyledSelectFormControl>
    );
};

export default TemporalScopeSelect;
