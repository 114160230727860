import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { TypographyStyledGlobal, fontWeight, colors, fontSize } from '../../theme';
import { SettingsOutlinedIconStyled } from '../../theme/style';
import Title from '../Title/title';
import { HeaderHotAlertsSummaryProps } from './hotAlertsInterface';
import { HeaderHotAlertTitleStyled } from './styled';

const settingsView = false;

const HeaderHotAlertsSummary: FC<HeaderHotAlertsSummaryProps> = ({ name, question }) => (
    <HeaderHotAlertTitleStyled>
        <div>
            <Title content={name} />
            <TypographyStyledGlobal
                color={colors.grey200}
                margin='0 0 24px 0'
                size={fontSize.m}
                sx={{ lineHeight: '24px' }}
                weight={fontWeight.semiRegular}
            >
                {question.questionLabel}
            </TypographyStyledGlobal>

        </div>
        {settingsView && (
            <Link to='/settings'>
                <SettingsOutlinedIconStyled />
            </Link>
        )}
    </HeaderHotAlertTitleStyled>
);

export default HeaderHotAlertsSummary;
