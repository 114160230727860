import DoDisturbOnOutlinedIcon from '@mui/icons-material/DoDisturbOnOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import { t } from 'i18next';
import React, { FC } from 'react';
import { TypographyStyledGlobal, colors, fontWeight, fontSize } from '../../../../../../theme';
import { ModalitiesLegendStyled } from '../../styled';

const IconsLegend: FC = () => {
    const iconLegends = [
        {
            id: 'visibilityIcon',
            icon: <VisibilityOffOutlinedIcon sx={{ marginRight: '17px', color: colors.grey500 }} />,
            title: t('dashboard:addIndicatorModal.refineIndicatorAnswer.hiddenAnswer.title'),
            subtitle: t('dashboard:addIndicatorModal.refineIndicatorAnswer.hiddenAnswer.subtitle'),
        },
        {
            id: 'doNotDisturbIcon',
            icon: <DoDisturbOnOutlinedIcon sx={{ marginRight: '17px', color: colors.grey500 }} />,
            title: t('dashboard:addIndicatorModal.refineIndicatorAnswer.excludeAnswer.title'),
            subtitle: t('dashboard:addIndicatorModal.refineIndicatorAnswer.excludeAnswer.subtitle'),
            additionnalInformation: t('dashboard:addIndicatorModal.refineIndicatorAnswer.excludeAnswer.additionnalInformation'),
        },
    ];

    return (
        <>
            {iconLegends.map((legend) => (
                <ModalitiesLegendStyled key={legend.id}>
                    {legend.icon}
                    <div>
                        <TypographyStyledGlobal
                            color={colors.grey200}
                            size={fontSize.sm}
                            weight={fontWeight.semiBold}
                        >
                            {legend.title}
                        </TypographyStyledGlobal>
                        <TypographyStyledGlobal
                            color={colors.grey300}
                            size={fontSize.sm}
                            weight={fontWeight.semiRegular}
                        >
                            {legend.subtitle}
                            <br />
                            {legend.additionnalInformation}
                        </TypographyStyledGlobal>
                    </div>
                </ModalitiesLegendStyled>
            ))}
        </>
    );
};

export default IconsLegend;
