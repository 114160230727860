import React, { FC, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TypographyStyledGlobal, fontWeight } from '../../../../../theme';
import { ConversationButtonStyled } from '../../../../Dashboard/styled';
import Loader from '../../../../Loader/loader';
import { ChatingProps } from '../../dashboardModalInterface';
import { ConversationDivStyled, ChatbotQuestionStyled, ChatbotResponseStyled, InputStyled } from './styled';
import TextTypingEffectWithTextsFadeOut from './typingEffect';
import { ReactComponent as ChatbotConversationImage } from '../../../../../assets/charts/chatbot-conversation.svg';

interface ChatbotConversationProps {
    conversation: Array<ChatingProps>;
    getChatbotResponse: (isSummary: boolean, chatEntry: string) => void;
    waitingForChatAnswer: boolean;
    handleIsRenderedChat: (chatIdRendered: number) => void;
}

const ChatbotConversation: FC<ChatbotConversationProps> = ({ conversation, getChatbotResponse, waitingForChatAnswer, handleIsRenderedChat }) => {
    const { t } = useTranslation('dashboard');
    const [chatEntry, setChatEntry] = useState<string>('');
    const handleNewChatEntry = (entry: string) => {
        if (entry) {
            getChatbotResponse(false, entry);
            setChatEntry('');
        }
    };
    const divRef = useRef<null | HTMLDivElement>(null);
    useEffect(() => {
        divRef.current?.scrollTo({ top: 2000 });
    }, [conversation]);

    return (
        <>
            <ConversationDivStyled ref={divRef}>
                {conversation?.map((c: ChatingProps) => (
                    <>
                        <ChatbotQuestionStyled key={`question-${c.id}`}>{c.entry}</ChatbotQuestionStyled>
                        { c.answer === '...' ? <Loader key={`loader-${c.id}`} justifyContent='left' size={20} /> : <ChatbotResponseStyled key={`answer-${c.id}`}><TextTypingEffectWithTextsFadeOut chatId={c.id} handleIsRenderedChat={handleIsRenderedChat} hasToUseTypingEffect={!c.alreadyRendered} text={c.answer} /></ChatbotResponseStyled>}
                    </>
                ))}
            </ConversationDivStyled>
            <InputStyled
                disabled={waitingForChatAnswer}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => setChatEntry(event.target.value)}
                onKeyDown={((event: any) => event.key === 'Enter' && handleNewChatEntry(event.target.value))}
                placeholder={t('addIndicatorModal.addChatbotIndicator.placeholder')}
                value={chatEntry}
                autoFocus
                disableUnderline
            />
            <ConversationButtonStyled onClick={() => handleNewChatEntry(chatEntry)}>
                <ChatbotConversationImage height={25} width={25} />
                <TypographyStyledGlobal
                    margin='0px'
                    weight={fontWeight.regular}
                >
                    {t('addIndicatorModal.addChatbotIndicator.response')}
                </TypographyStyledGlobal>
            </ConversationButtonStyled>
        </>
    );
};

export default ChatbotConversation;
