import { AuthorizedContent } from '@frontegg/react';
import React, { Dispatch, FC, SetStateAction } from 'react';
import { fontWeight, TypographyStyledGlobal } from '../../../theme/font';
import { EditIconDropdownStyled, CloseIconStyled, FilterNoneIconStyled } from '../../../theme/style';
import RolesType from '../../../utils/enum/RolesType';
import { ActionButtons, DashboardDropdownMenuItemsStyled } from './styled';

interface DashboardDropdownMenuItemsProps{
    content: string;
    id: string;
    onClick: Function;
    currentDashboard: boolean;
    setOpenDeleteModal: Dispatch<SetStateAction<boolean>>;
    setOpenDuplicateModal: Dispatch<SetStateAction<boolean>>;
    setOpenRenameModal: Dispatch<SetStateAction<boolean>>;
    setDashboardToDeleteTitle: Dispatch<SetStateAction<string>>;
}

const DashboardDropdownMenuItems: FC<DashboardDropdownMenuItemsProps> = ({
    content,
    id,
    onClick,
    currentDashboard,
    setOpenDeleteModal,
    setOpenDuplicateModal,
    setOpenRenameModal,
    setDashboardToDeleteTitle,
}) => {
    const handleOpenModal = () => {
        setDashboardToDeleteTitle(content);
        setOpenDeleteModal(true);
    };
    const handleDuplicateModal = () => {
        setDashboardToDeleteTitle(content);
        setOpenDuplicateModal(true);
    };
    const handleRenameModal = () => {
        setDashboardToDeleteTitle(content);
        setOpenRenameModal(true);
    };

    return (
        <DashboardDropdownMenuItemsStyled
            key={id}
            currentdashboard={currentDashboard ? 1 : 0}
            onClick={onClick}
            disableRipple
        >
            <TypographyStyledGlobal
                margin='0 20px 0 0'
                textAlign="center"
                weight={currentDashboard ? fontWeight.regular : fontWeight.semiRegular}
            >
                {content}
            </TypographyStyledGlobal>

            <AuthorizedContent requiredRoles={[RolesType.ADMIN, RolesType.ADMIN_MOAI]}>
                <ActionButtons>
                    <EditIconDropdownStyled onClick={handleRenameModal} sx={{ width: '21px' }} />
                    <FilterNoneIconStyled onClick={handleDuplicateModal} sx={{ width: '19px' }} />
                    <CloseIconStyled onClick={handleOpenModal} sx={{ width: '21px' }} />
                </ActionButtons>
            </AuthorizedContent>
        </DashboardDropdownMenuItemsStyled>
    );
};

export default DashboardDropdownMenuItems;
