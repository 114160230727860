import styled from 'styled-components';
import { colors, fontWeight, fontSize } from '../../theme';
import { ButtonStyled } from '../../theme/style';

const ExportButton = styled(ButtonStyled)`
    && {
        font-weight: ${fontWeight.regular};
        padding: 12px;
        background-color: ${colors.grey000};
        border: 1px solid;
        border-color: ${'transparent'};
        color: ${colors.default};
        width: 146px;
        font-size: ${fontSize.sm}
    }
`;
export default ExportButton;
