import CloseIcon from '@mui/icons-material/Close';
import { FormControl, Input, Modal } from '@mui/material';
import styled from 'styled-components';
import { colors, fontFamilyPrimary, fontSize, fontWeight } from '../../../theme';
import { ButtonStyled } from '../../../theme/style';
import { ActionButtonStyledProps } from '../DashboardModal/DashboardModalContent/styled';

export const CloseIconModalStyled = styled(CloseIcon)`
    && {
        position: absolute;
        right: 16px;
        top: 16px;
        cursor: pointer;
        padding: 8px;
        transition: color 400ms ease-in-out;
        &:hover {
            color: ${colors.grey100};
            transition: color 400ms ease-in-out;
        }
    }
`;
export const StyledFormControl = styled(FormControl)`
    && {
        width: 100%;
    }
`;

export const InputStyled = styled(Input)`
    && {
        font-family: ${fontFamilyPrimary};
        font-size: ${fontSize.sm};
        background-color: ${colors.grey000};
        border-radius: 8px;
        padding: 10px 16px;
        margin-top: 8px;
        color: ${colors.grey100};
        width: 100%;
        &::before {
            border: none;
        }
        &::after {
            border: none;
        }
        &:hover::after {
            border: none;
        }
        &:hover:not(.Mui-disabled):before {
            border: none;
        }
        & ::placeholder {
            color: ${colors.grey400};
            font-size: ${fontSize.sm};
            opacity: 1;
        }
    }
`;

export const ModalStyled = styled(Modal)`
    backdrop-filter: blur(2px);
    -webkit-backdrop-filter: blur(2px);
`;

export const ActionButtonStyled = styled(ButtonStyled)((props : ActionButtonStyledProps) => `
    && {
        padding: 12px 16px;
        background-color: ${props.default ? colors.default : colors.grey000};
        color: ${props.default ? colors.grey000 : colors.default};
        font-weight: ${fontWeight.semiBold};
        max-height: 48px;
        &:hover {
            background-color: ${props.default ? colors.defaultHover : colors.grey700};
        }
        &:disabled {
            background-color: ${colors.grey600};
            color: ${colors.grey300};
        }
    }
`);

export const ActionButtonContainer = styled.div`
    display: flex;
    gap: 16px;
    margin-top: 32px;
`;
