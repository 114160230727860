import styled from 'styled-components';
import { ReactComponent as KortoLogo } from '../../assets/korto-logotype.svg';
import { ReactComponent as MoaiLogo } from '../../assets/moai-logotype.svg';

export const MoaiLogoStyled = styled(MoaiLogo)`
    margin-left: 12px;
`;

export const LogoStyled = styled(KortoLogo)`
  width: 160px;
`;
