import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    LineElement,
    Tooltip,
    Legend,
    PointElement,
    Filler,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import React, { FC } from 'react';
import { Line } from 'react-chartjs-2';
import { colors, gutterSize, fontFamilyPrimary } from '../../../theme';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    LineElement,
    PointElement,
    Tooltip,
    Legend,
    ChartDataLabels,
    Filler,
);

type LineChartIndicatorProps = {
    listOfValues: any
}

enum legendPosition {
    BOTTOM = 'bottom'
}

enum secondXAxePosition {
    TOP = 'top'
}

enum legendAlign {
    START = 'start'
}

const LineChartIndicator: FC<LineChartIndicatorProps> = ({ listOfValues }) => {
    const { labels } = listOfValues;
    const options = {
        indexAxis: 'x' as const,
        responsive: true,
        layout: {
            padding: {
                left: gutterSize.m,
                right: gutterSize.xxl,
            },
        },
        plugins: {
            legend: {
                display: false,
                position: legendPosition.BOTTOM,
                align: legendAlign.START,
                fullSize: true,
                labels: {
                    boxWidth: 32,
                    boxHeight: 2,
                    padding: 20,
                    color: colors.grey200,
                    font: {
                        family: fontFamilyPrimary,
                        size: 14,
                    },
                },
                maxHeight: 1200,
                maxWidth: 200,
            },
            tooltip: {
                enabled: true,
                callbacks: {
                    label: (dataset: any) => {
                        const tooltipLabel = `${dataset.dataset.label}: ${dataset.raw.toFixed(listOfValues.round)}${listOfValues.computeMethod === 'PERCENT' ? '%' : ''}`;

                        return [`Base : ${listOfValues.bases[dataset.dataIndex]}`, tooltipLabel];
                    },
                },
            },
            datalabels: {
                display: false,
            },
        },
        scales: {
            x: {
                display: true,
                grid: {
                    display: true,
                    drawBorder: false,
                    drawTicks: true,
                    offset: false,
                    tickLength: 30,
                    color: colors.grey700,
                },
                ticks: {
                    color: colors.grey200,
                    font: {
                        size: 15,
                        family: 'Barlow',
                    },
                },
                scale: {
                    padding: 50,
                },
            },
            x2: {
                display: true,
                grid: {
                    display: true,
                    drawBorder: false,
                    drawTicks: true,
                    offset: false,
                    tickLength: 30,
                    color: colors.grey700,
                    drawLenght: 20,
                },
                ticks: {
                    display: false,
                },
                position: secondXAxePosition.TOP,
            },
            y: {
                display: true,
                grid: {
                    display: true,
                    drawBorder: false,
                    drawTicks: true,
                    offset: false,
                    tickLength: 30,
                    color: colors.grey700,
                },
                ticks: {
                    color: colors.grey200,
                    font: {
                        size: 15,
                        family: 'Barlow',
                    },
                    fontColor: colors.grey400,
                    callback: (value: any) => {
                        const tickLabel = listOfValues.computeMethod === 'PERCENT' ? `${value.toFixed(listOfValues.round)}%` : value;

                        return tickLabel;
                    },
                },
                min: listOfValues.min,
                max: listOfValues.max,
            },
        },
        elements: {
            point: {
                radius: 3,
                borderColor: colors.blue000,
                backgroundColor: colors.blue000,
            },
            line: {
                fill: true,
                borderColor: colors.blue000,
                backgroundColor: (context: any) => {
                    const bgColor = [
                        colors.blue000RGBA,
                        'rgba(255,255,255,0.25)',
                    ];
                    if (!context.chart.chartArea) {
                        return false;
                    }
                    const { ctx, chartArea: { top, bottom } } = context.chart;
                    const gradienBg = ctx.createLinearGradient(0, top, 0, bottom);
                    gradienBg.addColorStop(0, bgColor[0]);
                    gradienBg.addColorStop(1, bgColor[1]);

                    return gradienBg;
                },
            },
        },
    };

    const data = {
        labels,
        datasets: listOfValues.datasets,
    };

    return (
        <Line
            data={data}
            options={options}
        />
    );
};

export default LineChartIndicator;
