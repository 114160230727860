import { Box } from '@mui/material';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import styled from 'styled-components';

interface TableCellBodyStyledProps {
    isOpenData: boolean
}

export const FilterTableBoxStyled = styled(Box)`
    &&{
        display: flex;
    }
`;

export const TableCellHeaderStyled = styled(TableCell)`
    && {
        border: none;
        background-color: #F8FAFC;
        font-weight: 500;
        font-family: barlow;
        color: #0F172A;
        line-height: 20px;
        font-size: 16px;
        vertical-align: bottom;
    }

`;

export const TableRowHeaderStyled = styled(TableRow)`
    && {
        display: table-row;
        vertical-align: text-top;
        outline: 0;
        height: 80px;
    }

`;

export const TextFieldDivStyled = styled.div`
    && {
        display: block;
        vertical-align: bottom;
    }

`;

export const TextFieldStyled = styled(TextField)`
    && {
        background-color: #F2F2F2;
        color: #8C8C8C;
        max-width: 150px;
        border-radius: 5px;
    }

`;

export const TableRowBodyStyled = styled(TableRow)`
    && {
        border: none;
    }

`;

export const TableCellBodyStyled = styled(TableCell)(({ isOpenData } : TableCellBodyStyledProps) => `
    && {
        font-weight: ${isOpenData ? '400' : '600'};
        font-family: barlow;
        border: none;
        color: ${isOpenData ? '#8C8C8C' : '#334155'};
        font-size: 1.1em;
        line-height: 18px;
        padding-left: 30px;

    }

`);
