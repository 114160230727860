import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { FormLabelStyled, KeyFigureViewStyled } from '../../styled';

type ResultNumericIndicatorProps = {
    value: number;
}

const ResultNumericIndicator: FC<ResultNumericIndicatorProps> = ({ value }) => {
    const { t } = useTranslation('dashboard');

    return (
        <>
            <FormLabelStyled>
                {t('addIndicatorModal.commonAddIndicator.overview')}
            </FormLabelStyled>
            <KeyFigureViewStyled>
                {value}%
            </KeyFigureViewStyled>
        </>
    );
};

export default ResultNumericIndicator;
