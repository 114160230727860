import React, { FC } from 'react';
import { TitleStyled, ModalTitleStyled } from './styled';

interface TitleProps {
    content: string;
    modalTitle?: boolean;
}

const Title: FC<TitleProps> = ({ content, modalTitle = false }) => (
    <>
        {!modalTitle && (
            <TitleStyled>{content}</TitleStyled>
        )}
        {modalTitle && (
            <ModalTitleStyled>{content}</ModalTitleStyled>
        )}
    </>
);

export default Title;
