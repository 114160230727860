import { useAuth } from '@frontegg/react';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import createNewUserInMailingList from '../../../api/mailingList/createNewUserInMailingList';
import { ButtonWithoutBackgroundStyled } from '../../../theme/style';
import AddMailingListModal from '../../Modals/AddMailingListModal/addMailingListModal';

const AddUserInMailingListAction: FC<{mailingListId: string, refreshMutate: () => void}> = ({ mailingListId, refreshMutate }) => {
    const { t } = useTranslation('settings');
    const { user } = useAuth();
    const [openCreateUserModal, setOpenCreateUserModal] = useState<boolean>(false);
    const creationNewUserInMailingList = (value: string) => {
        createNewUserInMailingList(value, mailingListId, user.metadata, refreshMutate);
    };

    return (
        <>
            <ButtonWithoutBackgroundStyled
                onClick={() => setOpenCreateUserModal(true)}
                disableRipple
            >
                {t('mailingList.addUser')}
            </ButtonWithoutBackgroundStyled>

            {openCreateUserModal && (
                <AddMailingListModal
                    handleClose={() => setOpenCreateUserModal(false)}
                    labelButton={t('mailingList.modal.addNewUserButton')}
                    labelInput={t('mailingList.modal.addNewUserInputTitle')}
                    openModal={openCreateUserModal}
                    placeholder={t('mailingList.modal.placeholderAddNewUser')}
                    sendValue={creationNewUserInMailingList}
                    title={t('mailingList.addNewUser')}
                />
            )}
        </>
    );
};

export default AddUserInMailingListAction;
