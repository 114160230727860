import { UpdateDashboardProps } from '../../components/Dashboard/dashboardInterfaces';
import { displayToast } from '../../components/Toaster/displayToast';

const createDashboardUpdateFetcher = (
    surveyId: string,
    dashboard: UpdateDashboardProps | undefined,
    successMessage: string,
    errorMessage: string,
) => {
    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    myHeaders.append('Accept', 'application/json');
    myHeaders.append('Authorization', `Bearer ${localStorage.getItem('token')}`);

    const requestOptions = {
        method: 'PUT',
        headers: myHeaders,
        body: JSON.stringify(dashboard),
    };

    fetch(`${process.env.REACT_APP_API_URL}/survey/${surveyId}/dashboard/${dashboard?.id}`, requestOptions)
        .then((response) => {
            if (!response.ok) {
                displayToast(errorMessage, 'error');
            } else {
                displayToast(successMessage, 'success');
            }
        }).catch(() => {
            displayToast(errorMessage, 'error');
        });
};

export default createDashboardUpdateFetcher;
