import styled from 'styled-components';
import { colors, fontWeight, breakpoints } from '../../../theme';
import { ButtonStyled } from '../../../theme/style';

const AddIndicatorButtonStyled = styled(ButtonStyled)`
  && {
    @media print {
      display: none;
    }
    border: 1px dashed ${colors.default};
    color: ${colors.default};
    font-weight: ${fontWeight.bold};
    padding: 16px;
    max-height: 56px;
    &:hover {
        background-color: ${colors.lighten};
    }
    &:active {
        font-weight: ${fontWeight.bold};
    }
    @media screen and (max-width: ${breakpoints.s}) {
        padding: 16px;
    }
  }
`;

export default AddIndicatorButtonStyled;
