import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import { List } from '@mui/material';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import useSWR from 'swr';
import { ButtonWithoutBackgroundStyled } from '../../../theme/style';
import useFetcher from '../../../utils/hooks/useFetcher';
import EditInput from '../../EditInput/editInput';
import { ListIconStyled, ListItemStyled, ListItemTextStyled, SurveyCardShareStyled } from '../styled';
import { SurveyStatus } from '../surveyInterface';

interface SurveyCardProps {
    id: string
    dashboardId: string[]
}

const SurveyCardWithApi: FC<SurveyCardProps> = ({ id, dashboardId }) => {
    const { t } = useTranslation(['survey', 'dashboard']);
    const [shareSurvey, setShareSurvey] = useState<{name: string, surveyid: number, customSurveyTitle: string, status: SurveyStatus} | undefined>(undefined);
    const { data: fetchedShareDashboard } = useSWR(`${process.env.REACT_APP_API_URL}/survey/${id}`, useFetcher());

    useEffect(() => {
        if (fetchedShareDashboard) {
            setShareSurvey(fetchedShareDashboard);
        }
    }, [fetchedShareDashboard]);

    return (
        <SurveyCardShareStyled key={id}>
            {shareSurvey && (
                <>
                    <EditInput showEditButton={false} surveyId={shareSurvey.surveyid} title={shareSurvey.customSurveyTitle ?? shareSurvey.name} />
                    <List>
                        <>
                            <ListItemStyled key="dashboardCount">
                                <ListIconStyled>
                                    <DashboardOutlinedIcon />
                                </ListIconStyled>
                                <ListItemTextStyled primary={t('survey:surveyDashboard', { count: dashboardId.length })} />
                            </ListItemStyled>
                            <Link to={`/survey/${fetchedShareDashboard.surveyid}/dashboard/${dashboardId[0]}`}>
                                <ButtonWithoutBackgroundStyled sx={{ marginTop: '10px' }} disableRipple>{t('survey:seeResult')}</ButtonWithoutBackgroundStyled>
                            </Link>
                        </>
                    </List>
                </>
            )}
        </SurveyCardShareStyled>
    );
};

export default SurveyCardWithApi;
