import Fade from '@mui/material/Fade';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { colors, fontSize, fontWeight, TypographyStyledGlobal, TypographyDivStyledGlobal } from '../../../theme';
import { BigBoxModalStyled, OneLineTitleStyled } from '../styled';
import VirtualizedVerbatimTable from './DashboardModalContent/VerbatimModal/virtualizedVerbatimTable';
import { AllVerbatimModalProps } from './dashboardModalInterface';
import { CloseIconModalStyled, ModalStyled, AllVerbatimCardHeader, VerbatimImageStyled } from './styled';

const AllVerbatimModal: FC<AllVerbatimModalProps> = ({ verbatims, openModal, questionLabel, setOpenModal, selectedClauseFilterLabel }) => {
    const handleClose = () => {
        setOpenModal(false);
    };
    const { t } = useTranslation('dashboard');

    return (
        <ModalStyled
            aria-describedby="modal-modal-description"
            aria-labelledby="modal-modal-title"
            onClose={handleClose}
            open={openModal}
        >
            <Fade in={openModal}>
                <BigBoxModalStyled>
                    <CloseIconModalStyled onClick={handleClose} />
                    <AllVerbatimCardHeader>
                        <TypographyDivStyledGlobal color={colors.black000} paddingTop='10px' size={fontSize.ml} weight={fontWeight.semiBold}>
                            {questionLabel}
                            <OneLineTitleStyled>
                                <TypographyStyledGlobal color={colors.grey400} fontStyle='italic' size={fontSize.sm} textDecoration='underline' weight={fontWeight.semiRegular}>
                                    {'Filtrage : '}
                                </TypographyStyledGlobal>
                                { selectedClauseFilterLabel && selectedClauseFilterLabel.map((filter: any, index: number) => (
                                    <TypographyStyledGlobal key={filter.filterLabel} color={colors.grey400} fontStyle='italic' margin='0px 0px 0px 5px' size={fontSize.sm} weight={fontWeight.semiRegular}>
                                        {index === 0 ? `${filter.filterLabel}` : ` || ${filter.filterLabel}`}{` : ${filter.modalitiesLabel}`}
                                    </TypographyStyledGlobal>
                                ))}
                                { selectedClauseFilterLabel?.length === 0 && (
                                    <TypographyStyledGlobal color={colors.grey400} fontStyle='italic' margin='0px 0px 0px 5px' size={fontSize.sm} weight={fontWeight.semiRegular}>
                                        {t('filter.noFilter')}
                                    </TypographyStyledGlobal>
                                )}
                            </OneLineTitleStyled>
                        </TypographyDivStyledGlobal>
                        <VerbatimImageStyled />
                    </AllVerbatimCardHeader>
                    <VirtualizedVerbatimTable verbatims={verbatims} />
                </BigBoxModalStyled>
            </Fade>
        </ModalStyled>
    );
};

export default AllVerbatimModal;
