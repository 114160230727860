import { displayToast } from '../../components/Toaster/displayToast';
import myHeaders from '../myHeaders';

const shareDashboard = (
    mailingListId: string[],
    dashboardId: string,
    surveyId: string,
    successMessage: string,
    errorMessage: string,
) => {
    const requestOptions = {
        method: 'POST',
        headers: myHeaders,
    };

    mailingListId.map((mailingList) => fetch(`${process.env.REACT_APP_API_URL}/mailing_list/${mailingList}/share/survey/${surveyId}/dashboard/${dashboardId}`, requestOptions)
        .then((response) => {
            if (!response.ok) {
                displayToast(errorMessage, 'error');
            } else {
                displayToast(successMessage, 'success');
            }
        }).catch(() => {
            displayToast(errorMessage, 'error');
        }));
};

export default shareDashboard;
