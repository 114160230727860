import React, { Dispatch, FC, SetStateAction } from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import { TabSettingsStyled } from './styled';

interface LinkTabInterface {
    value: string, label: string, disableRipple: boolean, wrapped: boolean, currentContent: string, setCurrentContent: Dispatch<SetStateAction<string>>
}

const LinkTab: FC<LinkTabInterface> = ({ value, label, disableRipple, wrapped, setCurrentContent, currentContent }) => (
    <TabSettingsStyled
        component={Link}
        disableRipple={disableRipple}
        label={label}
        onClick={() => {
            setCurrentContent(value);
        }}
        selected={value === currentContent}
        to={`/settings${value}`}
        wrapped={wrapped}
    />
);

export default LinkTab;
