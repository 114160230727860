import React, { FC } from 'react';
import { SelectedFilterSummaryStyled } from './styled';

interface SelectedFilterSummaryProps {
        varTitle?: string
        selectedValuesLabel?: string
}

const SelectedFilterSummary: FC<SelectedFilterSummaryProps> = ({ varTitle, selectedValuesLabel }) => {
    const label = varTitle ? `${varTitle} ${selectedValuesLabel ? ` :  \n${selectedValuesLabel}` : ''}` : `${selectedValuesLabel ? `${selectedValuesLabel}` : ''}`;

    return (
        <SelectedFilterSummaryStyled>
            {label}
        </SelectedFilterSummaryStyled>
    );
};

export default SelectedFilterSummary;
