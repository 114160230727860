import React, { FC } from 'react';
import { ActionButtonContainer, ActionButtonStyled } from './styled';

interface ActionsButtonProps {
    handleClickConfirm: Function,
    labelConfirm: string
}

const SimpleActionButton: FC<ActionsButtonProps> = ({
    handleClickConfirm,
    labelConfirm,
}) => (
    <ActionButtonContainer>
        <ActionButtonStyled
            onClick={handleClickConfirm}
            variant="text"
            default
            disableRipple
        >
            {labelConfirm}
        </ActionButtonStyled>
    </ActionButtonContainer>
);

export default SimpleActionButton;
