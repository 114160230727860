import ControlPointOutlinedIcon from '@mui/icons-material/ControlPointOutlined';
import React, { useState, FC } from 'react';
import DashboardModal from '../../Modals/DashboardModal/dashboardModal';
import AddIndicatorButtonStyled from './styled';

interface AddIndicatorButtonProps {
    label: string;
}

const AddIndicatorButton: FC<AddIndicatorButtonProps> = ({ label }) => {
    const [openModal, setOpenModal] = useState(false);

    return (
        <>
            <AddIndicatorButtonStyled
                onClick={() => setOpenModal(true)}
                startIcon={<ControlPointOutlinedIcon />}
                disableRipple
            >
                {label}
            </AddIndicatorButtonStyled>
            <DashboardModal openModal={openModal} setOpenModal={setOpenModal} />
        </>
    );
};

export default AddIndicatorButton;
