import { TableContainer, TableCell, Select, MenuItem, TableRow, TableBody, TablePagination } from '@mui/material';
import styled from 'styled-components';
import { colors, fontFamilyPrimary, fontSize, fontWeight } from '../../theme';

export const TableContainerStyled = styled(TableContainer)`
    && {
        border: none;
        box-shadow: none;
        background: transparent;
    }

`;

export const TableCellStyled = styled(TableCell)(({ paddingcell }: {paddingcell: string}) => `
    && {
        font-family: ${fontFamilyPrimary};
        color: ${colors.grey100};
        font-size: ${fontSize.m};
        padding: ${paddingcell};
        white-space: nowrap;
    }

`);

export const TableColumnStyled = styled(TableCellStyled)`
    && {
        border: none;
        font-weight: 600;
        padding: 16px 0;
        width: auto;
    }
`;

export const SelectStatusHotAlertStyled = styled(Select)(({ background, fontcolor }: { background: string, fontcolor: string}) => `
&& {
    border-radius: 4px;
    background-color: ${background};
    border: none;
    color: ${fontcolor};
    fieldset {
        border: none;
        padding: 0;
    }
    .MuiSelect-select {
        padding: 2px 4px 4px;
        font-family: ${fontFamilyPrimary};
        font-weight: ${fontWeight.regular};
        font-size: ${fontSize.sm};
    }
    svg {
        right: 2px;
        color: ${fontcolor};
    }
`);

export const TableBodyStyled = styled(TableBody)`
    && {
        tr {
            &:nth-child(even)  {
                background-color: ${colors.grey800};
            }
            &:nth-child(odd)  {
                background-color: ${colors.grey000};
            }
            &:hover {
                background-color: ${colors.grey700};
            }
        }
        td {
            border-color: ${colors.grey600};
            line-height: 0;
            &:nth-child(3) {
                text-transform: uppercase;
                padding-left: 40px;
            }
        }
    }
`;

export const LinkToDetailStyled = styled(TableCellStyled)`
    opacity: 0;
`;

export const TableRowStyled = styled(TableRow)`
    && {
        cursor: pointer;
        :hover {
            .link-to-detail-hot-alert {
                opacity: 1;
            }
        }
    }
`;

export const MenuItemStyled = styled(MenuItem)`
    && {
        font-family: ${fontFamilyPrimary};
        font-size: ${fontSize.sm}
    }
`;

export const TableColumnContainerStyled = styled(TableRow)`
    && {
        th {
            padding: 10px;
        }
        th:nth-child(1) {
            width: 0;
            padding-right: 40px;
            padding-left: 0px;
        }
        th:nth-child(2) {
            width: 0;
        }
        th:nth-child(3) {
            padding-left: 40px;
        }
    }
`;

export const TablePaginationStyled = styled(TablePagination)`
    && {
        .MuiTablePagination-selectLabel, .MuiTablePagination-select, .MuiTablePagination-selectIcon {
            display: none;
        }
        .MuiTablePagination-displayedRows {
            font-family: ${fontFamilyPrimary};
            font-size: ${fontSize.m};
            color: ${colors.grey100};
        }
    }
`;

export const HeaderHotAlertTitleStyled = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
`;
