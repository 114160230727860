import React, { ChangeEvent, Dispatch, FC, SetStateAction } from 'react';
import { Indicator } from '../../Dashboard/dashboardInterfaces';
import { InputStyled, StyledFormControl } from './styled';

interface CustomTitleInputProps {
    newIndicator: Indicator;
    setNewIndicator: Dispatch<SetStateAction<Indicator>>
}

const CustomTitleInput: FC<CustomTitleInputProps> = ({ newIndicator, setNewIndicator }) => {
    const handleChangeTitle = (event: ChangeEvent<HTMLInputElement>) => {
        setNewIndicator((prev) => ({ ...prev, customIndicatorTitle: event.target.value }));
    };

    return (
        <StyledFormControl>
            <InputStyled
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChangeTitle(e)}
                placeholder={newIndicator.customItemTitle ?? newIndicator.question.label}
            />
        </StyledFormControl>
    );
};

export default CustomTitleInput;
