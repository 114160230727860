import React, { Dispatch, FC, SetStateAction } from 'react';
import { fontSize, fontWeight, TypographyStyledGlobal } from '../../theme';
import { ActionButtons } from '../Dashboard/DashboardDropdown/styled';
import { DeleteIconStyled, DropdownMenuItemsStyled, EditButtonStyled } from './styled';

interface DropdownMenuItemsProps{
    content: string;
    id: string;
    onClick: Function;
    currentElement: boolean;
    editActions?: (value:string) => void;
    setOpenEditActionsButton: Dispatch<SetStateAction<boolean>>
    setOpenDeleteActionsButton: Dispatch<SetStateAction<boolean>>
}

const DashboardDropdownMenuItems: FC<DropdownMenuItemsProps> = ({
    content,
    id,
    onClick,
    currentElement,
    editActions,
    setOpenEditActionsButton,
    setOpenDeleteActionsButton,
}) => (
    <DropdownMenuItemsStyled
        key={id}
        currentElement={currentElement ? 1 : 0}
        onClick={onClick}
        disableRipple
    >
        <TypographyStyledGlobal
            margin='0 20px 0 0'
            size={fontSize.sm}
            textAlign="center"
            weight={currentElement ? fontWeight.regular : fontWeight.semiRegular}
        >
            {content}
        </TypographyStyledGlobal>
        {editActions && (
            <ActionButtons>
                <EditButtonStyled onClick={() => setOpenEditActionsButton(true)} sx={{ width: '20px' }} />
                <DeleteIconStyled onClick={() => setOpenDeleteActionsButton(true)} sx={{ width: '20px' }} />
            </ActionButtons>
        )}
    </DropdownMenuItemsStyled>
);

export default DashboardDropdownMenuItems;
