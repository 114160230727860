import { CreateDashboardProps } from '../../components/Dashboard/dashboardInterfaces';
import { displayToast } from '../../components/Toaster/displayToast';

const createDashboardFetcher = async (
    dashboard: CreateDashboardProps,
    successMessage: string,
    errorMessage: string,
) => {
    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    myHeaders.append('Accept', 'application/json');
    myHeaders.append('Authorization', `Bearer ${localStorage.getItem('token')}`);

    const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: JSON.stringify(dashboard),
    };

    return fetch(`${process.env.REACT_APP_API_URL}/survey/${dashboard.surveyId}/dashboard/`, requestOptions)
        .then((response) => {
            if (!response.ok) {
                displayToast(errorMessage, 'error');

                return undefined;
            }
            displayToast(successMessage, 'success');

            return response.json();
        }).catch(() => {
            displayToast(errorMessage, 'error');
        });
};

export default createDashboardFetcher;
