import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Checkbox, FormControlLabel } from '@mui/material';
import React, { FC, useEffect } from 'react';
import { colors, fontSize, fontWeight, TypographyStyledGlobal } from '../../../theme';
import FilterAccordionCheckbox from './filterAccordionCheckbox';
import { Filter, Modality } from './filterModalInterface';
import { StyledAccordion, StyledAccordionDetails, StyledAccordionSummary } from './styled';

interface FilterAccordionProps {
    filter: Filter;
    mapOnObject: (modalities: Modality) => Array<Modality>;
    handleSelectedFilterWithModality: (filterId: string, modalityId: string, checked: boolean) => void;
    handleSelectAll: (filterId: string, checked: boolean) => void;
    alreadySelectedModality: Array<string> | undefined;
}

const FilterAccordion : FC<FilterAccordionProps> = ({ filter, mapOnObject, handleSelectedFilterWithModality, handleSelectAll, alreadySelectedModality }) => {
    const [checkedModality, setCheckedModality] = React.useState<Array<string>>([]);
    useEffect(() => {
        if (alreadySelectedModality) {
            setCheckedModality(alreadySelectedModality);
        }
    }, []);

    const handleCheckboxChange = (modalityId: string, checked:boolean) => {
        handleSelectedFilterWithModality((filter.id).toString(10), modalityId, checked);
        if (checked) {
            setCheckedModality([...checkedModality, modalityId]);
        } else {
            setCheckedModality(checkedModality.filter((id: string) => id !== modalityId));
        }
    };
    const allModalities = Object.keys(filter.modalities);

    const handleCheckAll = (_: unknown, checked: boolean) => {
        handleSelectAll(filter.id.toString(10), checked);
        if (checked) {
            setCheckedModality(allModalities);
        } else {
            setCheckedModality([]);
        }
    };

    const modalityIsChecked = (modalityKey: string) => checkedModality.includes(modalityKey);

    return (
        <StyledAccordion key={filter.label} sx={{ color: colors.grey500 }}>
            <StyledAccordionSummary
                aria-controls="panel1a-content"
                expandIcon={<ArrowDropDownIcon sx={{ color: colors.grey500 }} />}
                id="panel1a-header"
            >
                <FormControlLabel
                    control={(
                        <Checkbox
                            checked={checkedModality.length === allModalities.length}
                            indeterminate={checkedModality.length !== allModalities.length && checkedModality.length > 0}
                            sx={{ color: colors.grey500,
                                display: 'none',
                                '&.Mui-checked': { color: colors.alertSuccess },
                                '&.MuiCheckbox-indeterminate': { color: colors.alertSuccess },
                            }}
                            disabled
                        />
                    )}
                    label={(
                        <TypographyStyledGlobal
                            color={checkedModality.length > 0 ? colors.default : colors.grey200}
                            paddingLeft='12px'
                            size={fontSize.sm}
                            textDecoration='none'
                            weight={checkedModality.length > 0 ? fontWeight.bold : fontWeight.regular}
                        >
                            {`${filter.label} ${checkedModality.length > 0 ? `(${checkedModality.length})` : ''}`}
                        </TypographyStyledGlobal>
                    )}
                    value={filter.id}
                />
            </StyledAccordionSummary>
            <StyledAccordionDetails>
                <FormControlLabel
                    control={<Checkbox onChange={handleCheckAll} sx={{ color: colors.grey500, '&.Mui-checked': { color: colors.alertSuccess } }} value={filter.id} />}
                    label={(
                        <TypographyStyledGlobal
                            color={colors.grey200}
                            size={fontSize.sm}
                            weight={fontWeight.regular}
                        >
                            Tout sélectionner
                        </TypographyStyledGlobal>
                    )}
                />
            </StyledAccordionDetails>
            {filter && mapOnObject(filter.modalities).map((modality:Modality) => (
                <StyledAccordionDetails key={modality.key}>
                    <FilterAccordionCheckbox
                        handleSelectedFilterWithModality={handleCheckboxChange}
                        isChecked={modalityIsChecked(modality.key)}
                        modalityKey={modality.key}
                        modalityValue={modality.value}
                    />
                </StyledAccordionDetails>
            ))}
        </StyledAccordion>
    );
};

export default FilterAccordion;
