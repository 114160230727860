import styled from 'styled-components';
import { breakpoints } from '../../../theme';

const DashboardHeaderStyled = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 24px;
    @media screen and (max-width: ${breakpoints.s}) {
        flex-direction: column;
    }
    @media print {
        padding-bottom: 5px;
    }


`;
export const ButtonContainer = styled.div`
    display: flex;
    box-sizing: border-box;
    gap: 8px;
    justify-content: space-around;
    align-items: center;
    @media print{
        display: none;

    }
`;

export default DashboardHeaderStyled;
