import { AnimateLayoutChanges, useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import React, { CSSProperties } from 'react';

import { iOS } from '../../utilities';
import TreeItem from './TreeItem';
import { TreeItemProps } from './type';

const animateLayoutChanges: AnimateLayoutChanges = ({ isSorting, wasDragging }) => (!(isSorting || wasDragging));

export default function SortableTreeItem({ id, depth, ...props }: TreeItemProps) {
    const {
        attributes,
        isDragging,
        isSorting,
        listeners,
        setDraggableNodeRef,
        setDroppableNodeRef,
        transform,
        transition,
    } = useSortable({
        id,
        animateLayoutChanges,
    });
    const style: CSSProperties = {
        transform: CSS.Translate.toString(transform),
        transition,
    };

    return (
        <TreeItem
            {...props}
            ref={setDraggableNodeRef}
            depth={depth}
            disableInteraction={isSorting}
            disableSelection={iOS}
            ghost={isDragging}
            handleProps={{
                ...attributes,
                ...listeners,
            }}
            id={id}
            style={style}
            wrapperRef={setDroppableNodeRef}
        />
    );
}
