import React, { FC } from 'react';
// import { CloseArrowIconStyled } from '../../../theme/style';
import EditInput from '../../EditInput/editInput';
import { SurveyCardHeader, SurveyCardStyled } from '../styled';

type SurveyCardProps = {
    children: React.ReactNode;
    name: string;
    surveyId: number;
};

const SurveyWrapper: FC<SurveyCardProps> = ({ children, name, surveyId }) => (
    <SurveyCardStyled>
        <SurveyCardHeader>
            <EditInput surveyId={surveyId} title={name} />
            {/* <CloseArrowIconStyled /> */}
        </SurveyCardHeader>
        {children}
    </SurveyCardStyled>
);

export default SurveyWrapper;
