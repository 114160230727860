import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import ExportButton from './styled';

const ButtonExport: FC = () => {
    const { t } = useTranslation('dashboard');

    return (
        <ExportButton
            onClick={() => {
                window.print();
            }}
            startIcon={<DownloadOutlinedIcon />}
            variant="text"
            disableRipple
        > {t('exportDashboard')}
        </ExportButton>
    );
};

export default ButtonExport;
